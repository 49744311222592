<script>

import {mapGetters} from "vuex";
import MarketResearch from "../../api/modules/market-research";
import SimpleInfoTable from "../../components/SimpleInfoTable/SimpleInfoTable.vue";
import {cropImage, formatDate} from "../../utils/utils";
import BasicSearchInput from "../../components/UI/BasicSearchInput/index.vue";
import customCompetitors from "../../api/modules/custom-competitors";
import Competitors_select_modal from "../CompetitorsFinderPage/Competitors/competitors_select_modal.vue";
import DatepickerWithRangeOld from "../../components/UI/DatepickerWithRangeOld/index.vue";
import App from "../../api/objects/App";
import Store from "../../api/objects/Store";
import AppsSelect from "../../components/UI/HeaderMainOptions/AppsSelect.vue";
import SvgIcon from "../../components/UI/SvgIcon/index.vue";
import PromoBlock from "./PromoBlock.vue";
import Breadcrumbs from "../../components/UI/Breadcrumbs.vue";
import ProgressIndicator from "../../components/UI/ProgressIndicator/index.vue";
import router from "../../router";
import Tooltip from "../../components/UI/Tooltip/index.vue";
import OrderRadasoButton from "@/components/OrderRadaso/Button/index.vue";

export default {
  name: "MarketResearchList",
  methods: {
    router() {
      return router
    },
    openCompetitorsModal() {
      this.selectCompetitorsModalIsOpen = true;
      this.$refs.competitors_select_modal.openModal();
    },
    handleClickRow(item, event) {
      if (event.target.closest("[data-delete]")) {
        return;
      }

      if (item.status !== 'done') return;

      this.router().push({name: 'MarketResearchItem', params: {researchId: item.id}});
    },
    formatDate,
    cropImage,
    handleSelectCompetitors(newObj) {
      this.competitorsObj = newObj;
      this.selectedApps = this.competitorsObj.getCompetitorsCheckedOnlyInitial().map((item) => item.makeAppObj());
      this.selectCompetitorsModalIsOpen = false;
    },
    applyDatePicker(e) {
      this.dateFilter = {
        start: e?.start ?? this.minInitDate,
        end: e?.end ?? this.maxDate,
      };
    },
    async makeReport() {
      if (!this.selectedReportApp || !this.selectedApps.length) {
        return;
      }
      await MarketResearch.makeMarketResearch(
        this.selectedReportApp.id,
        this.selectedApps.map((item) => item.id),
        formatDate(this.dateFilter.start, 'digits-dash-reverse'),
        formatDate(this.dateFilter.end, 'digits-dash-reverse'),
      );
      await this.loadList();

      this.createModalIsOpen = false;
    },
    async deleteReport(id) {
      await MarketResearch.deleteMarketResearch(id);
      await this.loadList();
    },
    async loadList()
    {
      this.list = await MarketResearch.getList();
    },
    async handleSelectApp(res) {
      this.selectedReportApp = new App(res.id, null, new Store(res.store_key));
      this.lasSelectedApp = res;
    },
    processStatusUpdateSocket(msg)
    {
      const id = msg.id;
      const status = msg.status;
      const index = this.list.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.list[index].status = status;
      }
    }
  },
  components: {
    OrderRadasoButton,
    Tooltip,
    ProgressIndicator,
    Breadcrumbs,
    PromoBlock,
    SvgIcon, AppsSelect, DatepickerWithRangeOld, Competitors_select_modal, BasicSearchInput, SimpleInfoTable},
  data() {
    return {
      createModalIsOpen: false,
      selectCompetitorsModalIsOpen: false,
      searchResults: [],

      competitorsObj: null,
      selectedApps: [],

      selectedReportApp: null,
      lasSelectedApp: null,

      minInitDate: null,
      minDate: null,
      maxDate: null,
      dateFilter: null,

      list: [],

      loadedList: false,
    }
  },
  async mounted() {
    this.maxDate = new Date();

    this.selectedReportApp = this.currentApp;
    this.lasSelectedApp = this.currentApp;

    let minDate = new Date();
    minDate.setMonth(minDate.getMonth() - 12);
    minDate.setDate(1);
    this.minDate = minDate;

    let minInitDate = new Date();
    minInitDate.setMonth(minInitDate.getMonth() - 12);
    this.minInitDate = minInitDate;

    this.dateFilter = {
      start: this.minInitDate,
      end: this.maxDate,
    };

    await this.loadList();

    this.loadedList = true;
  },
  created() {
   try {
     this.sockets.userChannel(this.userData.id).bind('market-research-status-update', this.processStatusUpdateSocket)
   } catch (e) {
     console.error(e);
   }
  },
  beforeUnmount() {
    try {
      this.sockets.userChannel(this.userData.id).unbind('market-research-status-update', this.processStatusUpdateSocket);
    } catch (e) {
      console.error(e);
    }
  },
  computed: {
    allowedSize() {
      return this.windowWidth >= 768;
    },
    ...mapGetters([
      'isMobile',
      'sidebarIsCollapsed',
      'currentApp',
      'currentCountry',
      'userData',
    ]),
  },
}
</script>

<template>
  <the-new-header/>
  <div class="page-content">
    <slot name="sidebar"></slot>
    <main :class="[{'collapsed': sidebarIsCollapsed}, {'full-width': isMobile}]">
      <h1 class="page-title" style="margin-left: 25px">Market Research</h1>
      <div class="marker-research-list-container" v-if="allowedSize">
        <div class="top-nav-wrap">
          <div class="top_nav display-flex">
            <div class="common-big-block-title">My Reports</div>
            <div class="common-btn-purple-transparent ml-10" @click="createModalIsOpen = true">Create New</div>
          </div>

          <order-radaso-button class="order-radaso-wrap" :pre-selected="['full-aso-support', 'launch-support']" />
        </div>
        <div v-if="loadedList && list.length > 0">
          <SimpleInfoTable
              :table-id="'market-research-reports'"
              :tr-click-callback="handleClickRow"
              :tr-class-callback="(item) => item.status === 'done' ? 'tr_shadow_hover cursor-pointer' : ''"
              :columns="{
                0: {header: 'App', style: {width: '100%', minWidth:'250px'}, orientation: 'left'},
                1: {header: 'Competitors', style: {'min-width': '50px'}},
                2: {header: 'Period', style: {'min-width': '270px'}},
                3: {header: 'Status', style: {'min-width': '130px'}},
                4: {header: ''},
              }"
              :items="list"
              :show-pagination="false"
          >
            <template v-slot:headers="slotProps">
              <div :class="{
                    'text-left': slotProps.index === 0,
                    'text-right': slotProps.index >= 1,
                  }">
                {{ slotProps.header }}
              </div>
            </template>
            <template v-slot:items-0="slotProps">
              <div class="display-flex f-align-center">
                <img :src="cropImage(slotProps.item.app.logo, '32x32', slotProps.item.app.store.key)" alt="">
                <span class="ml-10">{{ slotProps.item.app.title }}</span>
                <div class="display-flex ml-10">
                  <img v-if="slotProps.item.app.store.key === 'APP_STORE'"
                       src="@/assets/images/icons/app_store.png"
                       alt="Store icon"
                       class="store-icon"
                       width="15"
                       height="15">
                  <img v-else
                       src="@/assets/images/icons/google_play.png"
                       alt="Store icon"
                       class="store-icon"
                       width="15"
                       height="15">
                </div>
              </div>
            </template>
            <template v-slot:items-1="slotProps">
              <div class="display-flex f-align-center f-j-end">
                {{ slotProps.item.competitors}}
              </div>
            </template>
            <template v-slot:items-2="slotProps">
              <div class="display-flex f-align-center f-j-end">
                <span>{{formatDate(slotProps.item.date_from, 'month-day-year')}} - {{formatDate(slotProps.item.date_to, 'month-day-year')}}</span>
              </div>
            </template>
            <template v-slot:items-3="slotProps">
              <div class="display-flex f-align-center f-j-end">
                <span v-if="slotProps.item.status === 'done'" class="green">Done</span>
                <span v-else-if="slotProps.item.status === 'in_progress'" class="yellow">Generating</span>
                <span v-else class="red">Error</span>
              </div>
            </template>
            <template v-slot:items-4="slotProps">
              <div class="display-flex f-align-center f-j-end actions">
                <svg-icon icon="close-alt" data-delete="true" class="cursor-pointer" @click="deleteReport(slotProps.item.id)"></svg-icon>
              </div>
            </template>
          </SimpleInfoTable>
        </div>
        <div v-else-if="loadedList && list.length === 0">
          <div class="display-flex f-align-start promos_block f-wrap" style="justify-content: space-evenly">
            <div><promo-block>
              <template v-slot:imageSlot="">
                <img src="@/assets/images/market_research/promo_1.png" width="340" height="200"/>
              </template>
              <template v-slot:titleSlot="">Installs & Revenue Intelligence</template>
              <template v-slot:textSlot="">
                <ul>
                  <li>Global Performance Overview: Gain insights into where your app's installs and revenue are concentrated around the world.</li>
                  <li>Identify Revenue Hotspots: Pinpoint countries that contribute the most to your app's revenue.</li>
                  <li>Understand User Distribution: Visualize where your app has the highest user engagement based on installs.</li>
                  <li>Strategic Planning: Use this data to tailor marketing efforts and user engagement strategies for specific regions.</li>
                </ul>
              </template>
            </promo-block></div>
            <div class="ml-10"><promo-block>
              <template v-slot:imageSlot="">
                <img src="@/assets/images/market_research/promo_2.png" width="340" height="200"/>
              </template>
              <template v-slot:titleSlot="">Competitors Trend Analysis</template>
              <template v-slot:textSlot="">
                <ul>
                  <li>Global Performance Tracking: Observe the trend of installs over different time frames on a worldwide scale.</li>
                  <li>Localized Insights: Zoom in to examine install patterns in specific countries, offering precise market insights.</li>
                  <li>Global Impact: Gain unparalleled insights into the performance of specific apps on a global scale, guiding your decisions.</li>
                </ul>
              </template>
            </promo-block></div>
            <div class="ml-10"><promo-block>
              <template v-slot:imageSlot="">
                <img src="@/assets/images/market_research/promo_3.png" width="340" height="200"/>
              </template>
              <template v-slot:titleSlot="">Discover Your Competitive Edge</template>
              <template v-slot:textSlot="">
                <ul>
                  <li>Top Performers Analysis: Spot leading apps by installs and revenue.</li>
                  <li>Top Charts Insights: Analyze Top Charts Positions, Ratings.</li>
                  <li>Analyze IAP pricing strategies across competitors. Tailor your IAP strategy based on real data insights for optimal results.</li>
                  <li>Gain insights into the range of app ages that succeed in the market.</li>
                </ul>
              </template>
            </promo-block></div>
          </div>
        </div>
        <div v-else>
          <progress-indicator>Loading...</progress-indicator>
        </div>
      </div>
      <div v-else class="display-flex f-j-center p-10 mt-20">
        Unsupported for your window screen width
      </div>
    </main>
  </div>
  <custom-modal :opened="createModalIsOpen && allowedSize"
                :max-width="965"
                class-name="create-market-research-modal"
                :scroll="true"
                :paddings="false"
                @closeModal="createModalIsOpen = false"
  >
    <div class="modal-heading common-big-block-title" style="margin: 20px 0 0 20px">Create New Market Analysis</div>
    <div class="modal-message">
      <div class="dashed-container">
        <div class="display-flex f-space-between creation_nav">
          <div style="width: 300px">
            <div>Select App to Analyse</div>
            <AppsSelect :selected-app="lasSelectedApp ?? currentApp" @app-selected="handleSelectApp"></AppsSelect>
          </div>
          <div>
            <div>Select Competitors</div>
            <div class="common-btn-purple-transparent" style="width: 205px; max-width: 205px"
                 @click="openCompetitorsModal"
                 v-if="this.competitorsObj">
              ({{ this.selectedApps.length }}/{{
                this.competitorsObj?.getTotalCount()
              }}) Selected <svg-icon icon="eye_o" class="ml-10" style="font-size: 19px"></svg-icon>
            </div>
            <div v-else style="height: 36px; width: 200px">
              Loading...
            </div>
          </div>
          <div>
            <div>Choose Period for Report</div>
            <datepicker-with-range-old
                v-if="dateFilter"
                :to-right="true"
                :date-filter="dateFilter"
                :min-date="minDate"
                :max-date="maxDate"
                :columns="2"
                :ranges="[30, 90, 180, 365]"
                @date-filter-changed="applyDatePicker"/>
          </div>
        </div>
      </div>
      <div class="btn_row">
        <div class="common-btn-purple-transparent" @click="makeReport" v-if="this.selectedReportApp && this.selectedApps?.length">Create Report</div>
        <div class="common-btn-purple-transparent gray" v-else>
          <tooltip width="max-content" :allow-point-events="true">
            <template v-slot:slotTrigger>
              Create Report
            </template>
            <template v-slot:slotContent>
              You need to select competitors
            </template>
          </tooltip>
        </div>
      </div>
    </div>
  </custom-modal>
  <competitors_select_modal :current-app="lasSelectedApp ?? currentApp"
                            :current-country="currentCountry"
                            :key="lasSelectedApp?.id ?? currentApp.id"
                            ref="competitors_select_modal"
                            @save-and-continue="handleSelectCompetitors"
  />
</template>

<style scoped lang="scss">
.marker-research-list-container {
  padding: 24px;

  .top_nav {
    margin-bottom: 24px;
  }

  .top-nav-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .order-radaso-wrap {
      margin-bottom: 24px;
    }
  }

  .actions {
    > * {
      display: block;
      font-size: 24px;
      color: var(--neutral-600);
      margin-left: 16px;
    }
  }
}

.dashed-container {
  padding: 60px;
  margin: 20px;
  font-weight: 400;
  border: 2px dashed #CAD5E5;
  border-radius: 8px;
}

.promos_block {
  > div {
    margin-bottom: 10px;

    li {
      margin-top: 10px;
    }
  }
  
  @media screen and (max-width: 1389px) {
    flex-direction: column;
    align-items: center;

    > div {
      margin-left: 0 !important;
    }
  }
}
</style>
<style>
.create-market-research-modal {
  .modal, section {
    overflow: visible !important;
  }

  .creation_nav {
    > div {
      width: max-content;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }

      > div {
        &:first-child {
          text-align: left;
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: var(--neutral-800);
        }
      }
    }

    .calendar-block {
      margin: 0;
    }

    .calendar-input {
      border: solid 1px var(--neutral-500);
      box-shadow: none;
    }

    @media screen and (max-width: 950px) {
      flex-direction: column;
      min-height: 300px;
      justify-content: space-between;
      align-items: flex-start;

      > div {
        width: 100% !important;
      }
    }
  }

  .btn_row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .gray {
      background-color: var(--neutral-400);
      border-color: var(--neutral-400);
      color: var(--neutral-600);
    }
  }
}
</style>