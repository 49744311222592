<template>
    <the-new-header>
      <template v-slot:main-block>
        <div class="middle-part">
          <header-main-options :current-app="currentApp"/>
        </div>
      </template>
    </the-new-header>
    <div class="page-content meta-editor">
    <slot name="sidebar"></slot>
    <main :class="[{'collapsed': sidebarIsCollapsed}, {'full-width': isMobile}]">

      <div class="new-container">
        <h1 class="page-title">{{ title }}</h1>
      </div>

      <div class="page-content new-container">
        <template v-if="currentApp?.store?.key === 'APP_STORE'">
          <template v-if="!isTablet && !isMobile">
            <div class="meta-editor-table-block">
              <div class="meta-editor-options-block-wrap">
                <div class="country-block">
                  <div class="search-locales-block">
                    <custom-inline-search placeholder="Search for Countries"
                                          @search-input-changed="countriesLocalesInputChanged"
                                          :with-result-slots="true"
                                          :min-input-length="0"
                                          :show-caret="true"
                                          :full-width-content="true"
                                          results-max-height="256px"
                                          :close-from-outside-counter="closeFromOutsideCounter"
                                          :initial-data="currentCountryData.name"
                                          :config="countryLocalesConfig"
                                          :search-results="countriesListComputed">

                      <template v-slot:heading-info>
                        <dynamic-image classes="country-flag"
                                       :width="18"
                                       :height="14"
                                       :size="32"
                                       :country-code="currentCountryData?.code"/>
                        {{ currentCountryData?.name }}
                      </template>

                      <template v-slot:results>
                        <div class="results-group"
                             v-if="countriesListLoaded">
                          <template
                            v-if="countriesListComputed?.length > 0">
                            <div v-for="country in countriesListComputed"
                                 @click="countryClickedHandler(country.code, country.name, country.id)"
                                 class="result-item">
                              <template v-if="country?.code">
                                <dynamic-image classes="country-flag"
                                               :width="18"
                                               :height="14"
                                               :size="32"
                                               :country-code="country?.code"/>
                              </template>
                              <span class="item-name">{{ country?.name }}</span>
                            </div>
                          </template>
                          <template v-else>
                            <div class="results-group">
                              <div class="text">No data found</div>
                            </div>
                          </template>
                        </div>
                      </template>
                    </custom-inline-search>
                  </div>
                  <language-selector :current-language="selectedLocales"
                                     :max-selection="maxSelectedLocales"
                                     :current-country-name="currentCountry.name"
                                     :languages="localesObject"
                                     placeholder="Choose locales"
                                     loader-text="Loading locales..."
                                     :multiselect="true"
                                     :is-languages-loaded="!isLocalesLoading"
                                     :search-input-configs="{
                                         border: 'none',
                                         borderRadius: '4px',
                                         boxShadow: 'rgba(160, 190, 221, 0.3) 0px 4px 8px',
                                         height: '36px',
                                         width: '300px',
                                       }"
                                     :show-group-title="false"
                                     @language-changed="localeChanged"
                  />

                </div>
                <div class="meta-editor-options-block">
                  <div class="right-side">
                    <div class="versions-block">
                      <custom-select :options="savedMetasArray"
                                     :initial-data="'App store'"
                                     :close-dropdown="closeSavedMetaDropdown"
                                     :classes="'flat outlined'">

                        <template v-slot:heading>
                          <template
                              v-if="JSON.stringify(currentMetaVersion) !== '{}' && currentMetaVersion !== undefined">
                            {{ currentMetaVersion?.name }}
                          </template>
                          <template v-else>App Store Meta</template>
                        </template>

                        <template v-slot:results>
                          <div class="results-block">
                            <div class="results-item main-item"
                                 @click="changeAppMeta">
                              App Store Meta
                            </div>
                            <template v-if="savedMetasArray?.length > 0">
                              <div class="results-item"
                                   v-for="metaItem in savedMetasArray">
                                <div class="item-inner"
                                     @click="changeAppMeta(metaItem)">
                                  <div class="meta-name">{{ metaItem?.name }}</div>
                                  <div class="meta-date">{{ metaItem?.updated_at }}</div>
                                </div>
                                <div class="delete-icon"
                                     @click="openMetaDeleteModal(metaItem)">
                                  <svg-icon icon="close"/>
                                </div>
                              </div>
                            </template>
                            <div class="no-results" v-else>No saved metas found</div>
                          </div>
                        </template>
                      </custom-select>
                    </div>
                    <div class="buttons-block">
                      <base-button height="36px"
                                   v-if="tableMode === 'view'"
                                   width="120px"
                                   class="btn-standard save-edit-btn"
                                   border-radius="4px"
                                   font-size="16px"
                                   @click="tableMode = 'edit'">
                        Edit Meta
                      </base-button>

                      <base-button height="36px"
                                   v-if="tableMode === 'edit'"
                                   width="140px"
                                   class="btn-standard save-edit-btn"
                                   border-radius="4px"
                                   font-size="16px"
                                   @click="saveCurrentVersion">
                        Save Meta
                      </base-button>

                      <base-button height="36px"
                                   v-if="tableMode === 'edit'"
                                   width="140px"
                                   class="btn-standard save-edit-btn outline"
                                   border-radius="4px"
                                   font-size="16px"
                                   @click="saveNewMetaClicked">
                        Save as new
                      </base-button>
                      <div class="buttons-block">
                        <div v-if="userSubscription?.plan_name === 'Free'"
                             @click="openPlansModal"
                             class="download-button">
                          <svg-icon icon="download"/>
                        </div>

                        <a v-else class="download-button"
                           :href="fetchDownloadData()"
                           download>
                          <svg-icon icon="export-csv-icon"/>
                          <div class="tooltip-content">Download CSV</div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <template v-if="metaTableDataLoaded">
                <meta-editor-table :table-mode="tableMode"
                                   :selected-locales="localesToDisplay"
                                   :table-data="appMetaData"
                                   :store-data="storeAppMetaData"
                                   :classes="'upper-table'"
                                   @turn-on-edit-mode="tableMode = 'edit'"
                                   @show-store-keywords-modal="showStoreKeywordsModal = true"
                                   @editable-table-value-changed="editableTableValueChanged" />
              </template>

              <template v-else>
                <div class="preloader-wrap">
                  <preloader-table-small loader-size="50px"></preloader-table-small>
                </div>
              </template>
            </div>

            <div class="meta-editor-tabs-content"
                 id="blured-scroll-anchor">
              <div class="tabs-heading">
                <filters-new :filters="filterConfigs"
                             ref="filters"
                             @apply-filters="filtersApplied">
                  <template v-slot:buttonText="slotProps">
                    Kw Filters <span v-if="slotProps.filtersChangedCount > 0">&nbsp;({{ slotProps.filtersChangedCount }})</span>
                  </template>
                </filters-new>

                <div class="tabs-heading-item"
                     :class="{active: setActiveClass('words')}"
                     @click="switchCurrentTab('words')">
                  Words
                </div>
                <div class="tabs-heading-item"
                     :class="{active: setActiveClass('keywords')}"
                     @click="switchCurrentTab('keywords')">
                  Keywords
                </div>
                <div class="tabs-heading-item"
                     :class="{active: setActiveClass('UncoveredKeywords')}"
                     @click="switchCurrentTab('UncoveredKeywords')">
                  Uncovered Keywords
                </div>
                <div class="tabs-heading-item"
                     :class="{active: setActiveClass('CompetitorsMeta')}"
                     @click="switchCurrentTab('CompetitorsMeta')">
                  Competitors Meta
                </div>
              </div>

              <div class="tabs-content">
                <template v-if="componentTableDataLoaded">
                  <component :is="currentComponent"
                             :key="currentComponent + (isUncoveredKeywordsTab ? '-uncovered' : '')"
                             :country-code="currentCountryData?.code"
                             :countries-list="countriesList"
                             :current-country-code="currentCountryData?.code"
                             :current-country-name="currentCountryData?.name"
                             :current-redaction-id="currentRedactionId"
                             :countriesList="currentMetaLocalesList"
                             :editable-metas="appMetaData"
                             :selected-locales-with-titles="selectedLocalesWithTitles"
                             :applied-filters="appliedFilters"
                             :localesData="localesData"
                             :is-uncovered-keywords-tab="isUncoveredKeywordsTab"
                             @scroll-to-anchor="scrollToWords('instant')"
                             @keyword-deleted="updateTables"
                             @save-table-words="saveTableWords"
                             @remove-meta-keyword="removeMetaKeyword" />
                </template>
                <template v-else>
                  <div class="preloader-wrap">
                    <preloader-table-small loader-size="50px"></preloader-table-small>
                  </div>
                </template>
              </div>
            </div>
          </template>

          <template v-else>
            <div class="mobile-notification-wrap">
              <div class="mobile-notification">
                <div class="notification-image">
                  <img src="@/assets/images/mobile-notification.svg"
                       width="181"
                       height="133"
                       alt="Mobile notification image"/>
                </div>
                <div class="notification-text">
                  Unfortunately, this tool is only available for the desktop version 😢
                </div>
              </div>
            </div>
          </template>
        </template>

        <template v-else>
          <div class="google-play-layout">
            <div class="text-block">
              <div class="gp-title">
                The Meta editor tool is currently only available for AppStore applications
              </div>
              <div class="gp-subtitle">
                We are actively working on making this feature available for Google Play
                applications to provide you
                with a seamless experience across both platforms.
              </div>
            </div>
          </div>
        </template>
      </div>
    </main>

    <custom-modal :opened="showNewMetaSave"
                  :max-width="520"
                  class-name="save-new-meta-modal"
                  @closeModal="metaVersionNameModalCancelled">
      <div class="modal-message">
        Enter the Metadata Version Name
      </div>

      <div class="meta-name-input-block">
        <input type="text"
               class="meta-name-input"
               placeholder="Version Name"
               autofocus
               v-model="newMetaName">
        <div class="error-block"
             v-if="newMetaVersionNameIsOkay() === false">
          You already have version with this name
        </div>
      </div>

      <template v-slot:footer-action>
        <div class="btn-block action-btns">
          <base-button height="40px"
                       width="144px"
                       font-size="15px"
                       border-radius="4px"
                       :mode="'btn-standard outline'"
                       @click="metaVersionNameModalCancelled">
            Cancel
          </base-button>

          <template v-if="saveVersionClickOrigin === 'menu'">
            <base-button height="40px"
                         width="144px"
                         font-size="15px"
                         border-radius="4px"
                         :disabled="newMetaName?.length === 0 || newMetaVersionNameIsOkay() === false"
                         :mode="'btn-standard'"
                         @click="saveEditedMetaData('new')">
              Save
            </base-button>
          </template>
          <template v-else>
            <base-button height="40px"
                         width="144px"
                         font-size="15px"
                         border-radius="4px"
                         :disabled="newMetaName?.length === 0 || newMetaVersionNameIsOkay() === false"
                         :mode="'btn-standard'"
                         @click="saveOutterData">
              Save
            </base-button>
          </template>
        </div>
      </template>
    </custom-modal>

    <custom-modal :opened="showDeleteMeta"
                  :max-width="520"
                  class-name="save-new-meta-modal"
                  @closeModal="closeModal('showDeleteMeta')">
      <div class="modal-message">
        Do you really want to delete the metadata {{ metaItemToDelete?.name }}
      </div>

      <template v-slot:footer-action>
        <div class="btn-block action-btns">
          <base-button height="40px"
                       width="144px"
                       font-size="15px"
                       border-radius="4px"
                       :mode="'btn-standard outline'"
                       @click="closeModal('showDeleteMeta')">
            Cancel
          </base-button>

          <base-button height="40px"
                       width="144px"
                       font-size="15px"
                       border-radius="4px"
                       :mode="'btn-standard'"
                       @click="deleteMetaClicked">
            Delete
          </base-button>

        </div>
      </template>
    </custom-modal>

    <custom-modal :opened="showStoreKeywordsModal"
                  :max-width="920"
                  class-name="store-keywords-modal"
                  @closeModal="showStoreKeywordsModal = false">
      <store-keywords-modal v-if="countriesList.length > 0"
                            @close="storeKeywordsUpdated"
                            :key="currentCountryData?.code"
                            :countries-list="countriesList"
                            :current-country="currentCountryData">
      </store-keywords-modal>
    </custom-modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {httpRequest} from "@/api";
import {deleteCookie, formatDate, getCookie, getFlagEmoji, setCookie} from "@/utils/utils";
import {AppMetaDataObjectCreator} from "@/utils/factories";
import NotifyMeComponent from "@/views/MetaEditor/NotifyMeComponent/index.vue";
import CustomInlineSearch from "@/components/Forms/CustomInlineSearch/index.vue";
import Words from "@/views/MetaEditor/components/Words/index.vue";
import Keywords from "@/views/MetaEditor/components/Keywords/index.vue";
import CompetitorsMeta from "@/views/MetaEditor/components/CompetitorsMeta/index.vue";
import MetaEditorTable from "@/views/MetaEditor/components/MetaEditorTable/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import CustomSelect from "@/components/Forms/CustomSelect/index.vue";
import SlidingWindow from "@/components/SlidingWindow/index.vue";
import CustomDropdownAbsolute from "@/components/UI/CustomDropdownAbsolute/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import OrderRadasoButton from "@/components/OrderRadaso/Button/index.vue";
import DynamicImage from "@/components/UI/DynamicImage/index.vue";
import CustomMultiselectNew from "@/components/Forms/CustomMultipleSelect/CustomMultiselectNew.vue";
import LanguageSelector from "@/components/LanguageSelector/index.vue";
import FiltersNew from "@/components/UI/FiltersComponent/FiltersNew.vue";
import StoreKeywordsModal from "@/views/MetaEditor/components/StoreKeywordsModal/index.vue";
import HeaderMainOptions from "@/components/UI/HeaderMainOptions/index.vue";

export default {
  name: 'MetaEditor',
  beforeRouteLeave(to, from, next) {
    if (this.tableMode === 'edit') {
      const answer = window.confirm('Changes you made may not be saved.');
      if (!answer) {
        next(false);
      } else {
        next();
      }
    } else {
      next();
    }
  },
  data() {
    return {
      title: '',
      countryLocalesConfig: {
        border: 'none',
        borderRadius: '4px',
        boxShadow: '0px 4px 8px rgba(160, 190, 221, 0.3)',
        height: '36px',
      },
      countryLocaleSearchInputValue: '',
      countriesList: [],
      currentCountryData: {
        name: '',
        code: '',
        id: '',
      },
      countriesListLoaded: false,
      metaTableDataLoaded: false,
      componentTableDataLoaded: false,
      closeFromOutsideCounter: 0,
      currentTab: 'words',
      currentComponent: 'Words',
      tabDropdownOpened: false,
      tableMode: 'view',
      appMetaData: {},
      storeAppMetaData: {},
      editedAppMetaData: {},
      currentRedactionId: null,
      localeCodes: {},
      localesData: {},
      wordsToUseObject: {},
      currentMetaLocalesList: [],
      showNewMetaSave: false,
      showDeleteMeta: false,
      newMetaName: '',
      savedMetasArray: [],
      closeSavedMetaDropdown: 0,
      currentMetaVersion: {},
      metaItemToDelete: {},
      saveVersionClickOrigin: 'menu',
      lastChangesObject: {},
      isLocalesLoading: true,
      selectedLocales: [],
      maxSelectedLocales: 4,
      appliedFilters: {},
      showStoreKeywordsModal: false,
      isUncoveredKeywordsTab: false,
    }
  },
  components: {
    HeaderMainOptions,
    StoreKeywordsModal,
    FiltersNew,
    LanguageSelector,
    CustomMultiselectNew, DynamicImage,
    OrderRadasoButton,
    SvgIcon,
    'custom-select': CustomSelect,
    NotifyMeComponent,
    MetaEditorTable,
    Words,
    Keywords,
    CompetitorsMeta,
    'custom-inline-search': CustomInlineSearch,
    'preloader-table-small': PreloaderTableSmall,
    SlidingWindow,
    CustomDropdownAbsolute,
  },
  methods: {
    filtersApplied(e) {
      if (JSON.stringify(e) !== this.memoizedAppliedFilters) {
       this.appliedFilters = { ...e };
      }
    },
    localeChanged(data) {
      this.selectedLocales = [...data];

      this.selectedLocales.forEach((locale) => {
        if (!this.appMetaData.metas[locale]) {
          this.appMetaData.metas[locale] = this.storeAppMetaData.metas[locale];
        }
      });
    },
    async fetchCountriesList() {
      this.countriesListLoaded = false;
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.SEARCH_KEYWORDS_COUNTRIES + '/' + this.currentApp?.id + '/countries');

      if (result?.countries) {
        this.countriesList = [...result?.countries]?.sort((a, b) => (a?.name > b?.name) ? 1 : -1);
      }

      this.countriesListLoaded = true;
    },
    async countryClickedHandler(code, name, id) {
      let answer = true;
      const currCountryData = {
        name: name,
        code: code,
        id: id,
      }

      this.editedAppMetaData = {};

      if (this.tableMode === 'edit') {
        answer = window.confirm('Changes you made may not be saved.');

        if (answer) {
          this.currentRedactionId = null;
          this.tableMode = 'view';
          this.currentCountryData = {...currCountryData};
          this.closeFromOutsideCounter++;

          this.countryLocaleSearchInputValue = '';
          await this.fetchMetaBundleData();
        } else {
          this.closeFromOutsideCounter++;
        }
      } else {
        this.currentRedactionId = null;
        this.currentCountryData = {...currCountryData};
        this.closeFromOutsideCounter++;

        this.countryLocaleSearchInputValue = '';
        await this.fetchMetaBundleData();
      }
    },
    async fetchMetaBundleData() {
      this.isLocalesLoading = true;
      this.componentTableDataLoaded = false;
      this.localesData = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.user.COUNTRY_LOCALES + `/${this.currentApp?.store?.key}/${this.currentCountryData.code}/no`
      );
      await this.fetchAppSavedMetas();
      await this.fetchAppEditableMetas('store');
      this.setCurrentMetaVersion();
      await this.fetchAppEditableMetas();
      this.localeCodes = {...this.localesData?.popular};
      this.setDefaultLocale();
      this.componentTableDataLoaded = true;
      this.isLocalesLoading = false;
    },
    setDefaultLocale() {
      const changedLocales = this.getDifferenceBetweenSavedMetaAndCurrent();
      if (Object.keys(changedLocales).length > 0) {
        const storedLocales = JSON.parse(localStorage.getItem(this.getSelectedLocalesStorageKey()));
        this.selectedLocales = storedLocales && storedLocales.length > 0
            ? storedLocales
            : Object.keys(changedLocales).slice(0, this.maxSelectedLocales)
      } else {
        this.selectedLocales = [this.defaultLocale];
      }
    },
    async fetchAppSavedMetas() {
      this.showStoreKeywordsModal = false;
      const url = `?app_id=${this.currentApp?.id}&country_code=${this.currentCountryData?.code}`;
      const savedMetasArray = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.metaEditor.GET_APP_METAS + url
      );

      if (savedMetasArray) {
        savedMetasArray?.sort((a, b) => (a?.updated_at < b?.updated_at) ? 1 : -1);
      }

      this.savedMetasArray = savedMetasArray?.map(item => {
        let updated_at = item?.updated_at?.split(' ');
        let time = updated_at[1];
        let date = formatDate(updated_at[0], 'day-month-year');
        return {
          ...item,
          updated_at: `${date} ${time}`,
        }
      });

    },
    getDifferenceBetweenSavedMetaAndCurrent() {
      let savedMeta = this.storeAppMetaData?.metas;
      let currentMeta = this.appMetaData?.metas;
      let difference = {};

      for (const key in currentMeta) {
        if (JSON.stringify(savedMeta[key]) !== JSON.stringify(currentMeta[key])) {
          difference[key] = currentMeta[key];
        }
      }

      return difference;
    },
    async fetchAppEditableMetas(mode) {
      let url = `?app_id=${this.currentApp?.id}`;

      if (this.currentRedactionId && this.currentRedactionId !== 'undefined' && mode !== 'store') {
        url += `&redaction_id=${this.currentRedactionId}`;
      }

      this.setLocales(this.localesData?.popular).forEach((locale, idx) => {
        url += `&locale_codes[]=${locale.code}`;
      });

      const appMetaData = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.metaEditor.GET_APP_EDITABLE_METAS + url
      );
      this.appMetaData = AppMetaDataObjectCreator(appMetaData);
      this.editedAppMetaData = {...this.appMetaData};
      this.$store.dispatch('SET_EDITABLE_META', this.appMetaData);

      this.currentMetaLocalesList = Object.values(this.appMetaData?.metas)?.map(item => {
        return {
          localeName: item?.locale_name,
          localeCode: item?.locale_code,
        }
      });

      if (mode === 'store') {
        this.storeAppMetaData = {...this.appMetaData};
      }

      this.metaTableDataLoaded = true;
    },
    async saveAppMeta(mode = 'default', changesObject = null) {
      let formData = new FormData();
      let versionName = this.newMetaName || this.currentMetaVersion?.name;
      formData.append('app_id', this.currentApp?.id);
      formData.append('country_code', this.currentCountryData?.code);

      if (this.currentRedactionId && this.currentRedactionId !== 'undefined' && this.newMetaName === '') {
        formData.append('redaction_id', this.currentRedactionId ?? '');
      }

      if (!versionName) {
        formData.append('redaction_name', 'Untitled');
      } else {
        formData.append('redaction_name', versionName);
      }

      let metas;
      if (mode === 'words-update') {
        metas = this.appMetaData?.metas;
      } else {
        metas = this.editedAppMetaData?.metas;
      }
      let tempAppMetaData = {...this.appMetaData};

      for (const key in metas) {
        formData.append(`metas[${key}][title]`, metas[key]?.title?.text);
        formData.append(`metas[${key}][sub_title]`, metas[key]?.sub_title?.text);

        tempAppMetaData.metas[key].title.text = metas[key]?.title?.text;
        tempAppMetaData.metas[key].sub_title.text = metas[key]?.sub_title?.text;

        ['words_to_use_in_title', 'words_to_use_in_subtitle', 'keywords'].forEach(useInType => {
          tempAppMetaData.metas[key][useInType] = this.prepareWords(key, useInType, changesObject, metas);

          if (changesObject?.[useInType]) {
            ['words_to_use_in_title', 'words_to_use_in_subtitle', 'keywords'].forEach(type => {
              if (type !== useInType) {
                tempAppMetaData.metas[key][type] = tempAppMetaData.metas[key][type]?.filter(item => !tempAppMetaData.metas[key][useInType].includes(item));
              }
            });
          }
        });

        if (changesObject?.keywordToRemove !== undefined && changesObject?.localeCode === key) {
          tempAppMetaData.metas[key].keywords = tempAppMetaData.metas[key].keywords.filter(item => item !== changesObject.keywordToRemove);
        }

        ['words_to_use_in_title', 'words_to_use_in_subtitle', 'keywords'].forEach(useInType => {
          for (const subKey in tempAppMetaData.metas[key][useInType]) {
            formData.append(`metas[${key}][${useInType}][]`, tempAppMetaData.metas[key][useInType][subKey]);
          }
        });
      }

      this.appMetaData = {...tempAppMetaData};
      this.editedAppMetaData = {...this.appMetaData};
      const saveAppMetaRequest = await httpRequest(
        'POST',
        process.env.VUE_APP_API_URL + this.$ApiUrls.metaEditor.SAVE_APP_METAS,
        formData
      );

      if (saveAppMetaRequest?.id) {
        this.currentRedactionId = saveAppMetaRequest?.id;

        let versionName = (saveAppMetaRequest?.name || this.currentMetaVersion?.name || this.newMetaName) ?? 'Untitled';
        this.currentMetaVersion = {
          name: versionName,
          id: saveAppMetaRequest?.id,
        };
      }

      if (mode === 'words-update') {

      } else {
        await this.fetchAppEditableMetas(mode);
      }

      this.newMetaName = '';
    },
    prepareWords(key, useInType, changesObject, metas) {
      if (metas[key][useInType] === undefined) {
        metas[key][useInType] = [];
      }

      if (!changesObject || changesObject[useInType] === undefined) {
        return [...new Set([...metas[key][useInType]])];
      }

      if (changesObject?.localeCode === key) {
        return [...new Set([...metas[key][useInType], ...changesObject[useInType]])];
      }

      return useInType === 'keywords'
          ? metas[key][useInType]
          : metas[key][useInType].filter(item => !changesObject[useInType].includes(item));
    },
    async saveEditedMetaData(mode) {
      this.componentTableDataLoaded = false;
      this.tableMode = 'view';
      this.showNewMetaSave = false;

      if (mode === 'new') {
        deleteCookie('currentMetaRedactionId');
        this.currentRedactionId = null;
      }

      await this.saveAppMeta();
      await this.fetchAppSavedMetas();
      this.setCurrentMetaVersion();
      this.componentTableDataLoaded = true;
    },
    async saveCurrentVersion() {
      let versionName = this.newMetaName || this.currentMetaVersion?.name;

      if (!versionName) {
        this.showNewMetaSave = true;
        this.saveVersionClickOrigin = 'menu';
      } else {
        await this.saveEditedMetaData();
      }
    },
    async editableTableValueChanged(e) {
      const changedTableData = {
        localeCode: e.localeCode,
        itemType: e.itemType,
        newValue: e.newValue
      };

      let tempTableData = {...this.appMetaData};

      if (changedTableData?.itemType === 'words_to_use_in_title' || changedTableData?.itemType === 'words_to_use_in_subtitle') {
        let usedWordsData = [];

        if (typeof changedTableData?.newValue !== 'string' && typeof changedTableData?.newValue !== 'number') {
          usedWordsData = [...changedTableData?.newValue];
        } else {
          changedTableData?.newValue.split(' ').forEach(item => {
            usedWordsData.push(item);
          });
        }
        
        tempTableData.metas[changedTableData.localeCode][changedTableData.itemType] = [...new Set(usedWordsData)]?.filter(item => item !== '');

        ['words_to_use_in_title', 'words_to_use_in_subtitle'].filter(item => item !== changedTableData.itemType).forEach(item => {
          tempTableData.metas[changedTableData.localeCode][item] = tempTableData.metas[changedTableData.localeCode][item]?.filter(item => !usedWordsData.includes(item));
        });
      } else if (e?.itemType === 'keywords') {

        if (typeof changedTableData.newValue === 'string') {
          //if adding keyword is string with spaces, ex. Currency converter
          const splittedBySpace = changedTableData.newValue?.split(' ');
          if (splittedBySpace?.length > 0) {
            tempTableData.metas[changedTableData.localeCode][changedTableData.itemType] = splittedBySpace;
          } else {
            //if adding keyword is string with no spaces, ex. Currency
            tempTableData.metas[changedTableData.localeCode][changedTableData.itemType] = new Array(changedTableData.newValue);
          }

        } else {
          let tempKeywordArray = [];
          for (const key in changedTableData.newValue) {
            const splittedBySpace = changedTableData.newValue[key]?.split(' ');

            if (splittedBySpace?.length > 0) {
              tempKeywordArray = [...tempKeywordArray, ...splittedBySpace];
            } else {
              tempKeywordArray.push(changedTableData.newValue[key]);
            }
          }

          tempTableData.metas[changedTableData.localeCode][changedTableData.itemType] = [...tempKeywordArray];
        }

      } else {
        tempTableData.metas[changedTableData.localeCode][changedTableData.itemType].text = changedTableData.newValue;
      }

      this.editedAppMetaData = {...tempTableData};
    },
    removeMetaKeyword(locale, word) {
      const changesObject = {
        localeCode: locale,
        keywordToRemove: word,
      }
      this.saveChanges(changesObject);
    },
    async saveChanges(changesObject) {
      let versionName = this.newMetaName || this.currentMetaVersion?.name;

      if (!versionName) {
        this.showNewMetaSave = true;
        this.saveVersionClickOrigin = 'from-table';
        this.lastChangesObject = {...changesObject};
      } else {
        await this.saveAppMeta('words-update', changesObject);
        this.lastChangesObject = {};
      }
    },
    async saveTableWords(locale, wordsToUseInTitle, wordsToUseInSubtitle, newKeywords) {
      const changesObject = {
        localeCode: locale,
        words_to_use_in_title: wordsToUseInTitle,
        words_to_use_in_subtitle: wordsToUseInSubtitle,
        keywords: newKeywords,
      }
      await this.saveChanges(changesObject);
    },
    async saveOutterData() {
      this.showNewMetaSave = false;
      await this.saveAppMeta('words-update', this.lastChangesObject);
      await this.fetchAppSavedMetas();
      this.setCurrentMetaVersion();
      this.lastChangesObject = {};
    },
    async updateTables(e) {
      this.componentTableDataLoaded = false;
      await this.fetchMetaBundleData();
      this.componentTableDataLoaded = true;
    },
    async deleteMetaClicked() {
      let formData = new FormData();
      formData.append('redaction_ids[]', this.metaItemToDelete?.id);
      await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.metaEditor.DELETE_SAVED_META, formData);

      if (this.metaItemToDelete?.id === this.currentRedactionId) {
        this.currentRedactionId = null;
        deleteCookie('currentMetaRedactionId');
        this.componentTableDataLoaded = false;
      }

      this.showDeleteMeta = false;
      await this.fetchAppSavedMetas();
      this.setCurrentMetaVersion();

      await this.fetchAppEditableMetas();
      this.componentTableDataLoaded = true;
    },
    async changeAppMeta(metaItem) {
      if (metaItem) {
        this.currentRedactionId = metaItem?.id;
        setCookie('currentMetaRedactionId', metaItem?.id, process.env.VUE_APP_COOKIE_DOMAIN, 0.5);
      } else { // store redaction
        this.currentRedactionId = null;
        deleteCookie('currentMetaRedactionId');
      }

      this.currentMetaVersion = this.savedMetasArray?.find(meta => meta?.id === metaItem?.id);
      this.closeSavedMetaDropdown++;
      this.componentTableDataLoaded = false;
      await this.fetchAppEditableMetas();
      setTimeout(() => {
        this.componentTableDataLoaded = true;
      }, 150);
    },
    countriesLocalesInputChanged(value) {
      this.countryLocaleSearchInputValue = value;
    },
    switchCurrentTab(tabName) {
      this.currentTab = tabName;

      if (tabName === 'UncoveredKeywords') {
        this.currentComponent = 'keywords';
        this.isUncoveredKeywordsTab = true;
      } else {
        this.currentComponent = tabName;
        this.isUncoveredKeywordsTab = false;
      }

      this.tabDropdownOpened = false;
    },
    setActiveClass(tabName) {
      return this.currentTab === tabName;
    },
    setLocales(obj) {
      let localesArr = [];
      for (const key in obj) {
        localesArr.push({
          code: key,
          name: obj[key],
        })
      }
      return localesArr;
    },
    flagEmoji(val) {
      return getFlagEmoji(val);
    },
    fetchDownloadData(appId) {
      const currentAppId = appId ?? this.currentApp?.id;
      const redactionId = this.currentRedactionId ?? '';
      let url = `?app_id=${currentAppId}&redaction_id=${redactionId}`;
      for (const key in this.localeCodes) {
        url += `&locale_codes[]=${key}`;
      }

      return process.env.VUE_APP_URL + this.$ApiUrls.exportsUrls.META_CREATOR + url;
    },
    scrollToWords(mode = 'smooth') {
      document.querySelector('#blured-scroll-anchor').scrollIntoView({
        behavior: mode
      });
    },
    handleBeforeUnload(event) {
      if (this.tableMode === 'edit') {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    saveNewMetaClicked() {
      this.newMetaName = '';
      this.showNewMetaSave = true;
      this.saveVersionClickOrigin = 'menu';
    },
    openMetaDeleteModal(deleteItem) {
      this.metaItemToDelete = {...deleteItem};
      this.showDeleteMeta = true;
    },
    setCurrentMetaVersion() {
      let tempCurrentMetaVersion = {};
      if (!this.currentRedactionId) {
        if (this.savedMetasArray?.length > 0) {
          tempCurrentMetaVersion = {...this.savedMetasArray[0]};
          this.currentRedactionId = tempCurrentMetaVersion?.id;
        } else {
          this.currentRedactionId = null;
          deleteCookie('currentMetaRedactionId');
        }
      } else {
        const meta = this.savedMetasArray?.find(item => item?.id === this.currentRedactionId);
        tempCurrentMetaVersion = {...meta};
      }

      this.currentMetaVersion = {
        name: tempCurrentMetaVersion?.name,
        id: tempCurrentMetaVersion?.id,
        updated_at: tempCurrentMetaVersion?.updated_at,
      }
    },
    newMetaVersionNameIsOkay() {
      const metaIdx = this.savedMetasArray?.findIndex(item => item?.name === this.newMetaName);
      return metaIdx < 0;
    },
    metaVersionNameModalCancelled() {
      this.newMetaName = '';
      this.closeModal('showNewMetaSave');
    },
    openPlansModal() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
    storeKeywordsUpdated(updatedKeywords) {
      this.fetchAppSavedMetas();

      for (let locale in updatedKeywords) {
        if (updatedKeywords.hasOwnProperty(locale) && this.storeAppMetaData.metas[locale]) {
          this.storeAppMetaData.metas[locale].keywords = updatedKeywords[locale];
        }
      }

      this.saveCurrentVersion();
    },
    getCountryLocalStorageKey() {
      return 'meta_editor_country_for_app_' + this.currentApp.id;
    },
    getSelectedLocalesStorageKey() {
      return `selected_locales_for_app_${this.currentApp.id}_country_${this.currentCountryData.code}`;
    },
  },
  async mounted() {
    this.currentRedactionId = getCookie('currentMetaRedactionId');

    window.addEventListener('beforeunload', this.handleBeforeUnload);

    const savedCountry = JSON.parse(localStorage.getItem(this.getCountryLocalStorageKey()));
    const tempDataObj = {
      name: savedCountry ? savedCountry.name : this.currentCountry?.name,
      code: savedCountry ? savedCountry.code : this.currentCountry?.code,
      id: savedCountry ? savedCountry.id :this.currentCountry?.id,
    };

    this.currentCountryData = {...tempDataObj};
    await this.fetchCountriesList();
    this.closeFromOutsideCounter++;
    this.countryLocaleSearchInputValue = '';
    await this.fetchMetaBundleData();

    this.setCurrentMetaVersion();
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'userData',
      'sidebarIsCollapsed',
      'currentCountry',
      'currentCountryManual',
      'isTablet',
      'isMobile',
      'userSubscription',
    ]),
    filterConfigs() {
      return [
        {
          type: 'range',
          title: 'Daily Impression',
          filter: 'range_daily_impression',
          minLimit: 0,
          selectedValue: {
            min: this.appliedFilters['filter[range_daily_impression][from]'] ?? null,
            max: this.appliedFilters['filter[range_daily_impression][to]'] ?? null,
          },
        },
        {
          type: 'range',
          title: 'Popularity',
          filter: 'range_sap',
          minLimit: 0,
          maxLimit: 100,
          selectedValue: {
            min: this.appliedFilters['filter[range_sap][from]'] ?? null,
            max: this.appliedFilters['filter[range_sap][to]'] ?? null,
          },
        },
        {
          type: 'range',
          title: 'App Rank',
          filter: 'range_rank',
          minLimit: 0,
          maxLimit: 50,
          selectedValue: {
            min: this.appliedFilters['filter[range_rank][from]'] ?? null,
            max: this.appliedFilters['filter[range_rank][to]'] ?? null,
          },
        },
        {
          type: 'range',
          title: 'Results',
          filter: 'range_applications_count',
          minLimit: 0,
          maxLimit: 2000,
          selectedValue: {
            min: this.appliedFilters['filter[range_applications_count][from]'] ?? null,
            max: this.appliedFilters['filter[range_applications_count][to]'] ?? null,
          },
        },
      ]
    },
    memoizedAppliedFilters() {
      return JSON.stringify(this.appliedFilters);
    },
    countriesListComputed() {
      const tempArray = [...this.countriesList];
      const loweredInput = this.countryLocaleSearchInputValue?.toLowerCase();
      return tempArray?.filter(country => country?.name?.toLowerCase()?.includes(loweredInput))
    },
    localesObject() {
      return {
        default: this.localesData?.default,
        popular: this.setLocales(this.localesData?.popular),
        other: [],
      };
    },
    defaultLocale() {
      return this.localesData?.default ?? 'en';
    },
    localesToDisplay() {
      return this.selectedLocales.length > 0 ? this.selectedLocales : [this.defaultLocale];
    },
    selectedLocalesWithTitles() {
      return this.localesToDisplay.map(locale => {
        return {
          code: locale,
          title: this.localesData?.popular[locale],
        }
      });
    },
  },
  watch: {
    async currentApp(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.currentRedactionId = null;
        deleteCookie('currentMetaRedactionId');
        this.metaTableDataLoaded = false;
        this.componentTableDataLoaded = false;
        deleteCookie('metadataStoreData');

        this.currentCountryData = {
          name: this.currentCountry?.name,
          code: this.currentCountry?.code,
          id: this.currentCountry?.id,
        };
        await this.fetchCountriesList();
        this.closeFromOutsideCounter++;
        this.countryLocaleSearchInputValue = '';
        await this.fetchMetaBundleData();
        this.metaTableDataLoaded = true;
        this.componentTableDataLoaded = true;
      }
    },
    async currentCountryManual(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        deleteCookie('metadataStoreData');
        this.currentRedactionId = null;
        deleteCookie('currentMetaRedactionId');
        this.metaTableDataLoaded = false;
        this.componentTableDataLoaded = false;

        this.currentCountryData = {
          name: newVal?.name,
          code: newVal?.code,
          id: newVal?.id,
        };
        await this.fetchCountriesList();
        await this.countryClickedHandler(newVal?.code, newVal?.name, newVal?.id);
        this.metaTableDataLoaded = true;
        this.componentTableDataLoaded = true;
      }
    },
    currentCountryData(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        localStorage.setItem(this.getCountryLocalStorageKey(), JSON.stringify(this.currentCountryData));
      }
    },
    newMetaName(newVal, oldVal) {
      this.newMetaVersionNameIsOkay();
    },
    selectedLocales(newVal, oldVal) {
      if (this.selectedLocales.length > 0) {
        localStorage.setItem(
            this.getSelectedLocalesStorageKey(),
            JSON.stringify(this.selectedLocales),
        );
      }
    }
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>
<style lang="scss">
.meta-editor-tabs-content {
  .advanced-custom-data-table.words-custom-table {
    max-height: calc(100vh - 280px);
  }
}
</style>