<script>
import CustomInputCounter from "../../Forms/CustomInputCounterNew/index.vue";
import SvgIcon from "../SvgIcon/index.vue";
import CustomMultipleSelect from "../../Forms/CustomMultipleSelect/index.vue";
import RangeFilterValue from "./RangeFilterValue";
import FilterItem from "./FilterItem";
import MultiselectFilterValue from "./MultiselectFilterValue";
import CustomMultiselectNew from "../../Forms/CustomMultipleSelect/CustomMultiselectNew.vue";
import FilterItemComp from "./FilterItemComp.vue";

export default {
  name: "FiltersNew",
  components: {FilterItemComp, FilterItem, CustomMultiselectNew, CustomMultipleSelect, SvgIcon, CustomInputCounter},
  emits: ['apply-filters'],
  props: {
    state: {
      type: Object,
      default: () => {
        return null;
      }
    },
    filters: {
      type: Array,
      required: true
    }
  },
  created() {
    //TODO нужно старый формат пересмотреть и стандартизировать
    if (this.state) {
      this.setState(this.state);
      this.resetKey++;

      return;
    }

    this.normalizeFilters();
  },
  data() {
    return {
      opened: false,
      resetKey: 0,

      filtersNormalized: [],
      lastAppliedFilters: [],

      lastAppliedFiltersBackup: [],
    };
  },
  computed: {
    filtersChangedCount() {
      return this.lastAppliedFilters.filter(filter => filter.value.isChanged()).length;
    },
    leftColumnFilters() {
      const totalFilters = this.filtersNormalized.length;
      const half = Math.ceil(totalFilters / 2);
      return this.filtersNormalized.slice(0, half);
    },
    rightColumnFilters() {
      const totalFilters = this.filtersNormalized.length;
      const half = Math.ceil(totalFilters / 2);
      return this.filtersNormalized.slice(half);
    }
  },
  watch: {
    filters(newValue, oldValue) {
      this.lastAppliedFiltersBackup = [...this.lastAppliedFilters.map(filter => filter.clone())];
      this.lastAppliedFiltersBackup = this.lastAppliedFiltersBackup.filter(filter => {
        let newFilter = newValue.find(item => item.filter === filter.key);
        if (newFilter) {
          return !newFilter.isAlwaysChanged;
        }
        return true;
      });

      this.normalizeFilters();
      this.backupValues();
      this.applyFilters();
    }
  },
  methods: {
    backupValues() {
      this.lastAppliedFiltersBackup.forEach(filter => {
        if (!filter.value.isChanged()) {
          return;
        }

        let filterNormalized = this.filtersNormalized.find(item => item.key === filter.key);
        if (filterNormalized) {
          filterNormalized.value = filter.value.clone();
        }
        let lastAppliedFilter = this.lastAppliedFilters.find(item => item.key === filter.key);
        if (lastAppliedFilter) {
          lastAppliedFilter.value = filter.value.clone();
        }
      });
    },
    getState() {
      return {
        filtersNormalized: this.filtersNormalized,
        lastAppliedFilters: this.lastAppliedFilters,
      }
    },
    setState(state) {
      this.filtersNormalized = state.filtersNormalized;
      this.lastAppliedFilters = state.lastAppliedFilters;
    },
    normalizeFilters() {
      this.filtersNormalized = this.filters.map(filter => {
        let value = null;

        if (filter.type === 'range') {
          value = new RangeFilterValue(filter.minLimit, filter.maxLimit, filter.min, filter.max);
          if (filter.selectedValue?.min) {
            value.setMinValue(filter.selectedValue?.min);
          }
          if (filter.selectedValue?.max) {
            value.setMaxValue(filter.selectedValue?.max);
          }
        } else if (filter.type === 'dropdown-multi') {
          value = new MultiselectFilterValue(
            filter.presetsData.map(item => {
              return {
                value: item.filterValue,
                label: item.presetValue,
              };
            }),
            filter.defaultValue
          );
          if (Array.isArray(filter.selectedValue)) {
            value.setSelectedValues(filter.selectedValue);
          }
        }

        if (value && filter.isAlwaysChanged) {
          value.setAlwaysChanged();
        }

        let filterItem = new FilterItem();
        filterItem.type = filter.type;
        filterItem.name = filter.title;
        filterItem.key = filter.filter;
        filterItem.value = value;
        if (filter.hidden) {
          filterItem.hidden = true;
        }
        return filterItem;

      }).filter(filter => filter.value !== null);

      this.lastAppliedFilters = [...this.filtersNormalized.map(filter => filter.clone())];

      this.resetKey++;
    },
    applyFilters() {
      this.opened = false;
      this.lastAppliedFilters = [...this.filtersNormalized.map(filter => filter.clone())];

      let result = {};
      this.lastAppliedFilters.forEach(filter => {
        if (!filter.value.isAlwaysChanged && !filter.value.isChanged()) {
          return;
        }

        let collectedData = filter.collectedData();
        for (let key in collectedData) {
          result[key] = collectedData[key];
        }
      });

      this.$emit('apply-filters', result);
    },
    toggleOpen() {
      this.opened = !this.opened;
    },
    close(e) {
      const setLastAppliedFilters = () => {
        this.filtersNormalized = [...this.lastAppliedFilters.map(filter => filter.clone())];
        this.resetKey++;
      };

      if (e.target.closest('.close')) {
        this.opened = false;
        setLastAppliedFilters();
        return;
      }
      if (e.target.closest('.common-btn-purple')) {
        return;
      }
      if (e.target.closest('.filters-container')) {
        return;
      }
      if (e.target.dataset.action === 'filter-reset') {
        return;
      }

      this.opened = false;
      setLastAppliedFilters();
    },
    clearAll() {
      this.filtersNormalized.forEach(filter => {
        filter.value.reset();
      });
      this.resetKey++;
    },
    clearFilter(filter) {
      filter.value.reset();
      this.resetKey++;
    },
    clearAllAndApply() {
      this.clearAll();
      this.applyFilters();
    }
  },
}
</script>

<template>
  <div class="pos-relative">
    <div class="common-btn-purple filters-toggle-btn" @click="toggleOpen">
      <slot name="buttonText" :filtersChangedCount="filtersChangedCount">
        Filters <span v-if="filtersChangedCount > 0">&nbsp;({{ filtersChangedCount }})</span>
      </slot>
    </div>
    <div class="filters-container common-white-container" v-show="opened" v-click-outside="close">
      <div class="close cursor-pointer" @click="close">
        <svg-icon icon="close"></svg-icon>
      </div>
      <div class="display-flex f-align-start">
        <div>
          <template v-for="filter in leftColumnFilters" :key="filter.key">
            <FilterItemComp :reset-key="resetKey" :filter="filter" @clear-filter="clearFilter"/>
          </template>
        </div>
        <div v-if="rightColumnFilters.length" class="ml-15">
          <template v-for="filter in rightColumnFilters" :key="filter.key">
            <FilterItemComp :reset-key="resetKey" :filter="filter" @clear-filter="clearFilter"/>
          </template>
        </div>
      </div>
      <div class="filters-nav">
        <div class="common-btn-purple-transparent" @click="clearAllAndApply">Clear All</div>
        <div class="common-btn-purple" @click="applyFilters">Apply</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.filters-nav {
  display: flex;
  margin-top: 20px;

  :first-child {
    margin-right: 10px;
  }
}

.close {
  color: #a3b0c5;
  position: absolute;
  right: 13px;
  top: 7px;
  font-size: 13px;
}

.filters-container {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(0, calc(100% + 5px));
  background-color: #fff;
  z-index: 30;
  padding: 10px;
}
</style>