<script>
import AdaptiveDataTable from "@/components/DataTables/AdaptiveDataTable/index.vue";
import SapTool from "@/components/DataTables/Tools/SapTool/index.vue";
import TopBlock from "@/components/DataTables/Tools/ExpandRankTableChart/TopBlock.vue";
import {formatDate} from "@/utils/utils";
import ChartBlock from "@/components/DataTables/Tools/ExpandRankTableChart/ChartBlock.vue";
import DatepickerWithRange from "@/components/UI/DatepickerWithRange/index.vue";
import SapChartModal from "@/components/Modals/SapChartModal/index.vue";
import catalogs from "@/api/modules/catalogs";
import DatepickerWithRangeOld from "@/components/UI/DatepickerWithRangeOld/index.vue";
import {cropImage} from "../../../../utils/utils";
import Tooltip from "../../../UI/Tooltip/index.vue";

export default {
  name: "ExpandRankTableChart",
  components: {
    Tooltip,
    SapChartModal, DatepickerWithRange, ChartBlock, TopBlock, SapTool, AdaptiveDataTable, DatepickerWithRangeOld
  },
  props: {
    app: {},
    country: {},
    keyword: {},

    hasCloseBtn: {
      type: Boolean,
      default: true,
    },
    additionalTitles: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      topsDataByAppRank: null,
      topsDataBySap: null,
      chartData: null,
      loaded: false,

      minInitDate: null,
      maxInitDate: null,
      minDate: null,
      maxDate: null,
      dateFilter: {},

      modalCountry: null,
      showTableChartModal: false,
    }
  },
  emits: ['close'],
  async mounted() {
    this.maxDate = new Date();

    let minDate = new Date();
    minDate.setMonth(minDate.getMonth() - 4);
    this.minDate = minDate;

    let minInitDate = new Date();
    minInitDate.setMonth(minInitDate.getMonth() - 4);
    this.minInitDate = minInitDate;

    let initMaxDate = new Date();
    initMaxDate.setDate(initMaxDate.getDate());

    this.maxInitDate = initMaxDate;

    this.dateFilter = {
      start: this.minInitDate,
      end: this.maxInitDate,
    };

    await this.loadData();
  },
  methods: {
    cropImage,
    handleSapModal(e) {
      this.modalCountry = e.country;
      this.openModal('showTableChartModal');
    },
    handleAddTracking(e) {
      let result = this.saveAddedKeywordsFunc([
            this.keyword,
          ], this.app.id, e.country.code
      );

      e.row.tracking.is_tracked = true
    },
    async loadData() {
      let dateFrom = formatDate(this.dateFilter.start, 'digits-dash');
      let dateTo = formatDate(this.dateFilter.end, 'digits-dash');

      let countries = await catalogs.getCountriesByStore(this.app.store.key);

      let queryArray = [
        {
          url: process.env.VUE_APP_API_URL + 'api/rankings/keyword-history' + '?store_key=' + this.app.store.key + '&country_code=' + this.country.code + '&keyword=' + this.keyword + '&date_from=' + dateFrom + '&date_to=' + dateTo,
          method: 'GET',
        },
      ];

      const chunkSize = 10;
      for (let i = 0; i < countries.length; i += chunkSize) {
        let chunk = countries.slice(i, i + chunkSize);
        let countryCodesParam = '';
        chunk.forEach((country) => {
          countryCodesParam += '&country_codes[]=' + country.code;
        });
        queryArray.push({
          url: process.env.VUE_APP_API_URL + 'api/rankings/keyword-app-ranking-countries' + '?keyword=' + this.keyword + '&date_from=' + dateFrom + '&date_to=' + dateTo + '&app_id=' + this.app.id + countryCodesParam,
          method: 'GET',
        });
      }

      this.loaded = false;
      this.topsDataByAppRank = null;
      this.topsDataBySap = null;
      this.chartData = null;
      await this.fetchRequestsBundle(queryArray).then(responses => {
        this.chartData = Object.entries(responses[0]);

        let topResponses = {};
        for (let i = 1; i < responses.length; i++) {
          topResponses = {...topResponses, ...responses[i]};
        }

        let topsData = Object.values(topResponses);

        this.topsDataByAppRank = topsData.filter((d) => d.last_rank_info.rank !== 'N/A' && d.last_rank_info.rank !== '-').sort(this.sortTopDataByRank).slice(0, 10);

        this.topsDataBySap = topsData.sort((a, b) => {
          let sapA = a.sap.value;
          let sapB = b.sap.value;

          if (sapA === sapB) {
            return this.sortTopDataByRank(a, b);
          }

          return sapA > sapB ? -1 : 1;
        }).filter((d) => d.sap?.value > 1).slice(0, 10);

        this.loaded = true;
      });
    },
    /**
     * Sort by rank
     * If ranks are equal - sort by difference
     * If rank differences are equal - sort by country name
     */
    sortTopDataByRank(a, b) {
      let rankA = a.last_rank_info.rank;
      if (rankA === null) {
        rankA = 999;
      }
      let rankB = b.last_rank_info.rank;
      if (rankB === null) {
        rankB = 999;
      }

      rankA = parseInt(rankA);
      rankB = parseInt(rankB);

      if (rankA === rankB) {
        let diffA = a.last_rank_info.difference;
        if (diffA === 'in') {
          diffA = 9999;
        } else if (diffA === 'out') {
          diffA = -9999;
        }
        let diffB = b.last_rank_info.difference;
        if (diffB === 'in') {
          diffB = 9999;
        } else if (diffB === 'out') {
          diffB = -9999;
        }

        diffA = parseInt(diffA);
        diffB = parseInt(diffB);

        if (diffA === diffB) {
          return a.country.name.localeCompare(b.country.name);
        }

        return diffA > diffB ? -1 : 1;
      }

      return rankA < rankB ? -1 : 1;
    },
    async resetDatePicker() {
      this.dateFilter = {
        start: this.minInitDate,
        end: this.maxDate,
      };

      await this.loadData();
    },
    async applyDatePicker(event) {
      this.dateFilter = {
        start: event?.start ?? this.minInitDate,
        end: event?.end ?? this.maxDate,
      };

      await this.loadData();
    },
  },
}
</script>

<template>
  <div class="expand_rank_block">
    <template v-if="loaded">
      <div class="chart_block">
        <chart-block
            :app="app"
            :keyword="keyword"
            :chart-raw-data="chartData"
            :show-app-title="additionalTitles"
            :without-border="additionalTitles"
        >
          <template v-slot:datepicker>
            <datepicker-with-range-old
                :to-right="true"
                :date-filter="dateFilter"
                :min-date="minDate"
                :max-date="maxDate"
                :columns="2"
                @date-filter-changed="applyDatePicker"/>
          </template>
        </chart-block>
      </div>
      <div class="top_blocks">
        <TopBlock
            :rows="topsDataByAppRank"
            :store="this.app.store"
            :keyword="keyword"
            @sap-modal-trigger-clicked="handleSapModal"
            @add-tracking="handleAddTracking"
        >
          <template v-slot:title>
            <span v-if="!additionalTitles">Top Countries by App Rank</span>
            <span v-else class="display-flex">
              <tooltip width="max-content">
                <template v-slot:slotTrigger>
                  <div class="display-flex">
                    <img class="mr-10" height="24" width="24" :src="cropImage(app.logo, '24x24', app.store.key)">
                  </div>
                </template>
                <template v-slot:slotContent>{{app.title}}</template>
              </tooltip>
              Best Rank Countries
            </span>
          </template>
        </TopBlock>
        <TopBlock
            :rows="topsDataBySap"
            :store="this.app.store"
            :keyword="keyword"
            @sap-modal-trigger-clicked="handleSapModal"
            @add-tracking="handleAddTracking"
        >
          <template v-slot:title>
            <span v-if="!additionalTitles">Top Countries by Popularity</span>
            <span v-else>Keyword Popularity Across Countries</span>
          </template>
        </TopBlock>
      </div>
    </template>
    <div v-else style="height: 600px; position: relative">
      <progress-indicator>Loading data...</progress-indicator>
    </div>

    <div @click="$emit('close')" class="close_block" v-if="hasCloseBtn">
      <svg-icon icon="angle-left-solid"/>
    </div>
  </div>

  <custom-modal :opened="showTableChartModal"
                :max-width="728"
                class-name="popularity-chart-modal"
                @closeModal="showTableChartModal = false">
    <sap-chart-modal :current-country-code="modalCountry.code"
                     :current-country-name="modalCountry.name"
                     :current-keyword-item="keyword"/>
  </custom-modal>
</template>

<style scoped lang="scss">
.expand_rank_block {
  width: 100%;
  border-bottom: 1px solid var(--neutral-400);

  .close_block {
    text-align: center;
    cursor: pointer;
    padding: 10px 0 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: var(--neutral-100);
    }

    svg {
      transform: rotate(90deg);
      font-size: 30px;
      color: var(--neutral-600);
    }
  }

  .chart_block {
    padding: 20px;
  }

  .top_blocks {
    display: flex;
    justify-content: space-between;

    .top_block {
      min-width: max-content;
    }

    > :first-child {
      padding-right: 10px;
    }

    > :last-child {
      padding-left: 10px;
    }
  }
}
</style>