<template>
  <div class="table-wrap meta-editor-keywords-table-wrap">
    <template v-if="keywordsLoaded">
      <div class="words-table keywords">
        <SimpleInfoTable :table-id="'meta-editor-keywords-table'"
                         :full-width-section="true"
                         :data-provider="keywordsDataProvider"
                         :columns="{
                         keyword: {header: 'Keyword', orientation: 'left', sortBy: 'keyword',  style: {minWidth: '125px'}},
                         coverage: {header: '', alt: 'Coverage'},
                         locale: {header: '', orientation: 'left', alt: 'Locales'},
                         popularity: {
                           header: 'Popul.',
                           sortBy: 'popularity',
                           alt: 'Popularity',
                           tooltip: 'Popularity - App Store popularity ranges from 5-100, with a score of 5 indicating low or no search volume. We assign a special value of 1 in cases where a popularity value of 5 is received consistently from the App Store for over 30 days, indicating extremely low or no search activity.',
                         },
                         impression: {
                           header: 'KDI',
                           sortBy: 'daily_impression',
                           tooltip: 'Estimated Keyword Daily Impressions - this metric shows the estimated number of impressions a keyword receives each day in a given country. This metric indicates keyword popularity and does not reflect the number of impressions your app will get based on its ranking.',
                         },
                         app_rank: {
                           header: 'App Rank',
                           sortBy: (item) => sortByRank(item.latest_rank.rank),
                           tooltip: 'App Rank - your app\'s ranking for this keyword in the App Store search results for the specified country.',
                         },
                         best_rank: {
                           header: 'Best Rank',
                             sortBy: (item) => sortByRank(item.best_rank),
                           tooltip: 'The highest position your app achieved for this keyword in App Store search results over the last 30 days.',
                         },
                         results: {
                           header: 'Res.',
                           sortBy: 'results',
                           alt: 'Results',
                           tooltip: 'Results - the number of apps that rank for this keyword in the App Store search results.'
                         },
                         competitors: {header: 'Comp. in Top 10', sortBy: (item) => item.competitors_top_10.competitors ?? 0},
                       }"
                         :search-by="['keyword']"
                         default-sort-direction="desc"
                         default-sort-index="popularity"
                         :configurable-columns="true"
                         table-id="meta-editor-keywords-table"
                         @search-input-changed="(v) => {searchInputValue = v}"
        >
          <template v-slot:searchFilterBlock>
            <div class="filters-wrap">
              <languages-filter :languages="languages"
                                @apply="selectedLanguages = [...$event]"/>
              <custom-dropdown v-if="isUncoveredKeywordsTab" :min-width="'140px'" :close-dropdown="closeDropdownCounter"
                               class="keywords-dropdown"
              >
                <template v-slot:header>
                  <span class="capitalized">{{ activeFilters.uncoveredKeywordsFilter ?? 'All kw'}}</span>
                </template>
                <template v-slot:content>
                  <div class="select-options" v-for="filter in uncoveredKeywordsFilters" :key="filter">
                    <div class="option"
                         @click="updateFilters({uncoveredKeywordsFilter: filter}); closeDropdownCounter++">
                      <span class="capitalized">{{ filter }}</span>
                    </div>
                  </div>
                </template>
              </custom-dropdown>
            </div>
          </template>
          <template v-slot:headersAfter="slotProps">
            <div v-if="slotProps.index === 'keyword'">
              <translate-tool :active="allKeywordsTranslated" @translate-clicked="translateAllKeywords"/>
            </div>
          </template>
          <template v-slot:full-width-sections="slotProps">
            <transition name="height-collapse">
              <div v-if="slotProps.item.opened" style="overflow: hidden">
                <expand-rank-table-chart
                    @close="handleChartClick(slotProps.item)"
                    :app="currentApp"
                    :country="currentCountry"
                    :keyword="slotProps.item.keyword"
                />
              </div>
            </transition>
          </template>
          <template v-slot:headers="slotProps">
            <div v-if="slotProps.index === 'coverage'" class="coverage header-locale">
              <div class="locale-wrap">
                <div class="locale" >
                  <div class="title">
                    <div>
                      <coverage-tooltip :is-keywords-mode="true">
                        Coverage
                      </coverage-tooltip>
                    </div>
                  </div>
                  <div class="icons" >
                    <div>
                      <coverage-tooltip :is-keywords-mode="true">
                        <img src="@/assets/images/icons/app_store_gray.svg"
                             width="16"
                             height="16">
                      </coverage-tooltip>
                    </div>

                    <div>
                      <coverage-tooltip :is-keywords-mode="true">
                        <svg-icon icon="document"/>
                      </coverage-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="slotProps.index === 'locale'" class="header-locale">
              <div v-for="locale in selectedLocalesWithTitles" class="locale-wrap">
                <div class="locale">
                  <div class="title" >
                    <custom-tooltip width="300px">
                      <template v-slot:slotTrigger>
                        {{ locale.code.toUpperCase() }}
                      </template>
                      <template v-slot:slotContent>
                        <span style="font-weight: 600;">{{ locale.title }} Locale.</span> Review and compare where a word is used in the current App Store metadata and the new edited metadata you create using our metaeditor. Click the checkbox to add this word to the Keywords field for this locale.
                      </template>
                    </custom-tooltip>
                  </div>
                  <div class="icons" >
                    <div>
                      <img src="@/assets/images/icons/app_store_gray.svg"
                           width="16"
                           height="16"
                      >
                    </div>
                    <div>
                      <svg-icon icon="document"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <custom-tooltip width="250px" v-if="slotProps.tooltip">
                <template v-slot:slotTrigger>
                  {{ slotProps.header }}
                </template>
                <template v-slot:slotContent>
                  {{ slotProps.tooltip }}
                </template>
              </custom-tooltip>
              <template v-else>
                {{ slotProps.header }}
              </template>
            </div>
          </template>
          <template v-slot:items-keyword="slotProps">
            <keyword-tool-new
                :value="slotProps.item.keyword"
                :translated-value="slotProps.item.translate"
                :search-input-value="searchInputValue"
            >
              <template v-slot:hoverSection>
                <div class="display-flex actions-block">
                  <div class="mr-12">
                    <translate-one-tool :set-result-to="slotProps.item" :to-translate-string="slotProps.item.keyword"></translate-one-tool>
                  </div>
                  <div class="tool-item" v-tooltip="{ text: 'Live search', position: 'left', classes: ['no-wrap-text'] }">
                    <svg-icon icon="livesearch-icon" class="live-search-icon cursor-pointer hover-color-secondary-yellow" @click="liveSearchTriggerHandler(slotProps.item)"/>
                  </div>
                  <div class="tool-item" v-tooltip="{ text: 'Autosuggest', position: 'left' }">
                    <svg-icon icon="autosuggest-icon" class="autosuggest-icon cursor-pointer" @click="autoSuggestTriggerHandler(slotProps.item)"/>
                  </div>
                </div>
              </template>
            </keyword-tool-new>
          </template>
          <template v-slot:items-coverage="slotProps">
            <meta-coverage-indicator :coverage="slotProps.item.meta_entry"
                                     :is-keywords-mode="true"
                                     :locales-titles="localesData.popular"
                                     :redaction-coverage="slotProps.item.meta_entry_redaction"
                                     :word="slotProps.item.keyword" />
            <meta-coverage-indicator :coverage="slotProps.item.meta_entry"
                                     :is-redaction-mode="true"
                                     :is-keywords-mode="true"
                                     :locales-titles="localesData.popular"
                                     :redaction-coverage="slotProps.item.meta_entry_redaction"
                                     :word="slotProps.item.keyword" />

          </template>
          <template v-slot:items-locale="slotProps">
            <select-meta-word-use-in :word="slotProps.item.keyword"
                                     :keywords-mode="true"
                                     :locales-with-titles="selectedLocalesWithTitles"
                                     :locale-to-use-in-title="slotProps.item.to_use_in_title"
                                     :locale-to-use-in-subtitle="slotProps.item.to_use_in_subtitle"
                                     :coverage="currentRedactionId ? slotProps.item.meta_entry_redaction : slotProps.item.meta_entry"
                                     :store-coverage="slotProps.item.meta_entry"
                                     :editable-metas="editableMetas"
                                     @apply="applyUseInTooltip"
                                     @remove-keyword="removeMetaKeyword" />
          </template>
          <template v-slot:items-popularity="slotProps">
            <div class="display-flex">
              <sap-tool :keyword="slotProps.item.keyword"
                        :sap="slotProps.item.popularity"
                        :store-key="currentApp.store.key"
                        :tooltipPos="slotProps.isLast ? 'top' : 'bottom'"
                        :is-show-progress-bar="false"
                        @sap-modal-trigger-clicked="sapChartTriggerHandler(slotProps.item)"/>
            </div>
          </template>
          <template v-slot:items-impression="slotProps">
            {{ shortenDigits(slotProps.item.daily_impression) }}
          </template>
          <template v-slot:items-app_rank="slotProps">
            <div class="chart">
              <rank-chart-tool :key="slotProps.item.keyword"
                               :chart-data="slotProps.item.rank_history"
                               :tooltipPos="slotProps.isLast ? 'top' : 'bottom'"
                               :is-show-full-chart="false"/>
            </div>
            <div class="rank-value"
                 :class="{'underlined': slotProps.item?.latest_rank.rank !== '-'}"
                 @click="handleChartClick(slotProps.item)"
                 v-tooltip="{text: 'Click to see position history', position: 'bottom', classes: ['no-wrap-text']}">
              <app-rank-tool :item="slotProps.item?.latest_rank"/>
            </div>
          </template>
          <template v-slot:items-best_rank="slotProps">
            <span>{{ slotProps.item.best_rank }}</span>
          </template>
          <template v-slot:items-results="slotProps">
            {{ shortenDigits(slotProps.item.results) }}
          </template>
          <template v-slot:items-competitors="slotProps">
            <competitors-in-top-tool :competitors="slotProps.item.competitors_top_10.competitors"
                                     :indirect-competitors="slotProps.item.competitors_top_10.indirect_competitors"
                                     :non-competitots="slotProps.item.competitors_top_10.non_competitors"
                                     :total="10"
                                     @clicked="liveSearchTriggerHandler(slotProps.item, 'db')" />
          </template>
        </SimpleInfoTable>
      </div>
    </template>
    <template v-else>
      <div class="preloader-wrap">
        <preloader-table-small loader-size="50px"></preloader-table-small>
      </div>
    </template>
  </div>
</template>

<script>
import {httpRequest} from "@/api";
import {mapGetters} from "vuex";
import {AppMetaKeywordsObject, GenerateQueryUrl} from "@/utils/factories";
import BasicSearchInput from "@/components/UI/BasicSearchInput/index.vue";
import CustomRangeInput from "@/components/Forms/CustomRangeInput/index.vue";
import TableCheckbox from "@/components/UI/TableCheckbox/index.vue";
import CustomRadioInput from "@/views/SearchTerms/RecommendedKeywords/components/CustomRadioInput/index.vue";
import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";
import TitleDescriptionDonut from "@/components/UI/TitleDescriptionDonut/index.vue";
import SapChartModal from "@/components/Modals/SapChartModal/index.vue";
import CustomChips from "@/components/Forms/CustomChips/index.vue";
import {openGlobalModal, shortenDigits} from "@/utils/utils";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import TranslateTool from "@/components/DataTables/Tools/TranslateTool/index.vue";
import translates from "@/api/modules/translates";
import TranslateOneTool from "../../../../components/DataTables/Tools/TranslateOneTool/index.vue";
import AppRankTool from "@/components/DataTables/Tools/AppRankTool/index.vue";
import MetaCoverageIndicator from "@/views/MetaEditor/components/MetaCoverageIndicator/index.vue";
import CoverageTooltip from "@/views/MetaEditor/components/CoverageTooltip/index.vue";
import SimpleInfoTable from "@/components/SimpleInfoTable/SimpleInfoTable.vue";
import CompetitorsInTopTool from "@/components/DataTables/Tools/CompetitorsInTopTool/index.vue";
import {SimpleDataProvider} from "@/api/modules/tables/simple-data-provider";
import {SortingData} from "@/api/modules/tables/search-filter-data";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import SelectMetaWordUseIn from "@/views/MetaEditor/components/SelectMetaWordUseIn/index.vue";
import RankChartTool from "@/components/DataTables/Tools/RankChartTool/index.vue";
import SapTool from "@/components/DataTables/Tools/SapTool/index.vue";
import SapChartGlobalModalAdapter from "@/components/Modals/SapChartModal/SapChartGlobalModalAdapter.vue";
import KeywordToolNew from "@/components/DataTables/Tools/KeywordToolNew.vue";
import LiveSearchGlobalModalAdapter from "@/components/Modals/LiveSearchModal/LiveSearchGlobalModalAdapter.vue";
import AutosuggestsGlobalModalAdapter from "@/components/Modals/AutosuggestsModal/AutosuggestsGlobalModalAdapter.vue";
import ExpandRankTableChart from "@/components/DataTables/Tools/ExpandRankTableChart/ExpandRankTableChart.vue";
import LanguagesFilter from "@/views/MetaEditor/components/LanguagesFilter/index.vue";

export default {
  name: "Keywords",
  components: {
    LanguagesFilter,
    ExpandRankTableChart,
    KeywordToolNew,
    SapTool,
    RankChartTool,
    SelectMetaWordUseIn,
    PreloaderTableSmall,
    CompetitorsInTopTool,
    SimpleInfoTable,
    CoverageTooltip,
    MetaCoverageIndicator,
    AppRankTool,
    TranslateOneTool,
    TranslateTool,
    SvgIcon,
    'basic-search-input': BasicSearchInput,
    'data-table-checkbox': TableCheckbox,
    'range-input': CustomRangeInput,
    'radio-input': CustomRadioInput,
    'custom-dropdown': CustomDropdown,
    'title-description-chart': TitleDescriptionDonut,
    'sap-chart-modal': SapChartModal,
    'custom-chips': CustomChips,
  },
  emits: ['save-table-words', 'keyword-deleted', 'remove-meta-keyword'],
  props: {
    countryCode: {
      type: String
    },
    currentCountryCode: {
      type: String
    },
    currentCountryName: {
      type: String
    },
    currentCountryId: {
      type: Number
    },
    currentRedactionId: {
      type: String
    },
    currentFilterReset: {
      type: Number,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    searchKeywordValue: {
      type: String,
      default: null,
    },
    editableMetas: {
      type: Object,
    },
    selectedLocalesWithTitles: {
      type: Array,
    },
    localesData: {
      type: Object,
    },
    isUncoveredKeywordsTab: {
      type: Boolean,
    },
    appliedFilters: {
      type: Object,
    },
  },
  data() {
    return {
      keywordsLoaded: false,
      translateIsActive: false,
      initialKeywordsTableData: [],
      keywordsTableData: [],
      keywordsDataProvider: new SimpleDataProvider([], null, ['keyword'], null, new SortingData(
          'total_popularity',
          'desc'
      )),
      searchInputValue: '',
      lastOpenedItem: null,
      initialLanguages: [],
      languages: [],
      selectedLanguages: [],
      uncoveredKeywordsFilters: ['all keywords', 'partly covered', 'uncovered'],
      activeFilters: {
        rangeFilters: {},
        languageFilter: [],
        uncoveredKeywordsFilter: null,
      },
      closeDropdownCounter: 0,
    }
  },
  async mounted() {
    await this.fetchKeywordsTableData();
    this.updateFilters({rangeFilters: this.appliedFilters});
    this.applyFilters();
  },
  methods: {
    shortenDigits,
    sortByRank(position) {
      return this.isUnknownRank(position) ? -8888 : parseInt(position) * -1;
    },
    isUnknownRank(position) {
      return position === '-' || position === 'N/A';
    },
    async translateClickHandler() {
      if (this.translateIsActive) {
        this.keywordsTableData.forEach((item => item.translate = null));
        this.translateIsActive = false;

        return;
      }

      let translatesByKeyword = await translates.translateKeywords(this, this.currentApp?.store?.key, this.keywordsTableData.map(item => item.keyword));
      this.keywordsTableData.forEach((item => item.translate = translatesByKeyword[item.keyword] ?? null));
      this.translateIsActive = true;
    },
    translateAllKeywords() {
      this.translateAll('keywordsTableData', this.allKeywordsTranslated);
    },
    async translateAll(dataKey, clear) {
      if (clear) {
        Object.keys(this[dataKey]).forEach((key) => {
          this[dataKey][key].translate = '';
        });
      } else {
        const translatesByKeyword = await translates.translateKeywords(
            this,
            this.currentApp.store.key,
            Object.values(this[dataKey]).map(item => item.word ?? item.keyword)
        );
        Object.keys(this[dataKey]).forEach((key) => {
          this[dataKey][key].translate = translatesByKeyword[this[dataKey][key].word ?? this[dataKey][key].keyword];
        });
      }
    },
    async fetchKeywordsTableData() {
      this.keywordsLoaded = false;
      let url = `?app_id=${this.currentApp?.id}&country_code=${this.countryCode}`;

      if (this.currentRedactionId && this.currentRedactionId !== 'undefined') {
        url += `&redaction_id=${this.currentRedactionId}`;
      }

      url += '&sort_by=popularity&order=desc';
      let endpoint = this.isUncoveredKeywordsTab
          ? this.$ApiUrls.metaEditor.GET_UNCOVERED_KEYWORDS
          : this.$ApiUrls.metaEditor.GET_KEYWORDS
      const keywordsData = await httpRequest('GET',process.env.VUE_APP_API_URL + endpoint + url);
      this.initialLanguages = keywordsData?.languages;
      this.languages = this.initialLanguages;
      this.initialKeywordsTableData = AppMetaKeywordsObject(keywordsData?.list, this.countryList);
      this.keywordsTableData = this.initialKeywordsTableData;
      this.keywordsLoaded = true;
    },
    applyUseInTooltip(locale, newWordToUseInTitle, newWordToUseInSubtitle, newKeywordToUse) {
      this.keywordsTableData.forEach(function (item) {
        if (item.keyword === newKeywordToUse) {
          item.meta_entry_redaction.keywords[locale].level = 'full';
        }
      });
      const newKeywordsArray = newKeywordToUse.replace(/[^\p{L}0-9\s]/gu, '').split(" ");
      const localeData = this.editableMetas.metas[locale];
      let uniqueKeywords = [];

      if (localeData) {
        const existingKeywords = localeData.keywords.flatMap(keyword => keyword.split(' '));
        uniqueKeywords = newKeywordsArray.filter(word => !existingKeywords.includes(word));
      }

      this.$emit(
          'save-table-words',
          locale,
          [newWordToUseInTitle].filter(item => item !== null),
          [newWordToUseInSubtitle].filter(item => item !== null),
          uniqueKeywords.filter(item => item !== null),
      );
    },
    removeMetaKeyword(locale, keyword) {
      this.keywordsTableData.forEach(function (item) {
        if (item.keyword === keyword) {
          item.meta_entry_redaction.keywords[locale].level = 'zero';
        }
      });
      this.$emit('remove-meta-keyword', locale, keyword);
    },
    sapChartTriggerHandler(e) {
      openGlobalModal(SapChartGlobalModalAdapter, {
        'country': this.currentCountry,
        'keyword': e.keyword
      })
    },
    liveSearchTriggerHandler(e, flow = 'live') {
      openGlobalModal(LiveSearchGlobalModalAdapter, {
        'country': this.currentCountry,
        'flow': flow,
        'keyword': e.keyword
      })
    },
    autoSuggestTriggerHandler(e) {
      openGlobalModal(AutosuggestsGlobalModalAdapter, {
        'country': this.currentCountry,
        'keyword': e.keyword,
        'app': this.currentApp,
        'keywordsChangedCallback': () => {
          this.handleAutoSuggest();
        },
        'saveKeywordsCallback': () => {
          this.handleAutoSuggest();
        }
      })
    },
    handleAutoSuggest() {
      this.$store.dispatch('UPDATE_USER_LIMITS');
      this.$store.dispatch('keywordsTracking/SET_TRACKING_KEYWORDS_COUNT');
      this.fetchKeywordsTableData();
    },
    handleChartClick(item) {
      if (this.lastOpenedItem) {
        this.lastOpenedItem.opened = false;
      }

      if (this.lastOpenedItem === item) {
        this.lastOpenedItem = null;
        return;
      }

      item.opened = !item.opened;
      this.lastOpenedItem = item;
    },
    updateFilters(newFilters) {
      this.activeFilters = {...this.activeFilters, ...newFilters};
      this.applyFilters();
    },
    applyFilters() {
      let filteredKeywords = this.initialKeywordsTableData;

      for (const key in this.activeFilters.rangeFilters) {
        const regex = /filter\[(.*?)]\[(from|to)]/;
        const match = key.match(regex);

        if (match) {
          const rangeKey = match[1];
          const rangeType = match[2];
          let filteringField;

          switch (rangeKey) {
            case 'range_daily_impression':
              filteringField = 'daily_impression';
              break;
            case 'range_sap':
              filteringField = 'popularity';
              break;
            case 'range_rank':
              filteringField = 'latest_rank';
              break;
            case 'range_applications_count':
              filteringField = 'results';
              break;
            default:
              filteringField = null;
          }

          if (filteringField) {
            if (filteringField === 'latest_rank') {
              filteredKeywords =
                  rangeType === 'from'
                      ? filteredKeywords.filter(
                          (keyword) => keyword[filteringField].rank >= this.activeFilters.rangeFilters[key]
                      )
                      : filteredKeywords.filter(
                          (keyword) => keyword[filteringField].rank <= this.activeFilters.rangeFilters[key]
                      );
            } else {
              filteredKeywords =
                  rangeType === 'from'
                      ? filteredKeywords.filter(
                          (keyword) => keyword[filteringField] >= this.activeFilters.rangeFilters[key]
                      )
                      : filteredKeywords.filter(
                          (keyword) => keyword[filteringField] <= this.activeFilters.rangeFilters[key]
                      );
            }
          }
        }
      }

      if (this.activeFilters.languageFilter.length > 0) {
        filteredKeywords = filteredKeywords.filter((item) => this.activeFilters.languageFilter.includes(item.language.code));
      }

      if (this.activeFilters.uncoveredKeywordsFilter) {
        filteredKeywords = filteredKeywords.filter((item) => {
          const levels = this.getRedactionLevels(item.meta_entry_redaction);

          if (this.activeFilters.uncoveredKeywordsFilter === 'partly covered') {
            return levels.has('partial') || levels.has('full');
          } else if (this.activeFilters.uncoveredKeywordsFilter === 'uncovered') {
            return !levels.has('partial') && !levels.has('full');
          }

          return true;
        });
      }

      this.keywordsTableData = filteredKeywords;
    },
    getRedactionLevels(redaction) {
      const levels = new Set();

      for (const section of Object.values(redaction)) {
        for (const locale of Object.values(section)) {
          levels.add(locale.level);

          if (levels.size > 2 || (levels.has('partial') && levels.has('full'))) {
            return levels;
          }
        }
      }

      return levels;
    },
    groupAndCountByLanguage(keywords) {
      const grouped = keywords.reduce((acc, keyword) => {
        const {name, code} = keyword.language;

        if (!acc[code]) {
          acc[code] = {name, code, total: 0};
        }

        acc[code].total += 1;

        return acc;
      }, {});

      return Object.values(grouped);
    }
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'currentCountry',
      'currentCountryManual',
      'countryList'
    ]),
    allKeywordsTranslated() {
      return this.keywordsTableData.every((item) => {
        return item.translate;
      });
    },
  },
  watch: {
    keywordsTableData() {
      this.keywordsDataProvider.setItems(this.keywordsTableData);
      this.languages = this.groupAndCountByLanguage(this.keywordsTableData);
    },
    keywordsLoaded() {
      this.keywordsDataProvider.setLoading(!this.keywordsLoaded);
    },
    async currentApp(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        await this.fetchKeywordsTableData();
      }
    },
    async currentCountryManual(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        await this.fetchKeywordsTableData();
      }
    },
    appliedFilters(newFilterValue, oldFilterValue) {
      if (JSON.stringify(oldFilterValue) !== JSON.stringify(newFilterValue)) {
        this.updateFilters({rangeFilters: newFilterValue});
      }
    },
    selectedLanguages(newValue, oldValue) {
      if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
        this.updateFilters({languageFilter: newValue});
      }
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>