import RefsStore from "./RefsStore";

export const getCookie = (name) => {
    const cookieArr = document.cookie.split(";");
    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");

        if (name === cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }

    return null;
}

export const openGlobalModal = (view, props) =>
{
    RefsStore.getRef('globalModal').open(view, props);
}

export const hasBetaCookie = () => {
    return getCookie('betaTesterCookie');
}

export const colorByIndex = (index) => {
    const rng = () => {
        const x = Math.sin(index++) * 10000;
        return x - Math.floor(x);
    };

    const randomR = Math.floor(30 + rng() * 156);
    const randomG = Math.floor(40 + rng() * 181);
    const randomB = Math.floor(50 + rng() * 201);
    const randomA = 0.4 + rng() * 0.4;

    return `rgb(${randomR},${randomG},${randomB},${randomA.toFixed(2)})`;
}

export const colorForProgressBar = (count) => {
    if (count <= 20) {
        return 'var(--secondary-red)';
    } else if (count >= 21 && count <= 40) {
        return 'var(--secondary-orange)';
    } else if (count >= 41 && count <= 60) {
        return 'var(--secondary-yellow)';
    } else if (count >= 61 && count <= 80) {
        return 'var(--secondary-light-green)';
    } else if (count >= 81) {
        return 'var(--secondary-dark-green)';
    }
}

export function getDatesBetween(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        currentDate.setDate(1);
        dates.push(new Date(currentDate));
        currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return dates;
}

export const setCookie = (name, value, cookieDomain, days = 30) => {
    const assign = name + "=" + value + ";";
    const d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString() + ";";
    const path = "path=/;";
    const domain = "domain=" + process.env.VUE_APP_COOKIE_DOMAIN + ";";
    document.cookie = assign + expires + path + domain;
}

export const deleteCookie = (cName) => {
    document.cookie = `${cName}=; Max-Age=0; path=/; domain=${process.env.VUE_APP_COOKIE_DOMAIN}`;
}

export const agentIsMobile = () => {
    const agentTestResult = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    if (agentTestResult === true && window.innerWidth >= 1024) {
        return false;
    } else {
        return agentTestResult
    }
}

export const agentIsTablet = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getSortedObjectPropsByValue = (obj) => {
    return Object.keys(obj).sort((a, b) => obj[b] - obj[a]);
}

export const getFlagEmoji = (countryCode) => {
    const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char => 127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
}

export const getDefaultMinDate = () => {
    const today = new Date();

    let targetMonth = today.getMonth() - 5;
    let targetYear = today.getFullYear();

    if (targetMonth < 0) {
        targetMonth += 12;
        targetYear -= 1;
    }

    const date = new Date(targetYear, targetMonth, 1);
    date.setHours(0, 0, 0, 0);

    return date;
}

export const arraySum = (arr) => {
    return arr.reduce((a, b) => a + b, 0);
}

export const getYesterdayDate = () => {
    const today = new Date();
    let date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    date.setHours(0, 0, 0, 0);

    return date;
}

export const getNDaysAgoDate = (days) => {
    const today = new Date();
    let date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - days);
    date.setHours(0, 0, 0, 0);

    return date;
}

export const getFlagSpan = (code) => {
    if (code === 'other') {
        return '<span class="flag fflag ff-sm" style="background: none; box-shadow: none"></span>';
    }

    return '<span class="flag fflag ff-sm fflag-' + code + '"></span>';
}

export const shortenDigits = (val) => {
    if (!val) {
        return val;
    }

    let newVal = val;
    if (typeof val !== "number") {
        newVal = Number(val);
    }
    if (newVal >= 1e3 || newVal <= -1e3) {
        const units = ["k", "M", "B", "T"];
        let unit = Math.floor(((newVal).toFixed(0).length - 1) / 3) * 3
        let num = (newVal / ('1e' + unit)).toFixed(1)
        let unitname = units[Math.floor(unit / 3) - 1]
        return Math.abs(num) + unitname
    }

    return Math.abs(newVal);
}

export const formatNumber = (val) => {
    return val.toLocaleString('ru-RU', {maximumFractionDigits: 0});
}

const shortMonth = (date) => {
    const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return shortMonths[date.getMonth()];
};

const fullMonth = (date) => {
    const shortMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return shortMonths[date.getMonth()];
};

export const getScrollPercent = (document) => {
    let h = document.documentElement,
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';

    return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
}

export const percentBetween = (value, from, to) => {
    return ((value - from) / (to - from)) * 100;
}

export const percentDiff = (a, b) => {
    if (!a && !b) {
        return 0;
    } else if (!a) {
        return 100;
    } else if (!b) {
        return -100;
    }

    return Number(((b - a) / a) * 100);
}

export const formatDate = (date, mode = 'month-day-year') => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    if (mode === 'month-day-year') {
        return [shortMonth(d), day + ',', year].join(' ');
    }

    if (mode === 'month-day') {
        return [shortMonth(d), day].join(' ');
    }

    if (mode === 'day-month-year') {
        return [day, shortMonth(d), year].join(' ');
    }

    if (mode === 'digits') {
        return [day + '.', month + '.', year].join('');
    }

    if (mode === 'digits-dash') {
        return [day + '-', month + '-', year].join('');
    }
    if (mode === 'digits-dash-reverse') {
        return [year + '-', month + '-', day].join('');
    }

    if (mode === 'full-months') {
        return [fullMonth(d) + ' ' + day + ', ', year].join('');
    }

    if (mode === 'month') {
        return [shortMonth(d)].join('');
    }

    if (mode === 'year-month-day') {
        return [year + '-' + month + '-' + '01'].join('');
    }

    if (mode === 'month-year') {
        return [shortMonth(d) + ' ' + year].join('');
    }

    if (mode === 'month-short') {
        return shortMonth(d);
    }

    if (mode === 'Month, year') {
        return [fullMonth(d) + ', ' + year].join('');
    }

    if (mode === 'SMonth  day') {
        return [shortMonth(d) + ' ' + day].join('');
    }

    return [day, shortMonth(d), year].join(' ');
};

export const isEmptyObject = (obj) => {
    return !!obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

export function debounce(fn, delay) {
    let timeoutID = null;
    return function () {
        clearTimeout(timeoutID);
        let args = arguments;
        let that = this;
        timeoutID = setTimeout(function () {
            fn.apply(that, args);
        }, delay);
    }
}

export function shortUnits(val, precision = 2) {
    let value = Number(val);
    if (value >= 1e3 || value <= -1e3) {
        const units = ["k", "M", "B", "T"];
        let unit = Math.floor(((value).toFixed(0).length - 1) / 3) * 3;
        let num = (value / ('1e' + unit)).toFixed(precision);
        let unitName = units[Math.floor(unit / 3) - 1];
        let symbol = value < 0 ? '-' : '';
        return symbol + Math.abs(num) + unitName;
    }

    return Math.abs(value);
}

export function convertDays(days, type = 'default') {
    let years = Math.floor(days / 365);
    let months = Math.floor((days - (years * 365)) / 30);
    let newdays = Math.floor(days - (years * 365) - (months * 30));
    let result;

    if (type === 'default') {
        if (years === 0) {
            if (months > 0) {
                result = months + 'm ' + newdays + 'd';
            } else {
                result = newdays + 'd';
            }
        } else {
            result = years + 'y ' + months + 'm';
        }

        if (years < 1 && newdays !== 0 && years > 0) {
            result += newdays + 'd';
        }
    }

    if (type === 'years') {
        result = years + 'y';
    }

    return result;
}

export function cropImage(imageUrl, size = '32x32', storeKey = 'APP_STORE') {
    if (!imageUrl) {
        return;
    }

    if (storeKey === 'APP_STORE') {
        let splittedScreenUrl = imageUrl?.split('/');
        let poppedItem = splittedScreenUrl.pop();
        let croppedScreen = splittedScreenUrl.join('/');
        let imageFormat = poppedItem.split('.')[1];
        let formatImageString = `${size}bb.${imageFormat}`;

        croppedScreen += '/' + formatImageString;
        return croppedScreen;
    } else {
        const splittedSize = size.split('x');
        if (splittedSize?.length < 2) {
            return imageUrl;
        }
        const width = splittedSize[0];
        const height = splittedSize[1];
        let splittedScreenUrl = imageUrl?.split('=');
        return `${splittedScreenUrl[0]}=w${width}-h${height}`;
    }
}

export function cropImageToHeight(imageUrl, newHeight, storeKey = 'APP_STORE') {
    if (storeKey === 'APP_STORE') {
        const regex = /(?<width>\d+)x(?<height>\d+)bb\./;
        const match = imageUrl.match(regex);
        if (match) {
            const {width, height} = match.groups;
            const ratio = newHeight / height;
            const newWidth = Math.round(width * ratio);
            return imageUrl.replace(regex, `${newWidth}x${newHeight}bb.`);
        } else {
            return imageUrl;
        }
    } else {
        return imageUrl;
    }
}

export function sanitizeString(string) {
    return string.replace('&amp;', '&');
    // return ampSanitize.replace(/[^\w ]/g, '');
}

export function splitTextToParts(inputString) {
    let notLetterRegex = `[^\\p{L}\\p{N}]`;
    notLetterRegex = new RegExp(notLetterRegex, 'u');

    inputString = inputString.split('');

    let splitInput = [];
    let wordBuffer = '';
    inputString.forEach((letter) => {
        if (letter.match(notLetterRegex)) {
            if (wordBuffer.length) {
                splitInput.push(wordBuffer);
            }
            splitInput.push(letter);
            wordBuffer = '';
        } else {
            wordBuffer += letter;
        }
    });
    if (wordBuffer.length) {
        splitInput.push(wordBuffer);
    }

    return splitInput;
}

export function decomposeUrlToObject(url, paramsArray) {
    const searchParams = new URLSearchParams(url);
    let resultsObj = {};

    if (paramsArray?.length > 0) {
        for (const key in paramsArray) {
            resultsObj[paramsArray[key]] = searchParams.get(paramsArray[key]);
        }
    }

    return resultsObj;
}

export function reverseDayMonthDate(dateStr) {
    let splittedArray = dateStr.split('-');
    return `${splittedArray[1]}-${splittedArray[0]}-${splittedArray[2]}`;
}

export function replaceUrl(query) {
    if (window.history.replaceState) {
        let queryString = query;
        queryString = queryString.charAt(0).replace('&', '?') + queryString.slice(1);
        window.history.replaceState({}, null, queryString);
    }
}

export function parseQueryStringToObject(queryString) {
    const params = new URLSearchParams(queryString);
    const parsedObject = {};

    params.forEach((value, key) => {
        parsedObject[key] = value;
    });

    return parsedObject;
}

export function copyToClipboard(text) {
    const tempText = document.createElement("textarea");
    tempText.value = text;
    document.body.appendChild(tempText);
    tempText.select();

    document.execCommand("copy");
    document.body.removeChild(tempText);
}
