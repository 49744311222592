<template>
  <custom-dropdown classes="meta-editor-select-language"
                   :close-dropdown="closeFiltersTrigger"
                   dropdown-width="315px">
    <template v-slot:header>
      <span v-if="isAllSelected">
        All languages
      </span>
      <span v-else-if="selectedLanguages.length === 1">
        {{ selectedLanguages[0].name }}
      </span>
      <span v-else>
        {{ selectedLanguages.length }} languages
      </span>
      <span>
        ({{ selectedLanguagesKeywordsCount }} kw)
      </span>
    </template>
    <template v-slot:content>
      <div class="selected-value">
        <span v-if="isAllSelected">
          <span>All: </span>
          <span>{{ localLanguages.length }} languages</span>
        </span>
        <span v-else-if="selectedLanguages.length === 1">
          {{ selectedLanguages[0].name }}
        </span>
        <span v-else>
          {{ selectedLanguages.length }} languages
        </span>
      </div>
      <div class="search-input-block">
        <basic-search-input placeholder="Search language"
                            :clear-input="searchInput"
                            @search-input-changed="searchInput = $event"
                            :hide-results-block="true">
          <template v-slot:search-icon>
            <svg-icon v-if="searchInput === ''"
                      class="search-icon"
                      icon="search-solid"/>
            <svg-icon v-else
                      @click="clearSearch"
                      class="close-icon"
                      icon="close"/>
          </template>
        </basic-search-input>
      </div>
      <div class="sort-block">
        <div class="label">Sort by:</div>
        <div>
          <div class="mode-switcher">
            <div :class="{active: sortBy === 'popular'}" @click="sortBy = 'popular'">
              Popular First
            </div>
            <div :class="{active: sortBy === 'alphabet'}" @click="sortBy = 'alphabet'">
              By Alphabet
            </div>
          </div>
        </div>
      </div>
      <div class="options">
        <label v-for="language in preparedLanguages" :for="'language_' + language.code">
          <input type="checkbox"
                 v-model="language.active"
                 class="common-checkbox"
                 :id="'language_' + language.code">
          <span>
            {{ language.name }} ({{ language.total }})
          </span>
        </label>
      </div>
      <base-button height="36px"
                   width="100%"
                   class="btn-standard"
                   border-radius="4px"
                   font-size="16px"
                   @click="apply">
        Apply
      </base-button>
    </template>
  </custom-dropdown>
</template>
<script>
import BaseButton from "@/components/UI/BaseButton/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import BasicSearchInput from "@/components/UI/BasicSearchInput/index.vue";
import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";
import RadioButton from "@/components/UI/RadioButton/index.vue";

export default {
  name: 'LanguagesFilter',
  emits: ['apply'],
  components: {
    RadioButton, CustomDropdown,
    BasicSearchInput,
    SvgIcon,
    BaseButton,
  },
  props: {
    languages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localLanguages: this.languages,
      searchInput: '',
      sortBy: 'popular',
      closeFiltersTrigger: 0,
    };
  },
  methods: {
    apply() {
      this.closeFiltersTrigger++;
      this.$emit('apply', this.selectedLanguages.map(language => language.code));
    },
    clearSearch(event) {
      event.stopPropagation();

      this.searchInput = '';
    },
  },
  computed: {
    isAllSelected() {
      return this.selectedLanguages.length === 0 || this.localLanguages.every(language => language.active);
    },
    selectedLanguages() {
      return this.localLanguages.filter(language => language.active);
    },
    selectedLanguagesKeywordsCount() {
      const languages = this.selectedLanguages.length > 0 ? this.selectedLanguages : this.localLanguages;
      return languages.reduce((acc, language) => acc + language.total, 0);
    },
    preparedLanguages() {
      const searchInput = this.searchInput.toLowerCase();
      const languages = this.localLanguages.filter(language => language.name.toLowerCase().includes(searchInput));
      if (this.sortBy === 'popular') {
        return languages.sort((a, b) => b.total - a.total);
      } else {
        return languages.sort((a, b) => a.name.localeCompare(b.name));
      }
    },
  },
  watch: {
    languages(newValue, oldValue) {
      if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
        this.localLanguages = newValue;
      }
    }
  }
}
</script>
<style lang="scss">
.custom-dropdown.meta-editor-select-language {
  width: 260px;
  font-size: 14px;
  color: var(--neutral-800);

  .select-styled {
    box-shadow: 0 2px 6px 0 #A0BEDD66;
    border-radius: 4px;
    white-space: nowrap;
  }

  .search-input-block {
    .search-input {
      border: 1px solid #E1E8F2;
      box-shadow: none;
      border-radius: 4px;
    }

    .search-icon {
      color: var(--neutral-700);
    }
  }

  .select-options-wrap {
    padding: 12px;
    border: none;
    box-shadow: 0 4px 8px 0 #A0BEDD4D;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .sort-block {
      display: flex;
      align-items: center;
      gap: 8px;
      white-space: nowrap;

      .label {
        font-weight: 600;
      }
    }

    .options {
      display: flex;
      flex-direction: column;
      max-height: 265px;
      overflow-y: scroll;

      label {
        cursor: pointer;
        padding: 9px 8px;

        &:hover {
          background: var(--neutral-400);
        }

        input, span {
          vertical-align: middle;
        }

        input {
          margin-right: 8px;
        }
      }
    }
  }
}
</style>