import Store from "@/api/objects/Store";

const LOCALES_IDS = {
    'GOOGLE_PLAY': 1,
    'APP_STORE': 2,
};

export const StoresListObjCreator = (obj) => {
    return obj;
}

export const CategoriesListObjCreator = (obj) => {
    let googlePlayArr = [];
    let appStoreArr = [];

    if (obj?.GOOGLE_PLAY) {
        googlePlayArr = obj?.GOOGLE_PLAY?.map(item => {
            return {
                ...item,
                store_id: 1
            }
        });
    }

    if (obj?.APP_STORE) {
        appStoreArr = obj?.APP_STORE?.map(item => {
            return {
                ...item,
                store_id: 2
            }
        });
    }

    return [...appStoreArr, ...googlePlayArr];
}

export const LocalesListArrayCreator = (arr) => {
    let processedArray = [];

    for (const key in arr) {
        if (arr[key]?.stores?.length > 0) {
            arr[key]?.stores?.forEach(store => {
                processedArray.push({
                    ...arr[key],
                    store_key: store,
                    store_id: LOCALES_IDS[store] ?? null,
                });
            });
        } else {
            processedArray.push({
                ...arr[key],
            });
        }
    }

    return processedArray;
}

export const CountriesListArrayCreator = (arr) => {
    let processedArray = [];

    const setMainLocale = (obj) => {
        let mainLocale = '';
        if (obj?.main_locale_code) {
            mainLocale = obj?.main_locale_code;
        } else if (obj?.locales?.length > 0) {
            mainLocale = obj?.locales[0];
        } else {
            mainLocale = 'en';
        }

        return mainLocale;
    };

    for (const key in arr) {
        if (arr[key]?.name === 'Worldwide') {
            continue;
        }
        if (arr[key]?.stores?.length > 0) {
            arr[key]?.stores?.forEach(store => {
                processedArray.push({
                    ...arr[key],
                    locales: {
                        main: setMainLocale(arr[key]),
                        list: [...arr[key]?.locales],
                    },
                    store_key: store,
                    store_id: LOCALES_IDS[store] ?? null,
                });
            });
        } else {
            processedArray.push({
                ...arr[key],
                locales: {
                    main: setMainLocale(arr[key]),
                    list: [...arr[key]?.locales],
                },
            });
        }
    }

    return processedArray;
}

export const UserAppsArrayCreator = (dataArray) => {
    return dataArray?.map(item => {
        const appInfo = {...item?.app_info};
        appInfo.updated_at = item?.app_info?.last_update;
        appInfo.aso = {total: item?.app_info?.aso_score};
        appInfo.store = {key: item?.app_info?.store};
        // appInfo.price = item?.app_info?.price;
        appInfo.rating = item?.app_info?.rating;
        const userAppInfo = {...item?.user_app_info};
        return {...appInfo, ...userAppInfo};
    });
}

export const UserCurrentAppObjectCreator = (userObj) => {
    const appInfo = {...userObj?.app_info};
    const userAppInfo = {...userObj?.user_app_info};
    const userDataObject = {
        ...appInfo,
        ...userAppInfo,
    };

    userDataObject.updated_at = userObj?.app_info?.last_update;
    userDataObject.aso = {total: userObj?.app_info?.aso_score};
    userDataObject.store = {key: userObj?.app_info?.store};
    // userDataObject.price = userObj?.app_info?.price;
    userDataObject.rating = userObj?.app_info?.rating;

    return userDataObject;
}

export const AppMetaDataObjectCreator = (dataObj) => {
    let metasDetailed = {};

    for (const key in dataObj?.metas) {
        metasDetailed[key] = {
            locale_code: key,
            locale_name: dataObj?.metas[key]?.locale_name,
            title: {...dataObj?.metas[key]?.title},
            sub_title: {...dataObj?.metas[key]?.sub_title},
            keywords: [...dataObj?.metas[key]?.keywords],
            words_to_use_in_title: [...dataObj?.metas[key]?.words_to_use_in_title],
            words_to_use_in_subtitle: [...dataObj?.metas[key]?.words_to_use_in_subtitle],
            duplicated: [...dataObj?.metas[key]?.duplicated],
        }
    }

    return {
        metas: {...metasDetailed},
        used_words: [...dataObj?.used_words],
        duplicated_words: [...dataObj?.duplicated_words],
    };
}

function replaceLocaleTitle(localeStr, localesArray) {
    return localesArray?.find(country => country?.code?.toLowerCase() === localeStr.toLowerCase());
}

export const AppMetaWordsObject = (dataArray, countryList) => {
    return dataArray?.map(item => {
        let usedIn = [...item?.used_in];
        if (item?.used_in?.length > 0) {
            const matchedCountry = countryList?.find(country => country?.code?.toLowerCase() === usedIn[0].toLowerCase());
            usedIn = matchedCountry?.name;
        }

        const checked = {
            title: item?.used_in?.includes('title'),
            sub_title: item?.used_in?.includes('sub_title'),
            keywords: item?.used_in?.includes('keywords'),
        };

        return {
            ...item,
            used_in: usedIn,
            checked,
        }
    });
}

export const AppMetaKeywordsObject = (dataArray, countryList) => {
    return dataArray.map(keyword => {
        let uncoveredObject = [];
        for (const key in keyword?.meta_entry_coverage) {
            uncoveredObject.push({
                ...keyword?.meta_entry_coverage[key],
                locale: replaceLocaleTitle(key, countryList),
            });
        }

        const checked = {
            title: keyword?.used_in?.includes('title'),
            sub_title: keyword?.used_in?.includes('sub_title'),
            keywords: keyword?.used_in?.includes('keywords'),
        };

        return {
            country_id: keyword?.country_id ?? null,
            difficulty: keyword?.difficulty ?? null,
            daily_impression: keyword?.daily_impression ?? null,
            keyword: keyword?.keyword ?? "",
            popularity: keyword?.popularity ?? null,
            results: keyword?.results ?? null,
            id: keyword?.tracking_id ?? null,
            rank_history: keyword?.app_rank_history,
            meta_entry_coverage: uncoveredObject,
            checked,
            meta_entry: keyword?.meta_entry,
            meta_entry_redaction: keyword?.meta_entry_redaction,
            latest_rank: {...keyword?.last_rank_info},
            competitors_top_10: {...keyword?.competitors_top_10},
            best_rank: keyword?.best_rank ?? null,
            language: keyword?.language,
        }
    });
}

export const AppsWithAdditionalDataObj = (array, appsList) => {
    let appsListArray = appsList;
    let groupedApps = array;

    if (typeof array === "object") {
        groupedApps = Object?.values(array);
    }

    if (typeof appsList === "object") {
        appsListArray = Object?.values(appsList);
    }

    return groupedApps.map(item => {
        let extendedData = appsListArray?.find(appItem => appItem?.id === (item?.id || item?.app_id));
        return {
            id: item?.app_id,
            keywords: item?.keywords !== 0 ? '-' + item?.keywords?.toLocaleString('ru-RU') : item?.keywords?.toLocaleString('ru-RU'),
            competitors: item?.competitors !== 0 ? '-' + item?.competitors?.toLocaleString('ru-RU') : item?.competitors?.toLocaleString('ru-RU'),
            logo: extendedData?.logo,
            title: extendedData?.title,
        }
    });
}

export const AppMetaCompetitorsArray = (dataArray) => {
    return dataArray?.map(competitor => {
        return {
            id: competitor?.id,
            title: competitor?.title,
            sub_title: competitor?.sub_title,
            logo: competitor?.logo,
            installs: competitor?.installs?.toLocaleString('ru-RU', {maximumFractionDigits: 0}),
            revenue: competitor?.revenue?.toLocaleString('ru-RU', {maximumFractionDigits: 0}),
        }
    });
}

export const MetadataKeywordsObject = dataArray => {
    if (!dataArray) {
        return [];
    }
    return dataArray.map(keyword => {
        return {
            country_id: keyword?.country_id ?? null,
            applications_count: keyword?.applications_count ?? null,
            keyword: keyword?.keyword ? {...keyword.keyword} : '',
            id: keyword.keyword?.tracked_id ?? null,
            difficulty: keyword?.difficulty ?? null,
            daily_impression: keyword?.daily_impression ? Number(keyword?.daily_impression) : null,
            sap: keyword?.sap ?? null,
            sources: keyword.sources ?? '',
            top_competitors: keyword?.top_competitors ?? null,
            last_rank_info: keyword?.last_rank_info ?? null,
        }
    });
}

export const RelatedKeywordsObject = dataArray => {
    if (!dataArray) {
        return [];
    }
    return dataArray.map(keyword => {
        return {
            country_id: keyword?.country_id ?? null,
            applications_count: keyword?.applications_count ?? null,
            keyword: keyword?.keyword ? {...keyword.keyword} : '',
            id: keyword.keyword?.tracked_id ?? null,
            difficulty: keyword?.difficulty ?? null,
            daily_impression: keyword?.daily_impression ? Number(keyword?.daily_impression) : null,
            sap: keyword?.sap ?? null,
            sources: keyword.sources ?? '',
            top_competitors: keyword?.top_competitors ?? null,
            last_rank_info: keyword?.last_rank_info ?? null,
        }
    });
}

export const RecommendedKeywordsObject = dataArray => {
    if (dataArray?.length === 0) {
        return [];
    }

    return dataArray?.map(keyword => {
        return {
            country_id: keyword?.country_id ?? null,
            applications_count: keyword?.applications_count ?? null,
            impression: keyword?.daily_impression ? Number(keyword?.daily_impression) : null,
            keyword: keyword?.keyword ? {...keyword.keyword} : '',
            difficulty: keyword?.difficulty ?? null,
            id: keyword.keyword?.tracked_id ?? null,
            sap: keyword?.sap ?? null,
            sources: keyword.sources ?? '',
            top_competitors: keyword?.top_competitors ?? null,
            last_rank_info: keyword?.last_rank_info ?? null,
        }
    });
}

export const RankingKeywordsObject = dataArray => {
    if (dataArray?.length === 0) {
        return [];
    }
    return dataArray.map(keyword => {
        return {
            applications_count: keyword?.applications_count ?? null,
            difficulty: keyword?.difficulty ?? null,
            country_id: keyword?.country_id ?? null,
            daily_impression: keyword?.daily_impression ? Number(keyword?.daily_impression) : null,
            keyword: keyword?.keyword ? {...keyword.keyword} : '',
            id: keyword.keyword?.tracked_id ?? null,
            sap: keyword?.sap ?? null,
            top_competitors: keyword?.top_competitors ?? null,
            first_rank_info: keyword?.first_rank_info ?? null,
            last_rank_info: keyword?.last_rank_info ?? null,
        }
    });
}

export const GenerateQueryUrl = filterObject => {
    let queryString = '';

    if (Object.keys(filterObject).length > 0) {
        for (const key in filterObject) {
            if (filterObject[key] !== null && filterObject[key] !== undefined) {
                queryString += '&' + key + '=' + filterObject[key];
            }
        }
    }
    return queryString;
}

export const LifeSearchResultsObject = (payload) => {
    let myAppsIds = [];
    if (payload?.myApps) {
        for (const key in payload.myApps) {
            const item = payload.myApps[key];
            myAppsIds.push(item?.id);
        }
    }

    const TYPES = {
        'apps': 'apps',
        'editorial-items': 'STORY',
        'app-bundles': 'BUNDLE',
        'developers': 'DEVELOPER PAGE',
        'in-apps': 'In-Apps Purchase',
        'preorder': 'PRE-ORDER',
        'subscription': 'SUBSCRIPTION',
    };

    let i = 0;

    return {
        hasNextPage: payload.hasNexPage,
        hasScreenshots: payload.hasScreenshots,
        querySugg: payload.query_sugg ?? payload.querySugg ?? null,
        results: payload?.results?.map(resultItem => {
            const rating_num = resultItem?.data?.rating_num ? parseFloat(resultItem?.data?.rating_num).toFixed(1) : null;

            let logo = null;
            if (resultItem?.data?.logo) {
                logo = resultItem?.data?.logo;
            } else if (resultItem?.data?.icon) {
                logo = resultItem?.data?.icon;
            }

            let screenshots = [];
            let croppedScreenshots = [];

            if (resultItem?.data?.screenshots) {
                screenshots = resultItem?.data?.screenshots.slice(0, 4);

                croppedScreenshots = screenshots.map(screenshot => {
                    const screen = screenshot?.src || screenshot;
                    let imageDirectionCss = '';
                    let formatImageString;

                    if (resultItem?.data?.store_key === 'APP_STORE') {
                        let splittedScreenUrl = screen?.split('/');
                        let poppedItem = splittedScreenUrl.pop();
                        let croppedScreen = splittedScreenUrl.join('/');
                        let imageFormat = poppedItem.split('.')[1];
                        let imageSizes = poppedItem.split('.')[0];
                        let imageSizesSplitted = imageSizes.split('x');
                        let imageWidth = parseInt(imageSizesSplitted[0]);
                        let imageHeight = parseInt(imageSizesSplitted[1]);

                        if (imageWidth > imageHeight) {
                            imageDirectionCss = 'horizontal';
                            formatImageString = '535x224bb.' + imageFormat;
                        } else {
                            imageDirectionCss = 'vertical';
                            formatImageString = '124x224bb.' + imageFormat;
                        }

                        croppedScreen += '/' + formatImageString;

                        return {
                            src: croppedScreen,
                            type: imageDirectionCss
                        }
                    } else {
                        const splittedScreenUrl = screen?.split('=');
                        const splittedSize = splittedScreenUrl[1] ? splittedScreenUrl[1]?.split('-') : null;

                        if (splittedSize === null || splittedSize?.length < 2) {
                            return screen;
                        }

                        const width = splittedSize[0];
                        const height = splittedSize[1];

                        if (width > height) {
                            imageDirectionCss = 'horizontal';
                            formatImageString = 'w535-h224';
                        } else {
                            imageDirectionCss = 'vertical';
                            formatImageString = 'w124-h224';
                        }

                        return {
                            src: `${splittedScreenUrl[0]}=${formatImageString}`,
                            type: imageDirectionCss
                        }

                    }
                });
            }

            return {
                type: resultItem?.type ?? null,
                typeFormatted: resultItem?.type ? TYPES[resultItem?.type] : null,
                is_paid: resultItem?.is_paid ?? false,
                positionIndex: resultItem?.is_paid ? null : ++i,
                competitorType: resultItem?.competitorType ?? null,
                data: {
                    id: resultItem?.data?.id ?? null,
                    store_key: resultItem?.data?.store_key ?? null,
                    logo,
                    link: resultItem?.data?.link ?? null,
                    icon: resultItem?.data?.icon ?? null,
                    title: resultItem?.data?.title ?? null,
                    developer_name: resultItem?.data?.developer_name ?? null,
                    categories: resultItem?.data?.categories ? [...resultItem?.data?.categories] : [],
                    rating_num,
                    votes_num: resultItem?.data?.votes_num,
                    price: resultItem?.data?.price ?? null,
                    genre: resultItem?.data?.genre ?? null,
                    screenshots: croppedScreenshots,
                    apps: resultItem?.data?.apps ?? [],
                    isAdded: myAppsIds.includes(resultItem?.data?.id),
                    competitorType: resultItem?.competitorType ?? null,
                    isLoading: resultItem?.data?.isLoading ?? false,
                }
            }
        })
    };
}

export const LifeSearchCompetitorsResultsObject = (liveSearchResults, competitorsResults) => {
    const notEmptyConcatedArray = liveSearchResults?.results?.map(item => {
        if (item !== null) {
            const competitor = competitorsResults?.resultIsCompetitors?.includes(+item?.data?.id);
            const nonCompetitor = competitorsResults?.resultNotCompetitors?.includes(+item?.data?.id);
            let tempItem = {...item};

            if (competitor || nonCompetitor) {
                tempItem.competitorType = competitor ? 'competitor' : (nonCompetitor ? 'nonCompetitor' : null);
            } else {
                tempItem.competitorType = null;
            }

            return tempItem;
        }
    });

    return {
        hasNextPage: liveSearchResults?.hasNextPage,
        hasScreenshots: liveSearchResults?.hasScreenshots,
        querySugg: liveSearchResults?.querySugg,
        results: notEmptyConcatedArray?.filter(item => (item !== null && item !== undefined))
    };
}

export const SplitArrays = (keywordArray, chunkSize) => {
    let result = [];

    for (let i = 0; i < keywordArray.length; i += chunkSize) {
        let chunk = keywordArray.slice(i, i + chunkSize);
        result.push(chunk);
    }

    return result;
}

export const storeByKey = (key) => {
    switch (key) {
        case 'GOOGLE_PLAY':
            return googlePlay();
        case 'APP_STORE':
            return appStore();
        default:
            return null;
    }
}

export const googlePlay = () => {
    return new Store(1, 'GOOGLE_PLAY');
}

export const appStore = () => {
    return new Store(2, 'APP_STORE');
}