<template>
  <custom-tooltip direction-horizontal="left"
                  direction-vertical="bottom"
                  :straight="true"
                  :width="isKeywordsMode ? '500px' : '400px'"
                  max-height="505px"
                  :with-padding="true"
                  :delayed-closing="true"
                  :style="{padding: 0}"
                  classes="tool-item hover-bg-green">
    <template v-slot:slotTrigger>
      <div class="meta-coverage-indicator-chart">
        <div class="title" :class="titleIndicatorClass"></div>
        <div class="subtitle" :class="subtitleIndicatorClass"></div>
      </div>
    </template>

    <template v-slot:slotContent>
      <div class="meta-coverage-tooltip">
        <div class="meta-coverage-tooltip-top">
          <div class="title">
            <template v-if="isKeywordsMode">
              Keyword Coverage Analysis
            </template>
            <template v-else>
              Word Coverage Analysis
            </template>
          </div>
          <div class="definitions">
            <template v-if="isKeywordsMode">
              <div><span class="coverage-in-title">in title</span></div>
              <div><span class="coverage-in-subtitle">in subtitle</span></div>
              <div><span class="coverage-in-keywords">in keywords</span></div>
              <div><span class="dark-red">uncovered</span></div>
            </template>
            <template v-else>
              <div><span class="dark-green">T</span> - Title</div>
              <div><span class="dark-green">S</span> - Subtitle</div>
              <div><span class="dark-green">K</span> - Keyword</div>
              <div><span class="dark-red">uncovered</span></div>
            </template>
          </div>
        </div>

        <table>
          <tr v-for="locale in locales">
            <td>
              <div class="locale-block">
                <div class="meta-coverage-indicator-chart">
                  <div class="title"
                       :class="coverageForTableIndicators.title[locale].level">
                  </div>
                  <div class="subtitle"
                       :class="[coverageForTableIndicators.sub_title[locale].level, coverage.keywords[locale].level]">
                  </div>
                </div>
                <div>
                  {{ localesTitles[locale] }}
                </div>
              </div>
            </td>
            <td v-if="!isKeywordsMode">{{ word }}</td>
            <td v-if="coverage.title[locale]">
              <div class="coverage">
                <img src="@/assets/images/icons/app_store_gray.svg"
                     width="16"
                     height="16">
                <span v-if="isKeywordsMode" v-html="highlightWord(locale, coverage)"></span>
                <template v-else>
                  <div :class="coverage.title[locale].level === 'zero' ? 'dark-red' : 'dark-green'">
                    T
                  </div>
                  <div v-if="coverage.sub_title[locale]"
                       :class="coverage.sub_title[locale].level === 'zero' ? 'dark-red' : 'dark-green'">
                    S
                  </div>
                  <div v-if="coverage.keywords[locale]"
                       :class="coverage.keywords[locale].level === 'zero' ? 'dark-red' : 'dark-green'">
                    K
                  </div>
                </template>
              </div>
            </td>
            <td v-if="redactionCoverage">
              <div class="coverage">
                <svg-icon icon="document" />
                <span v-if="isKeywordsMode" v-html="highlightWord(locale, redactionCoverage)"></span>
                <template v-else>
                  <div :class="redactionCoverage.title[locale].level === 'zero' ? 'dark-red' : 'dark-green'">T</div>
                  <div :class="redactionCoverage.sub_title[locale].level === 'zero' ? 'dark-red' : 'dark-green'">S</div>
                  <div :class="redactionCoverage.keywords[locale].level === 'zero' ? 'dark-red' : 'dark-green'">K</div>
                </template>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </template>
  </custom-tooltip>
</template>
<script>
import SapTool from "@/components/DataTables/Tools/SapTool/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import Tooltip from "@/components/UI/Tooltip/index.vue";

export default {
  name: 'MetaCoverageIndicator',
  components: {
    SvgIcon,
    SapTool,
    'custom-tooltip': Tooltip,
  },
  props: {
    coverage: {
      type: Object,
      required: true,
    },
    redactionCoverage: {
      type: Object,
    },
    word: {
      type: String,
      required: true,
    },
    localesTitles: {
      type: Object,
      required: true,
    },
    isKeywordsMode: {
      type: Boolean,
      default: false,
    },
    isRedactionMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isFull(type) {
      return Object.entries(this.computedCoverage[type]).some(([locale, item]) => {
        return Object.keys(this.localesTitles).includes(locale)
            ? item.level === 'full'
            : false;
      });
    },
    isPartial(type) {
      return Object.entries(this.computedCoverage[type]).some(([locale, item]) => {
        return Object.keys(this.localesTitles).includes(locale)
            ? item.level === 'partial'
            : false;
      });
    },
    highlightWord(locale, redaction) {
      let word = this.word;
      const intersectTitle = redaction.title[locale].intersect;
      const intersectSubtitle = redaction.sub_title[locale]?.intersect.filter(item => !intersectTitle.includes(item));
      const intersectKeywords = redaction.keywords[locale]?.intersect.filter(item => !intersectTitle.includes(item) && !intersectSubtitle.includes(item));

      intersectTitle?.forEach((item) => {
        word = word.replace(new RegExp(item, "gi"), (match) => `<span class="coverage-in-title">${match}</span>`);
      });
      intersectSubtitle?.forEach((item) => {
        word = word.replace(new RegExp(item, "gi"), (match) => `<span class="coverage-in-subtitle">${match}</span>`);
      });
      intersectKeywords?.forEach((item) => {
        word = word.replace(new RegExp(item, "gi"), (match) => `<span class="coverage-in-keywords">${match}</span>`);
      });

      return word;
    },
  },
  computed: {
    titleIndicatorClass() {
      return {
        full: this.isFull('title'),
        partial: this.isPartial('title'),
      }
    },
    subtitleIndicatorClass() {
      return {
        full: this.isFull('sub_title') || this.isFull('keywords'),
        partial: this.isPartial('sub_title') || this.isPartial('keywords'),
      }
    },
    locales() {
      return Object.keys(this.coverage.title);
    },
    coverageForTableIndicators() {
      return this.redactionCoverage ?? this.coverage;
    },
    computedCoverage() {
      return this.isRedactionMode ? this.redactionCoverage : this.coverage;
    },
  },
}
</script>
<style src="./styles.scss" lang="scss"></style>
