<script>
import Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Country from "../../api/objects/Country";
import DynamicImage from "../../components/UI/DynamicImage/index.vue";
import {formatDate, getDatesBetween} from "@/utils/utils";
import {createApp, defineComponent} from "vue";
import RatingTool from "../../components/DataTables/Tools/RatingTool/index.vue";
import ProgressBar from "../../components/UI/ProgressBar/index.vue";
import Dynamic from "../../components/UI/Dynamic/Dynamic.vue";
import SimpleInfoTable from "../../components/SimpleInfoTable/SimpleInfoTable.vue";
import {colorByIndex, cropImage, getFlagSpan, shortUnits} from "../../utils/utils";
import SvgIcon from "../../components/UI/SvgIcon/index.vue";
import TopMap from "./TopMap.vue";
import Tooltip from "@/components/UI/Tooltip/index.vue";
import MarketResearchLegendItem from "./MarketResearchLegendItem.vue";

export default {
  name: "MarketResearchCountries",
  components: {
    MarketResearchLegendItem,
    TopMap, SvgIcon, SimpleInfoTable, Dynamic, ProgressBar, RatingTool, DynamicImage, 'custom-tooltip': Tooltip,
  },
  props: {
    reportApp: {
      type: Object,
      required: true,
    },
    revenueIsAllowed: {
      type: Boolean,
      default: true,
    },
    appCountriesMode: {
      type: String,
      default: 'countries',
    },
    date_from: {
      type: Date,
      required: true,
    },
    date_to: {
      type: Date,
      required: true,
    },
    countriesInstallsRevenueShare: {
      type: Array,
      required: true,
    },
    appsInstallsRevenueShare: {
      type: Array,
      required: true,
    },
    countriesHistory: {
      type: Array,
      required: true,
    },
    appsHistory: {
      type: Array,
      required: true,
    },

    trClassCallback: {type: Function},
    formatItemAppTitle: {type: Function},
  },
  data() {
    return {
      grayColor: '#e1e8f2',

      minPercent: 2,

      mode: 'installs',

      fullTable: false,

      dataLoaded: false,
      chart: null,

      topByInstalls: [],
      topByRevenue: [],

      topAppsByInstalls: [],
      topAppsByRevenue: [],

      appsByCountryIndex: {},

      colorByApp: {
        base: {
          other: this.grayColor
        },
        installs: {
          other: this.grayColor
        },
        revenue: {
          other: this.grayColor
        }
      },
      colorByCountry: {
        base: {
          other: this.grayColor
        },
        installs: {
          other: this.grayColor
        },
        revenue: {
          other: this.grayColor
        }
      }
    }
  },
  mounted() {
    let tmp = [];
    let otherSum = {
      country: new Country('Other', 'other'),
      installs: 0,
      revenue: 0,
      installs_of_total_percent: 0,
      revenue_of_total_percent: 0
    }
    let otherAppSum = {
      app: {
        title: 'Other',
        icon: null,
        id: 'other'
      },
      installs: 0,
      revenue: 0,
      installs_of_total_percent: 0,
      revenue_of_total_percent: 0
    }

    this.countriesInstallsRevenueShare.forEach((item, index) => {
      if (!item.installs && !item.revenue) {
        return;
      }

      this.colorByCountry.base[item.country.code] = this.interpolateColor(index);

      this.appsByCountryIndex[item.country.code.toLowerCase()] = {
        country: item.country,
        apps: item.byApps,
        installs: item.installs,
        revenue: item.revenue,
        installs_of_total_percent: Number(item.installs_of_total_percent).toFixed(1),
        revenue_of_total_percent: Number(item.revenue_of_total_percent).toFixed(1),
      };
    });
    this.appsInstallsRevenueShare.forEach((item, index) => {
      if (!item.installs && !item.revenue) {
        return;
      }

      this.colorByApp.base[item.app.id] = this.interpolateColor(index);
    });

    let i = 0;
    this.countriesInstallsRevenueShare.sort((a, b) => b.installs - a.installs).forEach(item => {
      if (!item.installs_of_total_percent) {
        return;
      }
      if (item.installs_of_total_percent > this.minPercent) {
        tmp.push(item);
      } else {
        otherSum.installs += item.installs;
        otherSum.installs_of_total_percent += Number(item.installs_of_total_percent);
      }
    });

    if (otherSum.installs_of_total_percent)
      tmp.push(otherSum);
    this.topByInstalls = tmp;

    tmp = [];
    i = 0;
    this.countriesInstallsRevenueShare.sort((a, b) => b.revenue - a.revenue).forEach(item => {
      if (!item.revenue_of_total_percent) {
        return;
      }
      if (item.revenue_of_total_percent > this.minPercent) {
        tmp.push(item);
      } else {
        otherSum.revenue += item.revenue;
        otherSum.revenue_of_total_percent += Number(item.revenue_of_total_percent)
      }
    });

    if (otherSum.revenue_of_total_percent)
      tmp.push(otherSum);

    this.topByRevenue = tmp;

    tmp = [];
    i = 0;
    this.appsInstallsRevenueShare.sort((a, b) => b.installs - a.installs).forEach(item => {
      if (!item.installs_of_total_percent) {
        return;
      }
      if (item.installs_of_total_percent > this.minPercent) {
        tmp.push(item);
      } else {
        otherAppSum.installs += item.installs;
        otherAppSum.installs_of_total_percent += Number(item.installs_of_total_percent);
      }
    });

    if (otherAppSum.installs_of_total_percent)
      tmp.push(otherAppSum);

    this.topAppsByInstalls = tmp;

    tmp = [];
    i = 0;
    this.appsInstallsRevenueShare.sort((a, b) => b.revenue - a.revenue).forEach(item => {
      if (!item.revenue_of_total_percent) {
        return;
      }
      if (item.revenue_of_total_percent > this.minPercent) {
        tmp.push(item);
      } else {
        otherAppSum.revenue += item.revenue;
        otherAppSum.revenue_of_total_percent += Number(item.revenue_of_total_percent)
      }
    });

    if (otherAppSum.revenue_of_total_percent)
      tmp.push(otherAppSum);

    this.topAppsByRevenue = tmp;

    this.makeInstallsChart();
    if (this.revenueIsAllowed) {
      this.makeRevenueChart();
    }
    if ((this.isCountriesMode() ? this.countriesHistory : this.appsHistory).length) {
      this.makeDynamicChart();
    }

    this.dataLoaded = true;
  },
  methods: {
    collapseTableSwitch() {
      this.fullTable = !this.fullTable
      if (!this.fullTable) {
        window.scrollTo(0, this.$refs.list_table.offsetTop - 100);
      }
    },
    cropImage,
    isCountriesMode() {
      //TODO пройтись по метсам юза и рефаторнуть. тут спокойно можно вынести в абстракцию данные для отчета. Времени совсем не хватало еще вчера.
      return this.appCountriesMode === 'countries';
    },
    shortUnits,
    makeInstallsChart() {
      let data = [];

      if (this.isCountriesMode()) {
        this.topByInstalls.forEach(item => {
          if (!item.installs) {
            return;
          }
          data.push({
            country: item.country,
            app: item.app,
            name: item.country.name,
            y: item.installs,
            percent: item.installs_of_total_percent,
            color: item.country.code === 'other' ? this.grayColor : null
          });
        });
      } else {
        this.topAppsByInstalls.forEach(item => {
          if (!item.installs) {
            return;
          }
          data.push({
            country: item.country,
            app: item.app,
            name: item.app.title,
            y: item.installs,
            percent: item.installs_of_total_percent,
            color: item.app.id === 'other' ? this.grayColor : null
          });
        });
      }

      let dataWithoutOther = [];
      if (this.isCountriesMode()) {
        dataWithoutOther = data.filter(item => item.country?.code !== 'other');
      } else {
        dataWithoutOther = data.filter(item => item.app?.id !== 'other');
      }
      const minValue = Math.min(...dataWithoutOther.map(point => point.y));
      const maxValue = Math.max(...dataWithoutOther.map(point => point.y));

      let colors = [];
      if (this.isCountriesMode()) {
        colors = data.map(point => {
          return this.colorByCountry.base[point.country.code];
          // this.colorByCountry['installs'][point.country.code] = point.country.code === 'other' ? this.grayColor : this.generateGradientColor(point.y, minValue, maxValue);
          // return this.generateGradientColor(point.y, minValue, maxValue);
        });
      } else {
        colors = data.map(point => {
          return this.colorByApp.base[point.app.id];
          // this.colorByApp['installs'][point.app.id] = point.app.id === 'other' ? this.grayColor : this.generateGradientColor(point.y, minValue, maxValue);
          // return this.generateGradientColor(point.y, minValue, maxValue);
        });
      }

      const isCountriesMode = this.isCountriesMode();

      const options = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
        },
        title: {
          style: {
            display: 'none'
          }
        },
        plotOptions: {
          pie: {
            size: '100%',
            innerSize: 160,
            dataLabels: {
              enabled: false
            },
            colors
          }
        },
        tooltip: {
          "distance": 10,
          "padding": 0,
          "outside": false,
          "useHTML": true,
          "backgroundColor": "transparent",
          "borderWidth": 0,
          "borderRadius": 4,
          "shadow": false,
          "shape": "square",
          "hideDelay": 0,
          formatter: function () {
            let html = '<div class="market-tooltip pie-tooltip">';

            html += '<div class="display-flex">';
            if (isCountriesMode) {
              html += `<div class="color_hint" style="background-color: ${this.point.color}"></div><span class="ml-10">${getFlagSpan(this.point.country.code)}${this.point.country.name}</span>`;
            } else {
              html += `<div class="color_hint" style="background-color: ${this.point.color}"></div>`;
              if (this.point.app.logo) {
                html += `<img class="ml-10" src="${cropImage(this.point.app.logo, '24x24', this.point.app.store.key)}" alt="">`;
              }
              html += `<span class="ml-10 app-title-short">${this.point.app.title}</span>`;
            }

            html += '</div>';

            html += '<div class="display-flex">';
            html += `<b>${shortUnits(this.point.y)}</b><div class="ml-10 percent_hint">(${Number(this.point.percent).toFixed(1)}%)</div>`;
            html += '</div>';

            html += '</div>';

            return html;
          },
        },
        series: [{
          colorByPoint: true,
          data: data
        }]
      };

      Highcharts.chart(this.$refs.installs_chart, options);
    },
    makeRevenueChart() {
      let data = [];

      if (this.isCountriesMode()) {
        this.topByRevenue.forEach(item => {
          if (!item.revenue) {
            return;
          }
          data.push({
            country: item.country,
            name: item.country.name,
            y: item.revenue,
            color: item.country.code === 'other' ? this.grayColor : null,
            percent: item.revenue_of_total_percent
          });
        });
      } else {
        this.topAppsByRevenue.forEach(item => {
          if (!item.revenue) {
            return;
          }
          data.push({
            country: item.country,
            app: item.app,
            name: item.app.title,
            y: item.revenue,
            color: item.app.id === 'other' ? this.grayColor : null,
            percent: item.revenue_of_total_percent
          });
        });
      }

      let dataWithoutOther = [];
      if (this.isCountriesMode()) {
        dataWithoutOther = data.filter(item => item.country.code !== 'other');
      } else {
        dataWithoutOther = data.filter(item => item.app.id !== 'other');
      }
      const minValue = Math.min(...dataWithoutOther.map(point => point.y));
      const maxValue = Math.max(...dataWithoutOther.map(point => point.y));

      let colors = [];
      if (this.isCountriesMode()) {
        colors = data.map(point => {
          return this.colorByCountry.base[point.country.code];
          // this.colorByCountry['revenue'][point.country.code] = point.country.code === 'other' ? this.grayColor : this.generateGradientColor(point.y, minValue, maxValue);
          // return this.generateGradientColor(point.y, minValue, maxValue);
        });
      } else {
        colors = data.map(point => {
          return this.colorByApp.base[point.app.id];
          // this.colorByApp['revenue'][point.app.id] = point.app.id === 'other' ? this.grayColor : this.generateGradientColor(point.y, minValue, maxValue);
          // return this.generateGradientColor(point.y, minValue, maxValue);
        });
      }

      const isCountriesMode = this.isCountriesMode();

      const options = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
        },
        title: {
          style: {
            display: 'none'
          }
        },
        plotOptions: {
          pie: {
            size: '100%',
            innerSize: 160,
            dataLabels: {
              enabled: false
            },
            colors
          }
        },
        tooltip: {
          "distance": 10,
          "padding": 0,
          "outside": false,
          "useHTML": true,
          "backgroundColor": "transparent",
          "borderWidth": 0,
          "borderRadius": 4,
          "shadow": false,
          "shape": "square",
          "hideDelay": 0,
          formatter: function () {
            let html = '<div class="market-tooltip pie-tooltip">';

            html += '<div class="display-flex">';
            if (isCountriesMode) {
              html += `<div class="color_hint" style="background-color: ${this.point.color}"></div><span class="ml-10">${getFlagSpan(this.point.country.code)}${this.point.country.name}</span>`;
            } else {
              html += `<div class="color_hint" style="background-color: ${this.point.color}"></div>`;
              if (this.point.app.logo) {
                html += `<img class="ml-10" src="${cropImage(this.point.app.logo, '24x24', this.point.app.store.key)}" alt="">`;
              }
              html += `<span class="ml-10 app-title-short">${this.point.app.title}</span>`;
            }
            html += '</div>';

            html += '<div class="display-flex">';
            html += `<b>${shortUnits(this.point.y)}</b><div class="ml-10 percent_hint">(${Number(this.point.percent).toFixed(1)}%)</div>`;
            html += '</div>';

            html += '</div>';

            return html;
          },
        },
        series: [{
          colorByPoint: true,
          data: data
        }]
      };

      Highcharts.chart(this.$refs.revenue_chart, options);
    },
    changeMode(mode) {
      this.mode = mode;
      if (mode === 'revenue' && !this.revenueIsAllowed) {
        return;
      }
      this.makeDynamicChart();
    },
    makeDynamicChart() {
      More(Highcharts);

      let prop = this.mode === 'installs' ? 'sum_installs' : 'sum_revenue';

      let xCategories = [];
      let series = [];

      let fromDate = new Date(this.date_from);
      let toDate = new Date(this.date_to);

      let minValue = null;
      let maxValue = null;

      let otherData = {};

      let sumAllValuesForEachDate = {};

      let source = this.isCountriesMode() ? this.countriesHistory : this.appsHistory;

      let hasLastDate = false;
      getDatesBetween(fromDate, toDate).forEach(date => {
        let sum = 0;
        source.forEach(item => {
          sum += item.history[formatDate(date, 'digits-dash-reverse')]?.[prop] || 0;
        });
        sumAllValuesForEachDate[formatDate(date, 'digits-dash-reverse')] = sum;

        if (sum && date.getTime() === toDate.getTime()) {
          hasLastDate = true;
        }
      });

      if (!hasLastDate) {
        toDate.setMonth(toDate.getMonth() - 1);
      }
      getDatesBetween(fromDate, toDate).forEach(date => {
        xCategories.push(formatDate(date, 'month-short'));
      });

      let isCountryMode = this.isCountriesMode();
      let otherCountry = new Country('Other', 'other');
      let otherApp = {
        title: 'Other',
        icon: null,
        id: 'other'
      };
      source.forEach(item => {
        let data = [];
        getDatesBetween(fromDate, toDate).forEach(date => {
          let y = item.history[formatDate(date, 'digits-dash-reverse')]?.[prop] || null;
          let percent = y / sumAllValuesForEachDate[formatDate(date, 'digits-dash-reverse')] * 100;
          if (percent <= this.minPercent) {
            if (!otherData[date]) {
              otherData[date] = {
                y: 0,
                color: this.grayColor,
                custom: {
                  showDate: formatDate(date, 'Month, year'),
                  createApp,
                  defineComponent,
                  country: otherCountry,
                  app: otherApp,
                  percent: 0
                }
              };
            }
            otherData[date].y += y;
            otherData[date].custom.percent += percent;
            data.push(null);

            return;
          } else if (!otherData[date]) {
            otherData[date] = null;
          }

          let color = null;

          if (y) {
            color = isCountryMode ? this.colorByCountry['base'][item.country.code] : this.colorByApp['base'][item.app.id];
          }

          data.push({
            y,
            color,
            custom: {
              showDate: formatDate(date, 'Month, year'),
              createApp,
              defineComponent,
              country: item.country,
              app: item.app,
              percent: percent
            },
          });
        });

        series.push({
          name: isCountryMode ? item.country.name : item.app.title,
          data,
        });
      });

      if (Object.values(otherData).length) {
        series.push({
          name: 'Other',
          data: Object.values(otherData)
        });
      }

      const sortToEndFnc = function (a, b) {
        const aValue = a.point?.y ?? a.y;
        const bValue = b.point?.y ?? b.y;
        let aCode = null;
        let bCode = null;
        if (isCountryMode) {
          aCode = a.point?.custom?.country.code ?? a.custom?.country.code;
          bCode = b.point?.custom?.country.code ?? b.custom?.country.code;
        } else {
          aCode = a.point?.custom?.app.id ?? a.custom?.app.id;
          bCode = b.point?.custom?.app.id ?? b.custom?.app.id;
        }

        if (aCode === 'other') {
          return 1;
        } else if (bCode === 'other') {
          return -1;
        }

        if (aValue === null && bValue === null) {
          return 0;
        } else if (aValue === null) {
          return 1;
        } else if (bValue === null) {
          return -1;
        } else {
          return bValue - aValue;
        }
      }

      Highcharts.chart(this.$refs.dynamic_chart, {
        chart: {
          type: 'column',
          spacingTop: 20,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
          events: {
            render: function () {
              const series = this.series;
              const plotBottom = this.plotTop + this.plotHeight;

              series.forEach(s => {
                s.points.forEach(point => {
                  let cumulativeY = 0;
                  const stackedPoints = series.map(serie => serie.points[point.index]);
                  stackedPoints.sort(sortToEndFnc);

                  stackedPoints.forEach(stackedPoint => {
                    cumulativeY += stackedPoint.shapeArgs.height;
                    const plotY = plotBottom - cumulativeY;
                    stackedPoint.tooltipPos[1] = plotY;

                    stackedPoint.graphic?.attr({
                      y: plotY - 20
                    });
                  });
                });
              });
            }
          }
        },
        title: {
          text: null
        },
        xAxis: {
          categories: xCategories,
          lineWidth: 0,
          labels: {
            style: {
              fontSize: '16px',
              color: '#505660',
            },
          }
        },
        yAxis: {
          // type: 'logarithmic',
          title: {
            text: null
          },
          lineWidth: 0,
          gridLineWidth: 0,
          labels: {
            style: {
              fontSize: '16px',
              color: '#505660',
            }
          }
        },
        plotOptions: {
          column: {
            pointWidth: Math.min(Math.max(Math.round((((this.$refs.dynamic_chart?.clientWidth ?? 101) - 100) / (xCategories.length + 1))), 30), 200),
            stacking: 'normal',
            colorByPoint: true,
            states: {
              inactive: {
                enabled: false
              },
              hover: {
                enabled: false
              }
            }
          },
        },
        tooltip: {
          "distance": 10,
          "padding": 0,
          "outside": false,
          "useHTML": true,
          "backgroundColor": "transparent",
          "borderWidth": 0,
          "borderRadius": 4,
          "shadow": false,
          "shape": "square",
          "hideDelay": 100,
          "shared": true,
          "split": false,
          style: {
            pointerEvents: 'auto'
          },
          formatter: function () {
            let custom = this.points[0].point.custom;
            if (custom.htmlCache) {
              return custom.htmlCache;
            }

            let html = '<div class="market-tooltip dynamics_tooltip" style="color: var(--neutral-800); font-size: 15px">';
            html += `<div class="month_head">${this.points[0].point.custom.showDate}</div>`;

            this.points.sort(sortToEndFnc).forEach(point => {
              if (point.point.y) {
                html += `<div class="display-flex market-tooltip-row f-space-between">`;

                html += `<div class="display-flex">`;
                html += `<div class="color_hint" style="background-color: ${point.point.color}"></div>`;
                if (isCountryMode) {
                  html += `${getFlagSpan(point.point.custom.country.code)}`;
                  html += `<div class="ml-10">${point.point.custom.country.name}</div>`;
                } else {
                  if (point.point.custom.app.logo) {
                    html += `<img class="ml-10" src="${cropImage(point.point.custom.app.logo, '24x24', point.point.custom.app.store.key)}" alt="">`;
                  }
                  html += `<div class="ml-10 app-title-short">${point.point.custom.app.title}</div>`;
                }

                html += `</div>`;

                html += `<div class="ml-10">${shortUnits(point.point.y)} <span style="color: var(--neutral-700)">(${point.point.custom.percent.toFixed(1)}%)</span></div>`;
                html += `</div>`;
              }
            });

            html += '</div>';

            custom.htmlCache = html;

            return html;
          },
        },
        legend: {
          enabled: false
        },
        series: series
      });
    },

    progressColor(percent) {
      let color = '';
      percent = Number(percent).toFixed();
      if (percent >= 80) {
        color = '#5AAC80';
      } else if (percent >= 60) {
        color = '#6DD690';
      } else if (percent >= 40) {
        color = '#FCB852';
      } else if (percent >= 20) {
        color = '#F7D758';
      } else {
        color = '#EC5C3E';
      }

      return `background: linear-gradient(90deg, ${color} 0%, ${color} ${percent}%, #E1E8F2 ${percent}%, #E1E8F2 100%);`;
    },

    generateGradientColor(value, minValue, maxValue) {
      const gradientStop = (value - minValue) / (maxValue - minValue);
      const startColor = '#93d1d5';
      const endColor = '#384e9d';

      if (value === maxValue) {
        return endColor;
      }
      if (value === minValue) {
        return startColor;
      }

      let r = Math.round((1 - gradientStop) * parseInt(startColor.slice(1, 3), 16) + gradientStop * parseInt(endColor.slice(1, 3), 16));
      let g = Math.round((1 - gradientStop) * parseInt(startColor.slice(3, 5), 16) + gradientStop * parseInt(endColor.slice(3, 5), 16));
      let b = Math.round((1 - gradientStop) * parseInt(startColor.slice(5, 7), 16) + gradientStop * parseInt(endColor.slice(5, 7), 16));

      return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
    },
    interpolateColor(index) {
      return colorByIndex(index);
    },
    onResize() {
      if (window.researchDynamicChartResizeTimeout) {
        window.clearTimeout(window.researchDynamicChartResizeTimeout);
      }
      window.researchDynamicChartResizeTimeout = window.setTimeout(() => {
        this.makeDynamicChart();
      }, 100);
    },
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize);
    if (window.researchDynamicChartResizeTimeout) {
      window.clearTimeout(window.researchDynamicChartResizeTimeout);
    }
  },
  created() {
    window.addEventListener('resize', this.onResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  }
}
</script>

<template>
  <div @resize="onResize">
    <div class="map_and_graphics display-flex">
      <div class="left_charts">
        <div class="common-white-container display-flex" v-if="isCountriesMode()">
          <div class="left_chart">
            <div class="left_chart_title common-block-title">Installs</div>
            <div ref="installs_chart"></div>
          </div>
          <div class="market-research-legend">
            <div v-for="(data) in topByInstalls.slice(0, 6)">
              <MarketResearchLegendItem
                  :color-by-app="colorByCountry['base']"
                  :data="data"
                  :color-by-country="colorByCountry['base']"
                  :gray-color="grayColor"
                  :country-mode="true"
              ></MarketResearchLegendItem>
            </div>
            <custom-tooltip direction-horizontal="right" :classes="'chart-icon'"
                            :style="{width: 'max-content', paddingRight: '30px'}" v-if="topByInstalls.length > 6">
              <template v-slot:slotTrigger>
                <div class="more-countries">
                  <svg-icon icon="eye"></svg-icon>
                  More
                </div>
              </template>
              <template v-slot:slotContent>
                <div class="market-research-legend">
                  <div v-for="(data) in topByInstalls.slice(6)">
                    <MarketResearchLegendItem
                        :color-by-app="colorByCountry['base']"
                        :data="data"
                        :color-by-country="colorByCountry['base']"
                        :gray-color="grayColor"
                        :country-mode="true"
                    ></MarketResearchLegendItem>
                  </div>
                </div>
              </template>
            </custom-tooltip>
          </div>
        </div>
        <div class="common-white-container display-flex" v-else>
          <div class="left_chart">
            <div class="left_chart_title common-block-title">Installs</div>
            <div ref="installs_chart"></div>
          </div>
          <div class="market-research-legend">
            <div v-for="(data) in topAppsByInstalls.slice(0, 6)">
              <MarketResearchLegendItem
                  :color-by-app="colorByApp['base']"
                  :data="data"
                  :color-by-country="colorByCountry['base']"
                  :gray-color="grayColor"
                  :country-mode="false"
              ></MarketResearchLegendItem>
            </div>
            <custom-tooltip :classes="'chart-icon'" :style="{width: 'max-content', paddingRight: '30px'}"
                            v-if="topAppsByInstalls.length > 6">
              <template v-slot:slotTrigger>
                <div class="more-countries">
                  <svg-icon icon="eye"></svg-icon>
                  More
                </div>
              </template>
              <template v-slot:slotContent>
                <div class="market-research-legend">
                  <div v-for="(data) in topAppsByInstalls.slice(6)">
                    <MarketResearchLegendItem
                        :color-by-app="colorByApp['base']"
                        :data="data"
                        :color-by-country="colorByCountry['base']"
                        :gray-color="grayColor"
                        :country-mode="false"
                    ></MarketResearchLegendItem>
                  </div>
                </div>
              </template>
            </custom-tooltip>
          </div>
        </div>
        <template v-if="revenueIsAllowed">
          <div class="common-white-container display-flex" v-if="isCountriesMode()">
            <div class="left_chart">
              <div class="left_chart_title common-block-title">Revenue</div>
              <div ref="revenue_chart"></div>
            </div>
            <div class="market-research-legend">
              <div v-for="(data) in topByRevenue.slice(0, 6)">
                <MarketResearchLegendItem
                    :color-by-app="colorByCountry['base']"
                    :data="data"
                    :color-by-country="colorByCountry['base']"
                    :gray-color="grayColor"
                    :country-mode="true"
                    percent-prop="revenue_of_total_percent"
                    value-prop="revenue"
                ></MarketResearchLegendItem>
              </div>
              <custom-tooltip :classes="'chart-icon'" :style="{width: 'max-content', paddingRight: '30px'}"
                              v-if="topByRevenue.length > 6">
                <template v-slot:slotTrigger>
                  <div class="more-countries">
                    <svg-icon icon="eye"></svg-icon>
                    More
                  </div>
                </template>
                <template v-slot:slotContent>
                  <div class="market-research-legend">
                    <div v-for="(data) in topByRevenue.slice(6)">
                      <MarketResearchLegendItem
                          :color-by-app="colorByCountry['base']"
                          :data="data"
                          :color-by-country="colorByCountry['base']"
                          :gray-color="grayColor"
                          :country-mode="true"
                          percent-prop="revenue_of_total_percent"
                          value-prop="revenue"
                      ></MarketResearchLegendItem>
                    </div>
                  </div>
                </template>
              </custom-tooltip>
            </div>
          </div>
          <div class="common-white-container display-flex" v-else>
            <div class="left_chart">
              <div class="left_chart_title common-block-title">Revenue</div>
              <div ref="revenue_chart"></div>
            </div>
            <div class="market-research-legend">
              <div v-for="(data) in topAppsByRevenue.slice(0, 6)">
                <MarketResearchLegendItem
                    :color-by-app="colorByApp['base']"
                    :data="data"
                    :color-by-country="colorByApp['base']"
                    :gray-color="grayColor"
                    :country-mode="false"
                    percent-prop="revenue_of_total_percent"
                    value-prop="revenue"
                ></MarketResearchLegendItem>
              </div>
              <custom-tooltip :classes="'chart-icon'" :style="{width: 'max-content', paddingRight: '30px'}"
                              v-if="topAppsByRevenue.length > 6">
                <template v-slot:slotTrigger>
                  <div class="more-countries">
                    <svg-icon icon="eye"></svg-icon>
                    More
                  </div>
                </template>
                <template v-slot:slotContent>
                  <div class="market-research-legend">
                    <div v-for="(data) in topAppsByRevenue.slice(6)">
                      <MarketResearchLegendItem
                          :color-by-app="colorByApp['base']"
                          :data="data"
                          :color-by-country="colorByApp['base']"
                          :gray-color="grayColor"
                          :country-mode="false"
                          percent-prop="revenue_of_total_percent"
                          value-prop="revenue"
                      ></MarketResearchLegendItem>
                    </div>
                  </div>
                </template>
              </custom-tooltip>
            </div>
          </div>
        </template>
      </div>
      <div class="map_container common-white-container">
        <div class="display-flex f-j-end">
          <div class="common-block-title cursor-pointer" :class="{'active': mode === 'installs'}"
               @click="changeMode('installs')">Installs
          </div>
          <div class="common-block-title ml-10 cursor-pointer" :class="{'active': mode === 'revenue'}"
               v-if="revenueIsAllowed"
               @click="changeMode('revenue')">Revenue
          </div>
        </div>
        <top-map
            :key="mode"
            v-if="dataLoaded"
            :countries-installs-revenue-share="countriesInstallsRevenueShare"
            :apps-by-country-index="appsByCountryIndex"
            :min-percent="minPercent"
            :mode="mode"
        ></top-map>
      </div>
    </div>
    <div class="dynamic_graphic_container" v-if="(isCountriesMode ? countriesHistory : appsHistory).length">
      <div>
        <div class="common-big-block-title">Market {{ mode === 'installs' ? 'installs' : 'revenue' }} dynamics in
          {{ reportApp.store.key === 'GOOGLE_PLAY' ? 'Google Play' : 'App Store' }}
        </div>
      </div>
      <div class="dynamic_graphic_chart_container common-white-container">
        <div class="display-flex f-j-end">
          <div class="common-block-title cursor-pointer" :class="{'active': mode === 'installs'}"
               @click="changeMode('installs')">Installs
          </div>
          <div class="common-block-title ml-10 cursor-pointer" :class="{'active': mode === 'revenue'}"
               v-if="revenueIsAllowed"
               @click="changeMode('revenue')">Revenue
          </div>
        </div>
        <div ref="dynamic_chart" style="height: 400px"></div>
      </div>
    </div>
    <div class="list_table_container" ref="list_table"
         v-if="(isCountriesMode() ? countriesInstallsRevenueShare : appsInstallsRevenueShare).length">
      <SimpleInfoTable
          :table-id="'market-research-countries'"
          :tr-class-callback="trClassCallback"
          :key="'research_installs_revenue_share_table_' + mode"
          :per-page="fullTable ? 999 : 10"
          :show-pagination="false"
          :columns="{
            0: {header: (isCountriesMode() ? 'Countries' : 'Apps'), style: {width: '100%', minWidth: isCountriesMode() ? '200px' : '250px'}},
            1: {header: 'Installs', style: {'min-width': '190px'}, sortBy: 'installs'},
            2: {header: 'Installs Share', style: {'min-width': '170px'}},
            3: {header: 'Revenue', style: {'min-width': '190px'}, sortBy: 'revenue', hidden: !revenueIsAllowed},
            4: {header: 'Revenue Share', style: {'min-width': '170px'}, hidden: !revenueIsAllowed},
          }"
          :default-sort-index="this.mode === 'installs' ? 1 : 3"
          :default-sort-direction="'desc'"
          :items="isCountriesMode() ? countriesInstallsRevenueShare : appsInstallsRevenueShare"
      >
        <template v-slot:headers="slotProps">
          <div>
            <span :class="{'display-flex f-j-end f-align-center': [5, 6].includes(slotProps.index)}"
                  v-html="slotProps.header"></span>
          </div>
        </template>
        <template v-slot:items-0="slotProps">
          <div class="display-flex f-align-center" v-if="isCountriesMode()">
            <dynamic-image classes="flag"
                           :width="18"
                           :height="14"
                           :size="32"
                           :country-code="slotProps.item.country.code"/>
            <span class="ml-10">{{ slotProps.item.country.name }}</span>
          </div>
          <div class="display-flex f-align-center" v-else>
            <img :src="cropImage(slotProps.item.app.logo, '32x32', slotProps.item.app.store.key)" alt="">
            <span class="ml-10" v-html="formatItemAppTitle(slotProps.item)"></span>
          </div>
        </template>
        <template v-slot:items-1="slotProps">
          <div class="display-flex f-align-center f-space-between full-width">
            <progress-bar width="80px"
                          height="8px"
                          :version="2"
                          :fill-color="progressColor(slotProps.item.installs_percent_between_min_max)"
                          bar-type="custom"/>
            {{ shortUnits(slotProps.item.installs) }}
          </div>
        </template>
        <template v-slot:items-2="slotProps">
          <div class="display-flex f-align-center f-j-end">
            <span class="ml-10">{{ Number(slotProps.item.installs_of_total_percent).toFixed(1) }}%</span>
          </div>
        </template>
        <template v-slot:items-3="slotProps">
          <div class="display-flex f-align-center f-space-between full-width">
            <progress-bar width="80px"
                          height="8px"
                          :version="2"
                          :fill-color="progressColor(slotProps.item.revenue_percent_between_min_max)"
                          bar-type="custom"/>
            {{ shortUnits(slotProps.item.revenue) }}
          </div>
        </template>
        <template v-slot:items-4="slotProps">
          <div class="display-flex f-align-center f-j-end">
            <span class="ml-10">{{ Number(slotProps.item.revenue_of_total_percent).toFixed(1) }}%</span>
          </div>
        </template>
      </SimpleInfoTable>
      <div class="display-flex f-j-center" style="margin-top: 30px"
           v-if="(isCountriesMode() ? countriesInstallsRevenueShare : appsInstallsRevenueShare).length > 10">
        <div class="common-btn-purple" style="min-width: 190px" @click="collapseTableSwitch">
          {{ fullTable ? 'Collapse' : 'Show More' }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.left_charts {
  > div {
    padding: 0px 20px 0px 0px;
    width: max-content;
    align-items: initial;
    min-width: 500px;

    &:first-of-type {
      margin-bottom: 20px;
    }
  }

  .left_chart {
    width: 225px;
    height: 225px;
    margin: 15px 0px 15px 15px;
    position: relative;

    > div {
      width: 225px;
      height: 225px;
    }

    .left_chart_title {
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: initial;
      height: initial;
    }
  }
}

.map_container {
  margin-left: 20px;
  width: 100%;
  height: 530px;
  padding: 20px;

  > div:first-child {
    margin-bottom: 10px;
  }

  > div:last-child {
    height: 450px;

    > div {
      height: 450px;
    }
  }

  .common-big-block-title {
    opacity: 0.32;
    cursor: pointer;

    &.active {
      opacity: 1;
    }
  }
}

.common-big-block-title {
  margin-top: 40px;
  margin-bottom: 20px;
}

.dynamic_graphic_container {
  .dynamic_graphic_chart_container {
    padding: 20px;

    > div {
      overflow: visible !important;
    }
  }
}

.map_container, .dynamic_graphic_container {
  .common-block-title:not(.active) {
    opacity: 0.33;
  }
}

@media (max-width: 1250px) {
  .map_and_graphics {
    flex-direction: column;
  }
  .map_container {
    margin-left: 0;
    margin-top: 20px;
  }
}
</style>
<style lang="scss">
.color_hint {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.market-tooltip-row {
  margin-bottom: 5px;
}

.market-tooltip {
  box-shadow: 0px 4px 8px 0px #A0BEDD4D;
  border-radius: 4px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid var(--neutral-300);
}

.month_head {
  color: var(--neutral-900);
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}

.market-tooltip.dynamics_tooltip {
  .flag {
    margin-left: 10px;
  }
}

.market-tooltip.pie-tooltip {
  color: var(--neutral-800);
  font-size: 16px;

  .flag {
    margin-left: 0;
    margin-right: 7px;
    margin-bottom: 4px;
  }

  > div:first-child {
    margin-bottom: 5px;
  }

  .app-title-short {
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  b {
    font-weight: 600;
  }
}

.list_table_container {
  margin-top: 40px;
}

.market-research-legend {
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--neutral-800);

  > div {
    margin-bottom: 7px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .percent_hint {
    color: var(--neutral-600);
  }

  .more-countries {
    color: var(--primary-default);
  }

  .app-title-short {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .country-title-short {
    max-width: 135px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.left_charts .highcharts-container {
  position: inherit !important;
}

.market_research_container {
  .highcharts-container {
    z-index: 500 !important;
  }

  .highcharts-tooltip {
    z-index: 500;
  }
}
</style>