<template>
  <template v-if="item?.is_on_warm">
    <preloader-table-small :loader-size="'30px'" title="Data will be available soon"/>
  </template>
  <template v-else>
    <div class="rank-block rank-difference">
      <div class="rank-difference green"
           v-if="item?.difference > 0 || item?.difference === 'in'">
        <template v-if="item?.difference === 'in'">
          {{ item?.difference }}
        </template>
        <template v-else>
          {{ absoluteValue(item?.difference) }}
          <img src="@/assets/svg/arrow-up-alt.svg"
               width="12"
               height="12"
               alt="Arrow up"/>
        </template>
      </div>
      <div class="rank-difference red"
           v-if="item?.difference < 0 || item?.difference === 'out'">
        <template v-if="item?.difference === 'out'">
          {{ item?.difference }}
        </template>
        <template v-else>
          {{ absoluteValue(item?.difference) }}
          <img src="@/assets/svg/arrow-down-alt.svg"
               width="12"
               height="12"
               alt="Arrow down"/>
        </template>
      </div>
    </div>


    <span class="value-block ">
      {{ item?.difference !== 'out' && item?.rank ? item?.rank : ''}}
    </span>
  </template>
</template>

<script>
import {defineComponent} from 'vue';
import {formatDate} from "@/utils/utils";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import DefaultSpinner from "@/components/UI/DefaultSpinner/index.vue";
import Tooltip from "@/components/UI/Tooltip/index.vue";

export default defineComponent({
  name: "AppRankTool",
  components: {
    'preloader-table-small': PreloaderTableSmall,
    DefaultSpinner,
    'custom-tooltip': Tooltip,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  methods: {
    absoluteValue(val) {
      if (val === 'in' || val === 'out') {
        return val;
      }
      return Math.abs(val);
    },
    formatDateFunc(date, type) {
      return formatDate(date, type);
    }
  }
})
</script>

<style scoped lang="scss">
.rank-block  {
  min-width: 25px;
}
.rank-difference {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  margin-right: 4px;
}

.rank-difference.red {
  color: #f17a54;
}

.rank-difference.green {
  color: #6bd790;
}

.value-block {
  min-width: 19px;
  text-align: end;
}
.spinner-wrap {
  display: flex;
  align-items: center;

  .rank-difference {
    margin-left: 12px;
  }
}
</style>