<template>
  <div class="position-history">
    <custom-modal
        :opened="showModal"
        :max-width="1200"
        class-name="position-history-modal"
        @closeModal="close"
    >
      <div class="modal-wrapper" >
        <div class="title-block">
          <div class="title">Keyword Ranking History</div>
          <div class="chips">{{keyword}}</div>
        </div>
        <expand-rank-table-chart
            :app="currentApp"
            :country="currentCountry"
            :keyword="keyword"
            @close="close"
        />
      </div>
    </custom-modal>
  </div>
</template>

<script>

import ExpandRankTableChart from "@/components/DataTables/Tools/ExpandRankTableChart/ExpandRankTableChart.vue";

export default {
  name: 'PositionHistoryModal',
  components: {ExpandRankTableChart},
  emits: ['modal-closed'],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    currentApp: {
      type: Object,
      required: true,
    },
    currentCountry: {
      type: Object,
      required: true,
    },
    keyword: {
      type: Object,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('modal-closed');
    },
  }
}
</script>
<style lang="scss" src="./styles.scss"></style>
