import MyApps from "@/views/MyApps";

export const routes = [
    {
        path: '/',
        name: 'MyApps',
        component: MyApps,
        meta: { loaderText: 'Loading Apps', pageTitle: 'My Apps' }
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: () => import('@/components/UI/CountdownLayout/index'),
    },
    {
        path: '/login',
        name: 'Authorization',
        component: () => import('@/views/Authorization/index'),
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('@/views/ResetPassword/index'),
    },
    {
        path: '/account/',
        component: () => import('../views/Account/index.vue'),
        children: [
            {
                path: '/profile',
                name: 'Profile',
                component: () => import('../views/Account/Profile/index.vue'),
                meta: { pageTitle: 'ASOlytics - Account Settings', header: 'Profile', showTabs: true }
            },
            {
                path: '/notifications-list',
                name: 'NotificationsList',
                component: () => import('../views/Account/ReportsList/index.vue'),
                meta: { pageTitle: 'Reports List', header: 'Profile', showTabs: true }
            },
            {
                path: '/notification-settings/:app_id',
                name: 'NotificationSettings',
                component: () => import('../views/Account/ReportSettings/index.vue'),
                meta: { pageTitle: 'Reports Settings', header: 'Profile' }
            },
            {
                path: 'integration-settings',
                name: 'IntegrationSettingsList',
                component: () => import('../views/Account/IntegrationSettings/index.vue'),
                meta: { pageTitle: 'Integrations', header: 'Profile', showTabs: true }
            },
            {
                path: 'integration-settings/app-store/share-email/:app_id',
                name: 'IntegrationSettingsASEmail',
                component: () => import('../views/Account/IntegrationSettings/AppStore/ViaEmail/index.vue'),
                meta: { pageTitle: 'App Store Account Connecting', header: 'Profile' }
            },
            {
                path: 'integration-settings/app-store/api/:app_id',
                name: 'IntegrationSettingsASAPI',
                component: () => import('../views/Account/IntegrationSettings/AppStore/ViaAPI/index.vue'),
                meta: { pageTitle: 'Connecting your App Store Account', header: 'Profile' }
            },
            {
                path: 'integration-settings/app-store/api/:app_id/edit',
                name: 'IntegrationSettingsASAPIEdit',
                component: () => import('../views/Account/IntegrationSettings/AppStore/ViaAPI/index.vue'),
                props: { editMode: true },
                meta: { pageTitle: 'Connecting your App Store Account', header: 'Profile' }
            },
            {
                path: 'settings',
                name: 'Settings',
                component: () => import('../views/Account/Settings/index.vue'),
                props: { editMode: true },
                meta: { pageTitle: 'ASOlytics - Account Settings', header: 'Profile', showTabs: true }
            },
        ],
    },
    {
        path: '/market-research',
        name: 'MarketResearch',
        component: () => import('../views/MarkerResearch/MarketResearchList.vue'),
        meta: { pageTitle: 'Market Research Reports' },
    },
    {
        path: '/market-research/:researchId',
        name: 'MarketResearchItem',
        component: () => import('../views/MarkerResearch/MarketResearch.vue'),
        props: true,
        meta: { pageTitle: 'Market Research' }
    },
    {
        path: '/search-terms/',
        component: () => import('../views/SearchTerms/index.vue'),
        meta: { requiresSelectedApp: true },
        children: [
            {
                path: 'keywords-tracking',
                name: 'KeywordsTracking',
                component: () => import('../views/SearchTerms/KeywordsTracking/index.vue'),
                meta: { pageTitle: 'Tracking Keywords ', loaderText: 'Loading Keywords...' }
            },
            {
                path: 'live-search',
                name: 'LiveSearch',
                component: () => import('../views/SearchTerms/LiveSearch/index.vue'),
                meta: { pageTitle: 'Live Search Tool', loaderText: 'Loading Search Results...' }
            },
            {
                path: 'ranking-keywords',
                name: 'RankingKeywords',
                component: () => import('../views/SearchTerms/RankingKeywords/index.vue'),
                meta: { pageTitle: 'Ranking Keywords', loaderText: 'Loading Keywords...' }
            },
            {
                path: 'competitors-keywords',
                name: 'CompetitorKeywords',
                component: () => import('../views/SearchTerms/CompetitorsKeywords/index.vue'),
                meta: { pageTitle: 'ASOlytics - Competitors Keywords', loaderText: 'Loading Competitors Keywords...' }
            },
            {
                path: 'keywords-autosuggest',
                name: 'KeywordsAutosuggest',
                component: () => import('../views/SearchTerms/KeywordsAutosuggest/index.vue'),
                meta: { pageTitle: 'Keyword Autosuggests Analysis', loaderText: 'Loading Autosuggest Results...' }
            },
            {
                path: 'metadata-keywords',
                name: 'MetadataKeywords',
                component: () => import('../views/SearchTerms/MetadataKeywords/index.vue'),
                meta: { pageTitle: 'Keywords from Metadata (Competitors, My App)', loaderText: 'Loading Metadata Keywords...' }
            },
            {
                path: 'recommended-keywords',
                name: 'RecommendedKeywords',
                component: () => import('../views/SearchTerms/RecommendedKeywords/index.vue'),
                meta: { pageTitle: 'Recommended Keywords Intelligence', loaderText: 'Loading Keywords...' },
                // beforeEnter: (to, from, next) => {
                //     const betaTesterCookie = getCookie('betaTesterCookie');
                //     if (betaTesterCookie) {
                //         next();
                //     } else {
                //         window.location.href = '/';
                //     }
                // }
            },
            {
                path: 'related-keywords',
                name: 'RelatedKeywords',
                component: () => import('../views/SearchTerms/RelatedKeywords/index.vue'),
                meta: { pageTitle: 'Related Keywords Search Intelligence', loaderText: 'Loading Results...' }
            },
        ],
    },
    {
        path: '/store/',
        component: () => import('../views/AppStore/index.vue'),
        meta: { requiresSelectedApp: true },
        children: [
            {
                path: 'app-ranking',
                name: 'AppRanking',
                component: () => import('../views/AppStore/AppRanking/index.vue'),
                meta: { pageTitle: 'Top Charts', loaderText: 'Loading App Ranking...' }
            },
        ],
    },
    {
        path: '/dashboard/',
        component: () => import('../views/DashboardPage/index.vue'),
        meta: { requiresSelectedApp: true },
        children: [
            {
                path: 'overview',
                name: 'DashboardOverview',
                component: () => import('../views/DashboardPage/Overview/index.vue'),
                meta: { pageTitle: 'Dashboard - Overview', loaderText: 'Loading Dashboard...' }
            },
            {
                path: 'visibility',
                name: 'DashboardVisibility',
                component: () => import('../views/DashboardPage/Visibility/index.vue'),
                meta: { pageTitle: 'Dashboard - Visibility', loaderText: 'Loading Dashboard...' }
            },
            {
                path: 'category-ranking',
                name: 'DashboardCategoryRanking',
                component: () => import('../views/DashboardPage/CategoryRanking/index.vue'),
                meta: { pageTitle: 'Dashboard - Category Ranking', loaderText: 'Loading Dashboard...' }
            },
            {
                path: 'app-info',
                name: 'DashboardAppInfo',
                component: () => import('../views/DashboardPage/AppInfo/index.vue'),
                meta: { pageTitle: 'Dashboard - App Info', loaderText: 'Loading Dashboard...' }
            },
        ],
    },
    {
        path: '/competitors-finder/',
        component: () => import('../views/CompetitorsFinderPage/index.vue'),
        meta: { requiresSelectedApp: true },
        // beforeEnter: (to, from, next) => {
        //     const betaTesterCookie = getCookie('betaTesterCookie');
        //     if (betaTesterCookie) {
        //         next();
        //     } else {
        //         window.location.href = '/';
        //     }
        // },
        children: [
            {
                path: 'finder',
                name: 'CompetitorsFinder',
                component: () => import('../views/CompetitorsFinderPage/Finder/index.vue'),
                meta: { pageTitle: 'Competitors Finder Intelligence', loaderText: 'Loading Competitors...' },
            },
            {
                path: 'competitors',
                name: 'Competitors',
                component: () => import('../views/CompetitorsFinderPage/Competitors/index.vue'),
                meta: { pageTitle: 'Competitors', loaderText: 'Loading Competitors...' },
                children: [
                    {
                        path: '',
                        name: 'MyCompetitors',
                        component: () => import('../views/CompetitorsFinderPage/Competitors/tab_apps.vue'),
                        meta: { pageTitle: 'My Competitors', loaderText: 'Loading Competitors...' },
                    },
                    {
                        path: 'creatives',
                        name: 'Creatives',
                        component: () => import('../views/CompetitorsFinderPage/Competitors/tab_creatives.vue'),
                        meta: { pageTitle: 'Creatives', loaderText: 'Loading Creatives...' },
                    },
                    {
                        path: 'metadata',
                        name: 'Metadata',
                        component: () => import('../views/CompetitorsFinderPage/Competitors/tab_metadata.vue'),
                        meta: { pageTitle: 'Metadata', loaderText: 'Loading Metadata...' },
                    },
                    {
                        path: 'reviews-ai',
                        name: 'ReviewsAI',
                        component: () => import('../views/CompetitorsFinderPage/Competitors/ReviewsAI/tab_reviews.vue'),
                        meta: { pageTitle: 'Reviews AI Summary', loaderText: 'Loading Data...' },
                    },
                ]
            },
            {
                path: 'non-competitors',
                name: 'NonCompetitors',
                component: () => import('../views/CompetitorsFinderPage/NonCompetitors/index.vue'),
                meta: { competitor: 0, pageTitle: 'Apps you marked as non-Competitors', loaderText: 'Loading Competitors...' },
            },
            {
                path: 'indirect-competitors',
                name: 'IndirectCompetitors',
                component: () => import('../views/CompetitorsFinderPage/NonCompetitors/index.vue'),
                meta: { competitor: 2, pageTitle: 'Apps you marked as Indirect Competitors', loaderText: 'Loading Competitors...' },
            },
            {
                path: 'apps-timeline',
                name: 'AppsTimeline',
                component: () => import('../views/CompetitorsFinderPage/AppsTimelines/index.vue'),
                meta: { pageTitle: 'Apps Updates Timeline', loaderText: 'Loading...' },
            },
            {
                path: 'finder-v2',
                name: 'CompetitorsFinderV2',
                component: () => import('../views/CompetitorsFinderPage/FinderNew/index.vue'),
                meta: { pageTitle: 'Competitors Finder', loaderText: 'Loading Competitors...' },
            },
        ],
    },
    {
        path: '/reports/',
        component: () => import('../views/SearchTerms/index.vue'),
        meta: { requiresSelectedApp: false },
        children: [
            {
                path: 'impressions',
                name: 'ImpressionsReport',
                component: () => import('../views/Reports/Impressions/index.vue'),
                meta: {
                    pageTitle: 'Estimated Impressions Report',
                    loaderText: 'Loading data...',
                    hideHeaderCountrySelector: true,
                },
            },
            {
                path: 'rank-comparative',
                name: 'RankComparativeReport',
                component: () => import('../views/Reports/RankComparative/index.vue'),
                meta: {
                    pageTitle: 'Rank Comparative Report',
                    loaderText: 'Loading data...',
                    hideHeaderCountrySelector: true,
                },
            },
            {
                path: 'rank-breakdown',
                name: 'RankBreakdownReport',
                component: () => import('../views/Reports/RankBreakdown/index.vue'),
                meta: {
                    pageTitle: 'Rank Breakdown Report',
                    loaderText: 'Loading data...',
                    hideHeaderCountrySelector: true,
                },
            },
        ],
    },
    {
        path: '/meta-editor/',
        name: 'MetaEditor',
        component: () => import('../views/MetaEditor/index.vue'),
        meta: { pageTitle: 'Metadata Editor', hideHeaderCountrySelector: true},
    },
    {
        path: '/analyze/keyword-explorer',
        name: 'KeywordExplorer',
        component: () => import('../views/Analyze/KeywordExplorer/index.vue'),
        meta: { pageTitle: 'Keyword Analysis' }
    },
    {
        path: '/analyze/analyze-app',
        name: 'AnalyzeApp',
        component: () => import('../views/Analyze/AnalyzeApp/index.vue'),
        meta: { pageTitle: 'App Analysis' }
    },
    {
        path: '/choose-plan',
        name: 'PlanSelectionPage',
        component: () => import('../views/PlanSelectionPage/index.vue'),
        meta: { pageTitle: 'ASOlytics - Choose your plan' }
    },
    {
        path: '/add-application',
        name: 'AddFirstAppPage',
        component: () => import('../views/AddFirstAppPage/index.vue'),
        meta: { pageTitle: 'ASOlytics - Add your first application' }
    },
    {
        path: '/:notFound(.*)',
        name: 'Not Found',
        component: () => import('../views/NotFound/index.vue'),
    },
];
