<script>
import ProgressIndicator from "@/components/UI/ProgressIndicator";
import CompetitorBillet from "@/views/CompetitorsFinderPage/components/CompetitorBillet";
import LanguageSelector from "@/views/CompetitorsFinderPage/components/LanguageSelectorWithLoad/index.vue";
import CreativesAll from "@/views/CompetitorsFinderPage/Competitors/Creatives/All.vue";
import CreativesIcons from "@/views/CompetitorsFinderPage/Competitors/Creatives/Icons.vue";
import CreativesScreenshots from "@/views/CompetitorsFinderPage/Competitors/Creatives/Screenshots.vue";
import Competitors_select_modal from "@/views/CompetitorsFinderPage/Competitors/competitors_select_modal.vue";
import SimpleInfoTable from "../../../components/SimpleInfoTable/SimpleInfoTable.vue";
import MetadataKeywordsTable from "./Metadata/MetadataKeywordsTable.vue";
import {cropImage, shortenDigits} from "@/utils/utils";
import SvgIcon from "../../../components/UI/SvgIcon/index.vue";
import myApps from "../../../api/modules/my-apps";
import RatingTool from "../../../components/DataTables/Tools/RatingTool/index.vue";

export default {
  name: "competitors_tab_metadata",
  components: {
    RatingTool,
    SvgIcon,
    MetadataKeywordsTable,
    SimpleInfoTable,
    Competitors_select_modal,
    CreativesScreenshots,
    CreativesIcons,
    CreativesAll,
    LanguageSelector,
    'progress-indicator': ProgressIndicator,
    'competitor-billet': CompetitorBillet,
  },
  props: {
    currentApp: {
      type: Object,
      default: null,
    },
    currentCountry: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loaderText: 'Loading creatives...',

      loadingApp: false,
      loadingCompetitors: false,

      firstLoaded: false,
      competitorsModalIsOpen: false,
      competitors: [],
      locale: null,
      competitorsObj: null,

      competitorsMetadata: {},
      currentAppMetadata: {},

      lastOpenedItem: null,

      includedTexts: ['title', 'subtitle'],

      countryLocalesConfig: {
        border: 'none',
        boxShadow: '0 8px 38px 0 rgba(184, 186, 229, 0.16)',
        height: '36px',
      }
    }
  },
  computed: {
    competitorsPlusMyApp() {
      return [{
        title: this.currentApp.title,
        competitorId: this.currentApp.id,
        logo: this.currentApp.logo,
        rating: this.currentApp.rating,
        votesCount: this.currentApp.votes,
        store: this.currentApp.store.key,
        mutateToModalAppFormat() {
          return {
            id: this.competitorId,
            title:this.title,
            store: this.store,
            logo: this.logo,
          };
        }
      }, ...this.competitors];
    },
    allTitles() {
      return Object.values(this.competitorsMetadata).map((competitor) => {
        return competitor.title;
      }).filter((v) => v !== null);
    },
    allSubtitles() {
      return Object.values(this.competitorsMetadata).map((competitor) => {
        return competitor.sub_title;
      }).filter((v) => v !== null);
    },
    allDescriptions() {
      return Object.values(this.competitorsMetadata).map((competitor) => {
        return competitor.description;
      }).filter((v) => v !== null);
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.title = to?.meta?.pageTitle ?? '';
      vm.loaderText = to?.meta?.loaderText ?? '';
    })
  },
  mounted() {

  },
  methods: {
    shortenDigits,
    fetchCompetitorsMetadata() {
      this.loadingCompetitors = true;

      myApps.getAppsAso(this.competitorsPlusMyApp.map((app) => app.competitorId), this.locale.code).then(response => {
        this.competitorsMetadata = response;
      });

      this.loadingCompetitors = false;
    },
    fetchCurrentAppMetadata() {
      myApps.getAppsAso([this.currentApp.id], this.locale.code).then(response => {
        this.currentAppMetadata = response[this.currentApp.id];
      });
    },
    cropImage,
    changedLocale(locale) {
      this.locale = locale;
      this.fetchCurrentAppMetadata();
      this.fetchCompetitorsMetadata();
    },
    selectedCompetitors(newObj) {
      this.firstLoaded = true;
      this.competitorsObj = newObj;
      let tmp = this.competitorsObj.getCompetitorsCheckedOnlyInitial();
      this.competitors = tmp.map(competitor => {
        competitor.opened = false;

        return competitor;
      });
      this.competitorsModalIsOpen = false;

      this.fetchCompetitorsMetadata();
    },
    competitorsModelOpen() {
      this.$refs.competitors_select_modal.openModal();
    },
    handleOpenFullSection(item) {
      if (this.lastOpenedItem) {
        this.lastOpenedItem.opened = false;
      }

      if (this.lastOpenedItem === item) {
        this.lastOpenedItem = null;
        return;
      }

      item.opened = !item.opened;
      this.lastOpenedItem = item;
    },
    trClassCallback(item) {
      if (!this.currentApp?.id) {
        return {};
      }

      return {'my_app': item.competitorId === this.currentApp.id};
    }
  },
}
</script>

<template>
  <div>
    <div class="progress-wrap" v-if="loading">
      <progress-indicator>{{ loaderText }}</progress-indicator>
    </div>
    <template v-else>
      <div>
        <div class="display-flex mb-24 no-select flex-wrap">
          <div class="common-mid-block-title">General Competitors Keyword Density</div>
          <div class="display-flex ml-15">
            <div class="display-flex ml-8">
              <label class="cursor-pointer display-flex"><input v-model="includedTexts" type="checkbox"
                                                                class="common-checkbox" value="title" :checked="true">
                Title</label>
            </div>
            <div class="display-flex ml-8">
              <label class="cursor-pointer display-flex"><input v-model="includedTexts" type="checkbox"
                                                                class="common-checkbox" value="subtitle"
                                                                :checked="true">
                <template v-if="currentApp.store.key === 'APP_STORE'">Subtitle</template>
                <template v-else>Sh.Desc</template>
              </label>
            </div>
            <div class="display-flex ml-8">
              <label class="cursor-pointer display-flex"><input v-model="includedTexts" type="checkbox"
                                                                class="common-checkbox" value="description"
                                                                :checked="false"> Desc</label>
            </div>
          </div>
          <div class="language-selector ml-16" style="min-width: 280px">
            <language-selector
                ref="language-selector"
                :initial-selection="locale"
                :current-app="currentApp"
                :current-country="currentCountry"
                :country-locales-config="countryLocalesConfig"
                :current-keyword-country-name="currentCountry.name"
                @locale-changed="changedLocale"
                placeholder="Choose locale"
            ></language-selector>
          </div>
        </div>
        <div class="display-flex flex-align-start topTables">
          <div class="mr-10 flex-50p">
            <MetadataKeywordsTable
                :store="currentApp.store.key"
                :table-container-styles="{display: 'grid', gridTemplateRows: 'auto max-content', minHeight: '505px'}"
                :title="allTitles"
                :sub-title="allSubtitles"
                :description="allDescriptions"
                :outer-texts="includedTexts"
                :allowed-counts="[1]"
            >
            </MetadataKeywordsTable>
          </div>
          <div class="ml-10 flex-50p">
            <MetadataKeywordsTable
                :store="currentApp.store.key"
                :table-container-styles="{display: 'grid', gridTemplateRows: 'auto max-content', minHeight: '505px'}"
                :title="allTitles"
                :sub-title="allSubtitles"
                :description="allDescriptions"
                :outer-texts="includedTexts"
                :init-count="2"
                :max-count-plus="true"
                :allowed-counts="[2, 3]"
            >
            </MetadataKeywordsTable>
          </div>
        </div>
        <div class="mt-40">
          <div class="display-flex mb-24 flex-wrap">
            <div class="common-btn-purple-transparent competitors_selector" @click="competitorsModelOpen">Select
              Competitors ({{ this.competitors.length }}/{{ this.competitorsObj?.getTotalCount() ?? 0 }})
            </div>
            <div class="language-selector ml-16" style="min-width: 280px">
              <language-selector
                  ref="language-selector-2"
                  :initial-selection="locale"
                  :current-app="currentApp"
                  :current-country="currentCountry"
                  :country-locales-config="countryLocalesConfig"
                  :current-keyword-country-name="currentCountry.name"
                  @locale-changed="changedLocale"
              ></language-selector>
            </div>
          </div>
          <div>
            <SimpleInfoTable
                :table-id="'competitors-metadata-table'"
                :columns="{
                0: {header: 'Logo', style: {width: '32px', paddingRight: 0}, headerStyle: {paddingRight: 0}},
                1: {header: 'Title', style: {width: '50%'}, orientation: 'left'},
                2: {header: currentApp.store.key === 'APP_STORE' ? 'Subtitle' : 'Sh.Desc', style: {width: '50%'}, orientation: 'left'},
                3: {header: 'Description'},
                4: {header: 'Rate', sortBy: 'rating'},
                5: {header: 'Ratings', sortBy: 'votesCount'},
                6: {header: ''}
                }"
                :default-sort-index="5"
                default-sort-direction="desc"
                :full-width-section="true"
                :items="competitorsPlusMyApp"
                :tr-class-callback="trClassCallback"
            >
              <template v-slot:headers="slotProps">
                <div>
                  {{ slotProps.header }}
                </div>
              </template>
              <template v-slot:items-0="slotProps">
                <div class="display-flex f-align-center cursor-pointer" @click="this.$emit('clicked', slotProps.item)">
                  <img v-if="slotProps.item.logo"
                       :width="32"
                       :height="32"
                       :src="cropImage(slotProps.item.logo, '32x32', slotProps.item.store)"
                  />
                  <div v-else class="empty-logo"></div>
                </div>
              </template>
              <template v-slot:items-1="slotProps">
                <div class="display-flex f-align-center f-j-between w100p">
                  {{ competitorsMetadata[slotProps.item.competitorId]?.title ?? '-' }} <span class="myAppHint" v-if="slotProps.item.competitorId === currentApp.id">(My App)</span><span class="color-700 no-wrap-text ml-10">{{ competitorsMetadata[slotProps.item.competitorId]?.title?.length ?? 0 }} / 30</span>
                </div>
              </template>
              <template v-slot:items-2="slotProps">
                <div class="display-flex f-align-center f-j-between w100p">
                  {{ competitorsMetadata[slotProps.item.competitorId]?.sub_title ?? '-' }}<span
                    class="color-700 no-wrap-text ml-10">{{ competitorsMetadata[slotProps.item.competitorId]?.sub_title?.length ?? 0 }} / <template
                    v-if="currentApp.store.key === 'APP_STORE'">30</template><template v-else>80</template></span>
                </div>
              </template>
              <template v-slot:items-3="slotProps">
                <div class="display-flex f-align-center f-j-end color-700 no-wrap-text">
                  {{ competitorsMetadata[slotProps.item.competitorId]?.description?.length ?? 0 }} / 4000
                </div>
              </template>
              <template v-slot:items-4="slotProps">
                <div class="display-flex f-align-center f-j-end">
                  <rating-tool :rating="slotProps.item.rating"/>
                </div>
              </template>
              <template v-slot:items-5="slotProps">
                <div class="display-flex f-align-center f-j-end">
                  {{ shortenDigits(slotProps.item.votesCount) ?? '-' }}
                </div>
              </template>
              <template v-slot:items-6="slotProps">
                <div class="display-flex f-align-center f-j-end color-700">
                  <svg-icon icon="angle-down-solid" class="cursor-pointer" :class="{rotate180: slotProps.item.opened}"
                            @click="handleOpenFullSection(slotProps.item)"/>
                </div>
              </template>
              <template v-slot:full-width-sections="slotProps">
                <transition name="height-collapse">
                  <div v-if="slotProps.item.opened" style="overflow: hidden">
                    <div class="display-flex flex-align-start mb-20 mt-12 inTableTables">
                      <div class="mr-10 ml-20 flex-50p">
                        <div class="common-white-container p-20"
                             :style="{boxShadow: 'none', border: '1px solid var(--neutral-400, #E1E8F2)'}">
                          <div class="common-mid-block-title mb-12">Description</div>
                          <div class="common-scrollbar pr-10" style="height: 466px; overflow-y: auto"
                               v-html="competitorsMetadata[slotProps.item.competitorId]?.description.replace(/[\n\r]/g, '<br>')"></div>
                        </div>
                      </div>
                      <div class="ml-10 mr-20 flex-50p">
                        <MetadataKeywordsTable
                            :store="currentApp.store.key"
                            :max-count-plus="true"
                            :table-container-styles="{boxShadow: 'none', border: '1px solid var(--neutral-400, #E1E8F2)', ...{display: 'grid', gridTemplateRows: 'max-content auto max-content', minHeight: '548px'}}"
                            :title="competitorsMetadata[slotProps.item.competitorId]?.title"
                            :sub-title="competitorsMetadata[slotProps.item.competitorId]?.sub_title"
                            :description="competitorsMetadata[slotProps.item.competitorId]?.description"
                            :init-count="2"
                            :allowed-counts="[1, 2, 3]"
                        >
                        </MetadataKeywordsTable>
                      </div>
                    </div>
                  </div>
                </transition>
              </template>
            </SimpleInfoTable>
          </div>
        </div>
      </div>
    </template>

    <competitors_select_modal
        ref="competitors_select_modal"
        :current-app="currentApp"
        :current-country="currentCountry"
        :key="currentApp.id + '|' + currentCountry.id"
        @save-and-continue="selectedCompetitors"/>
  </div>
</template>

<style scoped lang="scss">
@media (max-width: 1439px) {
  .topTables, .inTableTables {
    flex-direction: column;

    > div {
      width: 100%;
      flex: 100%;
      margin: 0;

      &:first-child {
        margin-bottom: 20px;
      }
    }
  }

  .inTableTables {
    margin: 0 20px;
    margin-bottom: 20px;
  }
}
</style>