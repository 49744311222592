<template>
  <div class="impressions-table">
    <SimpleInfoTable
      v-if="isSimplified"
      :table-id="'impressions-table-simplified'"
      :columns="{
        'keyword': {header: 'Keyword', sortBy: 'keyword', orientation: 'left'},
        'country': {header: 'Country', sortBy: 'country'},
        'impression': {header: 'Est. Impression', sortBy: 'impression'},
        'popularity': {header: 'Popularity', sortBy: 'sap'},
        'popularity_change': {header: 'Popularity Change', sortBy: 'popularity_change'},
        'rank': {header: 'Best Rank', sortBy: 'best_rank'},
      }"
      :data-provider="dataProvider"
      :default-sort-index="'impression'"
      :default-sort-direction="'desc'"
      :search-by="['keyword']"
      @refresh-data="refreshData"
    >
      <template v-slot:searchFilterBlock>
        <div class="mb-10">
          <translate-tool :active="showTranslation"
                          style="margin-right: 16px;"
                          @translate-clicked="translateClickHandler()"/>
        </div>
      </template>

      <template v-slot:items-keyword="slotProps">
        <div>
          <div>
            {{ slotProps.item.keyword }}
          </div>
          <div class="translated-block">
            {{ slotProps.item.translation }}
          </div>
        </div>
      </template>
      <template v-slot:items-country="slotProps">
        <div class="country-title">
          <dynamic-image classes="country-flag"
                         :width="14"
                         :height="10"
                         :country-code="slotProps.item.country_code"/>
          <div>
            {{ slotProps.item.country }}
          </div>
        </div>
      </template>
      <template v-slot:items-title="slotProps">
        <div>
          <div :class="{'country-title': mode === 'keywords'}">
            <dynamic-image classes="country-flag"
                           :width="14"
                           :height="10"
                           v-if="slotProps.item.country_code"
                           :country-code="slotProps.item.country_code"/>
            <div>
              {{ slotProps.item.title }}
            </div>
          </div>
          <div class="translated-block">
            {{ slotProps.item.translation }}
          </div>
        </div>
      </template>
      <template v-slot:items-impression="slotProps">
        <div>
          {{ formatNumber(slotProps.item.impression) }}
        </div>
      </template>
      <template v-slot:items-popularity="slotProps">
        <div class="sap-value">
          <sap-tool :keyword="slotProps.item.keyword"
                    :sap="slotProps.item.sap"
                    :country-code="slotProps.item.country_code"
                    :country-name="slotProps.item.country"
                    :store-key="storeKey"
          />
        </div>
      </template>
      <template v-slot:items-popularity_change="slotProps">
        <div class="sap-change" :class="{down: slotProps.item.popularity_change < 0}">
          <template v-if="slotProps.item.popularity_change > 0">
            <img src="@/assets/svg/arrow-up-alt.svg"
                 width="12"
                 height="12"
                 alt="Arrow up"/>
            {{ slotProps.item.popularity_change }}
          </template>
          <template v-else-if="slotProps.item.popularity_change < 0">
            <img src="@/assets/svg/arrow-down-alt.svg"
                 width="12"
                 height="12"
                 alt="Arrow down"/>
            {{ absoluteValue(slotProps.item.popularity_change) }}
          </template>
        </div>
      </template>
      <template v-slot:items-rank="slotProps">
        <div>
          {{ slotProps.item.best_rank }}
        </div>
      </template>
    </SimpleInfoTable>
    <SimpleInfoTable
      v-else
      :table-id="'impressions-table-not-simplified'"
      :columns="{
        'title': {
          header: this.mode === 'keywords' ? 'Keyword' : 'Country',
          orientation: 'left',
          sortBy: 'title',
          sortExportParam: 'title'
        },
        'impression': {header: 'Total Est. Impression', sortBy: 'impression', sortExportParam: 'impression'},
        'total': {header: 'Total Keywords', hidden: mode === 'keywords', sortBy: 'total', sortExportParam: 'total'},
      }"
      :default-sort-index="'impression'"
      :default-sort-direction="'desc'"
      :data-provider="dataProvider"
      :full-width-section="true"
      :search-by="['title']"
      :classes="['main-table']"
      @refresh-data="refreshData"
      :trClickCallback="openRow"
      :tr-class-callback="trClassCallback"
    >
      <template v-slot:searchFilterBlock>
        <div>
          <div class="collapse-button" @click="collapseClickHandler">
            <template v-if="isAllUncollapsed">
              <div>Collapse all</div>
              <svg-icon icon="collapse"/>
            </template>
            <template v-else>
              <div>Uncollapse all</div>
              <svg-icon icon="uncollapse"/>
            </template>
          </div>

          <translate-tool :active="showTranslation"
                          v-if="mode === 'keywords'"
                          @translate-clicked="translateClickHandler()"/>
        </div>
      </template>

      <template v-slot:items-title="slotProps">
        <div class="display-flex f-align-center f-j-between full-width">
          <div>
            <div :class="{'country-title': mode === 'countries'}">
              <dynamic-image classes="country-flag"
                             :width="14"
                             :height="10"
                             v-if="slotProps.item.country_code"
                             :country-code="slotProps.item.country_code"/>
              <div>
                {{ slotProps.item.title }}
              </div>
            </div>
            <div class="translated-block">
              {{ slotProps.item.translation }}
            </div>
          </div>

          <svg-icon icon="angle-down-solid" class="cursor-pointer open-full-section"
                    :class="{rotate180: slotProps.item.opened}" />
        </div>
      </template>
      <template v-slot:items-impression="slotProps">
        <div>
          {{ formatNumber(slotProps.item.impression) }}
        </div>
      </template>
      <template v-slot:items-total="slotProps">
        <div>
          {{ slotProps.item.total }}
        </div>
      </template>

      <template v-slot:full-width-sections="slotProps">
        <div class="opened" v-if="slotProps.item.opened">
          <SimpleInfoTable
            :table-id="'impressions-table-full-width'"
            :columns="{
              'title': {
                header: mode === 'countries' ? 'Keyword' : 'Country',
                orientation: 'left',
                sortBy: 'title',
              },
              'impression': {header: 'Est. Impression', sortBy: 'impression'},
              'popularity': {header: 'Popularity', sortBy: 'sap'},
              'popularity_change': {header: 'Popularity Change', sortBy: 'popularity_change'},
              'rank': {header: 'Best Rank', sortBy: (item) => this.getRankForSort(item.best_rank)},
            }"
            :default-sort-index="'impression'"
            :default-sort-direction="'desc'"
            :items="slotProps.item.list"
            :show-pagination="slotProps.item.list.length > 50"
            :search-by="['title']"
          >
            <template v-slot:searchFilterBlock>
              <div class="mb-10">
                <translate-tool :active="slotProps.item.translate"
                                v-if="mode === 'countries'"
                                @translate-clicked="translateClickHandlerInner(slotProps.item)"/>
              </div>
            </template>

            <template v-slot:items-title="slotProps">
              <div>
                <div :class="{'country-title': mode === 'keywords'}">
                  <dynamic-image classes="country-flag"
                                 :width="14"
                                 :height="10"
                                 v-if="slotProps.item.country_code"
                                 :country-code="slotProps.item.country_code"/>
                  <div>
                    {{ slotProps.item.title }}
                  </div>
                </div>
                <div class="translated-block">
                  {{ slotProps.item.translation }}
                </div>
              </div>
            </template>
            <template v-slot:items-impression="slotProps">
              <div>
                {{ formatNumber(slotProps.item.impression) }}
              </div>
            </template>
            <template v-slot:items-popularity="subTableProps">
              <div class="sap-value">
                <sap-tool :keyword="subTableProps.item.keyword"
                          :sap="subTableProps.item.sap"
                          :country-code="slotProps.item.country_code"
                          :country-name="slotProps.item.country"
                          :store-key="storeKey"
                />
              </div>
            </template>
            <template v-slot:items-popularity_change="slotProps">
              <div class="sap-change" :class="{down: slotProps.item.popularity_change < 0}">
                <template v-if="slotProps.item.popularity_change > 0">
                  <img src="@/assets/svg/arrow-up-alt.svg"
                       width="12"
                       height="12"
                       alt="Arrow up"/>
                  {{ slotProps.item.popularity_change }}
                </template>
                <template v-else-if="slotProps.item.popularity_change < 0">
                  <img src="@/assets/svg/arrow-down-alt.svg"
                       width="12"
                       height="12"
                       alt="Arrow down"/>
                  {{ absoluteValue(slotProps.item.popularity_change) }}
                </template>
              </div>
            </template>
            <template v-slot:items-rank="slotProps">
              <div>
                {{ slotProps.item.best_rank }}
              </div>
            </template>
          </SimpleInfoTable>
        </div>
      </template>
    </SimpleInfoTable>
  </div>
</template>

<script>
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import SimpleInfoTable from "@/components/SimpleInfoTable/SimpleInfoTable.vue";
import SapTool from "@/components/DataTables/Tools/SapTool/index.vue";
import TranslateTool from "@/components/DataTables/Tools/TranslateTool/index.vue";
import translates from "@/api/modules/translates";
import DynamicImage from "@/components/UI/DynamicImage/index.vue";
import {ApiDataProvider} from "@/api/modules/tables/api-data-provider";
import {formatNumber} from "@/utils/utils";

export default {
  name: 'ImpressionsTable',
  components: {
    DynamicImage,
    TranslateTool,
    SapTool,
    SimpleInfoTable,
    SvgIcon
  },
  props: {
    mode: String,
    dataProvider: ApiDataProvider,
    isSimplified: Boolean,
    storeKey: String,
  },
  data() {
    return {
      showTranslation: false,
    }
  },
  methods: {
    formatNumber,
    absoluteValue(value) {
      return Math.abs(value);
    },
    openRow(item) {
      item.opened = !item.opened
    },
    trClassCallback() {
      return {'cursor-pointer': true};
    },
    getRankForSort(rank) {
      if (!isNaN(rank)) {
        return -rank;
      } else if (rank === '-') {
        return -1000;
      } else {
        return -999;
      }
    },
    clearTranslations(list) {
      list.forEach((item) => {
        item.translation = null;
      });

      return list;
    },
    async translateClickHandler() {
      this.showTranslation = !this.showTranslation;
      let items = this.dataProvider.getItems();
      items = await this.translate(this.showTranslation, items);
      this.dataProvider.setItems(items);
    },
    async translateClickHandlerInner(item) {
      item.translate = !item.translate;
      await this.translate(item.translate, item.list);
    },
    async translate(enable, list) {
      if (!enable) {
        this.clearTranslations(list);
      } else {
        let keywords = list.map((item) => {
          return this.isSimplified ? item.keyword : item.title;
        });

        let translatesByKeyword = await translates.translateKeywords(this, this.storeKey, keywords);

        list.forEach((item) => {
          item.translation = translatesByKeyword[this.isSimplified ? item.keyword :item.title] ?? null;
        });
      }

      return list;
    },
    refreshData(searchFilterData) {
      this.$emit('refresh-data', searchFilterData);
    },
    collapseClickHandler() {
      let items = this.dataProvider.getItems();
      let opened = !this.isAllUncollapsed;
      items.forEach((item) => {
        item.opened = opened;
      })
      this.dataProvider.setItems(items);
    },
  },
  computed: {
    isAllUncollapsed() {
      return this.dataProvider.getItems().every((item) => item.opened === true);
    },
  }
}
</script>

<style src="./styles.scss" lang="scss"></style>
