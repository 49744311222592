<template>
  <div class="chart-block">
    <vue-highcharts
     v-if="loaded"
     type="chart"
     :options="options"
     :redrawOnUpdate="true"
     :oneToOneUpdate="false"
     :animateOnUpdate="true"/>
    <div v-if="isShowFullChart" v-tooltip="{ text: 'Show more', position: tooltipPos, classes: ['no-wrap-text'] }">
      <svg-icon icon="chart-icon" @click="this.$emit('chart-icon-clicked')" class="icons-margin chart-icon"/>
    </div>
  </div>
</template>

<style scoped>
.chart-block {
  display: flex;
  align-items: center;
}
.chart-icon {
  font-size: 24px;
  color: #a3b0c5;
  margin-left: 2px;
  cursor: pointer;
  transition: color .3s;
}
</style>

<script>
import {defineComponent} from 'vue';
import {formatDate} from "@/utils/utils";
import SvgIcon from "../../../UI/SvgIcon/index.vue";

export default defineComponent({
  name: "RankChartTool",
  components: {
    SvgIcon
  },
  props: {
    chartData: {
      type: Array,
    },
    tooltipPos: {
      type: String,
      default: 'bottom'
    },
    isShowFullChart: {
      type: Boolean,
      default: true,
    }
  },
  data() {
   return {
     loaded: false,
     options: {},
   }
  },
  methods: {
    getRankingData() {
      if (this.chartData === undefined || this.chartData?.length === 0) {
        return;
      }

      const rankingData = this.chartData?.sort(function (a, b) {
        const dateA = new Date(a.created_at).getTime();
        const dateB = new Date(b.created_at).getTime();
        return dateA < dateB ? -1 : 1;
      });

      const dates = rankingData.map(item => {
        return formatDate(item?.created_at, 'month-day-year');
      });

      const rank = rankingData.map(item => {
        return item?.rank;
      });

      this.options = {
        chart: {
          type: 'line',
          width: 64,
          height: 30,
          margin: 0,
        },
        title: false,
        legend: {
          enabled: false
        },
        xAxis: {
          title: false,
          categories: dates,
          labels: {
            enabled: false
          },
          gridLineColor: '#ffffff',
          visible: false
        },
        yAxis: {
          title: false,
          labels: {
            enabled: false
          },
          gridLineColor: '#ffffff',
          reversed: true,
        },
        plotOptions: {
          series: {
            color: 'var(--primary-default)',
            opacity: 1,
            marker: {
              enabled: false
            },
            states: {
              inactive: {
                opacity: 1
              },
              hover: {
                halo: {
                  size: 4,
                  attributes: {
                    fill: 'transparent',
                    'stroke-width': 2,
                    stroke: 'transparent'
                  }
                }
              }
            }
          }
        },
        series: [{
          data: rank,
          lineWidth: 1,
          marker: {
            states: {
              hover: {
                fillColor: 'white',
                radius: 3,
                lineWidth: 1,
                lineColor: 'var(--primary-default)',
              }
            }
          }
        }],
        tooltip: {
          width: 100,
          distance: 20,
          padding: 8,
          outside: true,
          useHTML: true,
          backgroundColor: '#fff',
          borderColor: '#d7dde7',
          borderRadius: 4,
          shadow: false,
          shape: "square",
          hideDelay: 15,
          formatter: function () {
            let rankDifference = null;
            const category = this.point.category;

            for (const key in dates) {
              if (category === formatDate(dates[key], 'month-day-year')) {
                rankDifference = rankingData[key]?.difference;
                break;
              }
            }

            const differenceColor = rankDifference > 0 ? 'green' : 'red';
            const differenceRenderValue = rankDifference > 0 ? '+' + rankDifference : rankDifference;
            let differenceBlock = '';

            if (rankDifference !== 0 && rankDifference !== null && rankDifference !== undefined) {
              differenceBlock = `<span class="difference ${differenceColor}">${differenceRenderValue}</span>`;
            }

            return `<div class="tooltip-inner-container">${this.point.category}
                      <br />
                      <span class="rank">Rank: <strong>${this.point.y}</strong>
                      ${differenceBlock}
                      </span>
                    </div>`
          }
        },
      };
      this.loaded = true;
    },
  },
  mounted() {
    this.getRankingData();
  },
})
</script>