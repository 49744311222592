const LOCALE_COUNTRIES = {
    'ar': {
        localeName: 'Arabic',
        defaultCountry: 'Saudi Arabia',
        countryCode: 'SA',
    },
    'ca': {
        localeName: 'Catalan, Valencian',
        defaultCountry: 'Spain',
        countryCode: 'ES',
    },
    'zh_cn': {
        localeName: 'Chinese (simplified)',
        defaultCountry: 'China',
        countryCode: 'CN',
    },
    'zh': {
        localeName: 'Chinese (simplified)',
        defaultCountry: 'China',
        countryCode: 'CN',
    },
    'zh_tw': {
        localeName: 'Chinese (traditional)',
        defaultCountry: 'Taiwan',
        countryCode: 'TW',
    },
    'hr': {
        localeName: 'Croatian',
        defaultCountry: 'Croatia',
        countryCode: 'HR',
    },
    'cs': {
        localeName: 'Czech',
        defaultCountry: 'Czech Republic',
        countryCode: 'CZ',
    },
    'da': {
        localeName: 'Danish',
        defaultCountry: 'Denmark',
        countryCode: 'DK',
    },
    'nl': {
        localeName: 'Dutch',
        defaultCountry: 'Netherlands',
        countryCode: 'NL',
    },
    'en_au': {
        localeName: 'English',
        defaultCountry: 'Australia',
        countryCode: 'AU',
    },
    'en_ca': {
        localeName: 'English',
        defaultCountry: 'Canada',
        countryCode: 'CA',
    },
    'en_gb': {
        localeName: 'English',
        defaultCountry: 'United Kingdom',
        countryCode: 'GB',
    },
    'en': {
        localeName: 'English',
        defaultCountry: 'United States',
        countryCode: 'US',
    },
    'fi': {
        localeName: 'Finnish',
        defaultCountry: 'Finland',
        countryCode: 'FI',
    },
    'fr': {
        localeName: 'French',
        defaultCountry: 'France',
        countryCode: 'FR',
    },
    'fr_ca': {
        localeName: 'French',
        defaultCountry: 'Canada',
        countryCode: 'CA',
    },
    'de': {
        localeName: 'German',
        defaultCountry: 'Germany',
        countryCode: 'DE',
    },
    'el': {
        localeName: 'Greek (modern)',
        defaultCountry: 'Greece',
        countryCode: 'GR',
    },
    'he': {
        localeName: 'Hebrew',
        defaultCountry: 'Israel',
        countryCode: 'IL',
    },
    'hi': {
        localeName: 'Hindi',
        defaultCountry: 'India',
        countryCode: 'IN',
    },
    'hu': {
        localeName: 'Hungarian',
        defaultCountry: 'Hungary',
        countryCode: 'HU',
    },
    'id': {
        localeName: 'Indonesian',
        defaultCountry: 'Indonesia',
        countryCode: 'ID',
    },
    'it': {
        localeName: 'Italian',
        defaultCountry: 'Italy',
        countryCode: 'IT',
    },
    'ja': {
        localeName: 'Japanese',
        defaultCountry: 'Japan',
        countryCode: 'JP',
    },
    'ko': {
        localeName: 'Korean',
        defaultCountry: 'Korea (South)',
        countryCode: 'KR',
    },
    'ms': {
        localeName: 'Malay',
        defaultCountry: 'Malaysia',
        countryCode: 'MY',
    },
    'nb': {
        localeName: 'Norwegian',
        defaultCountry: 'Norway',
        countryCode: 'NO',
    },
    'pl': {
        localeName: 'Polish',
        defaultCountry: 'Poland',
        countryCode: 'PL',
    },
    'pt_br': {
        localeName: 'Portuguese',
        defaultCountry: 'Portugal',
        countryCode: 'BR',
    },
    'pt': {
        localeName: 'Portuguese',
        defaultCountry: 'Portugal',
        countryCode: 'PT',
    },
    'ro': {
        localeName: 'Romanian',
        defaultCountry: 'Romania',
        countryCode: 'RO',
    },
    'ru': {
        localeName: 'Russian',
        defaultCountry: 'Russian Terrorists',
        countryCode: 'RU',
    },
    'sk': {
        localeName: 'Slovak',
        defaultCountry: 'Slovakia',
        countryCode: 'SK',
    },
    'es_mx': {
        localeName: 'Spanish',
        defaultCountry: 'Mexico',
        countryCode: 'MX',
    },
    'es': {
        localeName: 'Spanish',
        defaultCountry: 'Spain',
        countryCode: 'ES',
    },
    'sv': {
        localeName: 'Swedish',
        defaultCountry: 'Sweden',
        countryCode: 'SE',
    },
    'th': {
        localeName: 'Thai',
        defaultCountry: 'Thailand',
        countryCode: 'TH',
    },
    'tr': {
        localeName: 'Turkish',
        defaultCountry: 'Turkey',
        countryCode: 'TR',
    },
    'uk': {
        localeName: 'Ukrainian',
        defaultCountry: 'Ukraine',
        countryCode: 'UA',
    },
    'vi': {
        localeName: 'Vietnamese',
        defaultCountry: 'Vietnam',
        countryCode: 'VN',
    },
};
export function getLocaleCountryCode(locale) {
    let loweredLocale = locale?.toLowerCase();
    if (LOCALE_COUNTRIES[loweredLocale]) {
        let locale = LOCALE_COUNTRIES[loweredLocale];
        let localeFullName = locale.localeName;
        if (loweredLocale.includes('_')) {
            localeFullName += '(' + locale.defaultCountry + ')';
        }
        locale.localeFullName = localeFullName;

        return locale;
    }
    return null;
}